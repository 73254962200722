export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDgU_qyND7LXCY-hESIzUvu8jIqFflc_BE',
    authDomain: 'befret-development.firebaseapp.com',
    databaseURL: 'https://befret-development-default-rtdb.firebaseio.com',
    projectId: 'befret-development',
    storageBucket: 'befret-development.appspot.com',
    messagingSenderId: '384879116689',
    appId: '1:384879116689:web:4264e8f53d6d37e3c2da30',
    measurementId: 'G-M6VGY46KTE'
  }
};