<!-- <p>parcel-details works!</p> -->
<div  id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
    <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
        <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
        <div class="o_we_bg_filter bg-black-0"></div>
        <div class="container">
            <h1><font style="color: rgb(0, 0, 0);">{{"Détails du colis" | translate}}</font></h1>
        </div>
    </section>
    <section *ngIf="parcel" class="s_text_image pt32 pb32 o_colored_level" data-snippet="s_text_image" data-name="Texte - Image">
        
        <div class="container">
            <div class="row align-items-center">
                
                <div class="col-lg-6 o_colored_level ">
                    <ol id="olList">
                        <li id="pendingB" [ngClass]="{'active': isActive('pending')}">
                          <span class="olspan">
                            <span  class="fa fa-check-circle" *ngIf="isActive('pending')">{{"Attente" | translate}}</span>
                            <!-- <i class="fa fa-check-circle" *ngIf="isActive('pending')"></i> Attente -->
                          </span>
                        </li>
                        <li id="receivedB" [ngClass]="{'active': isActive('received')}">
                          <span class="olspan">
                            <span  class="fa fa-check-circle" *ngIf="isActive('received')">{{"Préparation" | translate}}</span>
                          </span>
                          <!-- <span class="olspan"><i class="fa fa-check-circle" *ngIf="isActive('received')"></i> Préparation</span> -->
                        </li>
                        <li id="sentB" [ngClass]="{'active': isActive('sent')}">
                          <span class="olspan">
                            <span  class="fa fa-check-circle" *ngIf="isActive('sent')">{{"Expédié" | translate}}</span>
                          </span>
                          <!-- <span class="olspan"><i class="fa fa-check-circle" *ngIf="isActive('sent')"></i> Expédié</span> -->
                        </li>
                        <li id="arrivedB" [ngClass]="{'active': isActive('arrived')}">
                          <span class="olspan">
                            <span  class="fa fa-check-circle" *ngIf="isActive('arrived')">{{"Arrivé" | translate}}</span>
                          </span>
                          <!-- <span class="olspan"><i class="fa fa-check-circle" *ngIf="isActive('arrived')"></i> Arrivé</span> -->
                        </li>
                        <li id="deliveredB" [ngClass]="{'active': isActive('delivered')}">
                          <span class="olspan">
                            <span  class="fa fa-check-circle" *ngIf="isActive('delivered')">{{"Livré" | translate}}</span>
                          </span>
                          <!-- <span class="olspan"><i class="fa fa-check-circle" *ngIf="isActive('delivered')"></i> Livré</span> -->
                        </li>
                      </ol>
                      
                    <!-- <ol id="olList">
                        <li id="pendingB" [ngClass]="{'active': isActive('pending')}">
                          <span class="olspan">Attente</span>
                        </li>
                        <li id="receivedB" [ngClass]="{'active': isActive('received')}"><span class="olspan">Préparation</span></li>
                        <li id="sentB" [ngClass]="{'active': isActive('sent')}"><span class="olspan">Expédié</span></li>
                        <li id="arrivedB" [ngClass]="{'active': isActive('arrived')}"><span class="olspan">Arrivé</span></li>
                        <li id="deliveredB" [ngClass]="{'active': isActive('delivered')}"><span class="fa fa-1x fa-check-circle olspan">Livré</span></li>
                      </ol> -->
                    
                    <div class="timeline-parcours">
                        
                        <!-- <div class="container-info">
                            

                          <div class="timeline-section-tl" [class.active]="parcel?.status === 'pending' || parcel?.status === 'received' || parcel?.status === 'sent' || parcel?.status === 'arrived' || parcel?.status === 'delivered'">
                            <div class="parcours-box parc1">
                              <div class="infobox date1">
                                <h4>En attente</h4>
                                </div>
                              <div class="fleche-bloc"></div>
                            </div>
                          </div>
                      

                          <div class="timeline-section-tl" [class.active]="parcel?.status === 'received' || parcel?.status === 'sent' || parcel?.status === 'arrived' || parcel?.status === 'delivered'">
                            <div class="parcours-box parc2">
                              <div class="infobox date1">
                                <h4>Préparation</h4>
                              </div>
                              <div class="fleche-bloc"></div>
                            </div>
                          </div>
                      

                          <div class="timeline-section-tl" [class.active]="parcel?.status === 'sent' || parcel?.status === 'arrived' || parcel?.status === 'delivered'">
                            <div class="parcours-box parc3">
                              <div class="infobox date1">
                                <h4>Expédié</h4>
                              </div>
                              <div class="fleche-bloc"></div>
                            </div>
                          </div>
                      

                          <div class="timeline-section-tl" [class.active]="parcel?.status === 'arrived' || parcel?.status === 'delivered'">
                            <div class="parcours-box parc4">
                              <div class="infobox date1">
                                <h4>Arrivé</h4>
                              </div>
                              <div class="fleche-bloc"></div>
                            </div>
                          </div>
                      

                          <div class="timeline-section-tl" [class.active]="parcel?.status === 'delivered'">
                            <div class="parcours-box parc5">
                              <div class="infobox date1">
                                <h4>Livré</h4>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      
                    <h2 class="o_default_snippet_text">&nbsp;{{"Votre colis : " | translate}}<strong>{{parcel.trackingID}}</strong></h2>
                    
                    <table class="o_list_table table table-sm">
                        <thead>
                            <th>.</th>
                            <th>.</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{"Type" | translate}}</td>
                                <td>{{parcel.type || 'Paquet'}}</td>
                            </tr>
                            <tr>
                                <td>{{"Poids" | translate}}</td>
                                <td>{{parcel.weight + ' Kg'}}</td>
                            </tr>
                            <tr>
                                <td>{{"Destinataire" | translate}}</td>
                                <td>{{parcel.receiver_name || 'Jean-Marie Kalemba'}}</td>
                            </tr>
                            <tr>
                                <td>{{"Date de commande" | translate}}</td>
                                <td>{{(parcel.create_date).split('T')[0] || '01-01-2024'}}</td>
                            </tr>
                            <tr>
                                <td>{{"Mode de livraison" | translate}}</td>
                                <td *ngIf="parcel.pickupMethod === 'home_delivery'">{{"A domicile" | translate}}</td>
                                <td *ngIf="parcel.pickupMethod === 'warehouse'">{{"Point de rencontre" | translate}} {{parcel.city.toUpperCase()}}</td>
                            </tr>
                            <tr *ngIf="parcel.pickupMethod === 'home_delivery'">
                                <td>{{"Adresse de livraison" | translate}}</td>
                                <td>{{parcel.address + ', '+ (parcel.city).toUpperCase() || 'Av Luzumu, Q/Bangu C/Ngaliema, '+ (parcel.city).toUpperCase()}}</td>
                            </tr>
                            <tr>
                                <td>{{"Status" | translate}}</td>
                                <td><div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info" *ngIf="parcel.status === 'draft'">
                                    <span class="badge rounded-pill text-bg-info">{{"En Brouillon" | translate}}</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info" *ngIf="parcel.status === 'pending'">
                                    <span class="badge rounded-pill text-bg-info">{{"En attente ( Poste )" | translate}}</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-light" *ngIf="parcel.status === 'received'">
                                    <span class="badge rounded-pill text-bg-secondary">{{"En Préparation ( Belgique )" | translate}}</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-light" *ngIf="parcel.status === 'sent'">
                                    <span class="badge rounded-pill text-bg-secondary">{{"Belgique vers" | translate}} {{parcel.city}}</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'arrived'">
                                    <span class="badge rounded-pill text-bg-warning">{{"Arrivé à" | translate}} ({{parcel.city}})</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'delivered'">
                                    <span class="badge rounded-pill text-bg-primary">{{"Livré à" | translate}} {{parcel.city}}</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'invalid'">
                                    <span class="badge rounded-pill text-bg-danger">{{"Invalidé (Contactez-nous)" | translate}}</span>
                                </div></td>
                            </tr>
                            
                            <tr *ngIf="parcel.status === 'received' || parcel.status === 'sent' || parcel.status === 'arrived' || parcel.status ==='delivered'">
                                <td>{{"Date de reception" | translate}}</td>
                                <td>{{parcel.display_receive_date || '01-01-2024'}}</td>
                            </tr>
                            
                            <tr *ngIf="parcel.status === 'sent' || parcel.status === 'arrived' || parcel.status ==='delivered'">
                                <td>{{"Expédier le : " | translate}}</td>
                                <td>{{parcel.display_sent_date || '01-01-2024'}}</td>
                            </tr>
                            
                            <tr *ngIf="parcel.status === 'arrived' || parcel.status ==='delivered'">
                                
                                <td>{{"Date d'arrivée à " | translate}}{{parcel.city}} : </td>
                                <td>{{parcel.display_arrived_date || '01-01-2024'}}</td>
                            </tr>
                            
                            <tr *ngIf="parcel.status === 'delivered'">
                                <td>{{"Livré le : " | translate}}</td>
                                <td>{{parcel.display_delivery_date || '01-01-2024'}}</td>
                            </tr>
 
                            <tr *ngIf="parcel.status === 'delivered'">
                                <td> </td>
                                <td>{{"A " | translate}}{{parcel.final_receiver_name || '01-01-2024'}}</td>
                            </tr>
                            <tr>
                                <td>{{"Coût" | translate}}</td>
                                <td>{{parcel.cost || 15}}<i class="fa fa-fw fa-eur "></i></td>
                            </tr>
                        </tbody>
                    </table>
                    <h6 style="color: red;">{{errorMessage}}</h6>
                    <div *ngIf="parcel.status === 'draft'" >
                        <button class="btn btn-primary" (click)="draftPayment()" >
                            <!-- <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span> -->
                            <span >{{"Finir la commande" | translate}}</span>
                            </button>
                        <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" (click)="deleteParcel()">{{"Supprimer le colis" | translate}}</button>
                    </div>
                </div>
                <div *ngIf="!parcel" class="col-lg-6 o_colored_level">
                    <h2>{{"Code invalide. Vérifiez votre tracking code aupres de notre service client" | translate}}</h2>
                </div>
                <div class="col-lg-6 pt16 pb16 o_colored_level">
                    <section class="s_map pt256 pb72" data-map-type="m" data-map-zoom="12" data-snippet="s_map" data-map-address="Rue Ilya PRIGOGINE 35 Tubize Belgique" data-name="Carte">
        <div class="map_container o_not_editable">
            <div class="css_non_editable_mode_hidden">
                <div class="missing_option_warning alert alert-info rounded-0 fade show d-none d-print-none o_default_snippet_text">
                    Une adresse doit être spécifiée pour qu'une carte soit intégrée
                </div>
            </div>
            <iframe *ngIf="!parcel.location" class="s_map_embedded o_not_editable" src="https://maps.google.com/maps?q=250%20Executive%20Park%20Blvd%2C%20Suite%203400%20San%20Francisco%20California%20(US)%20United%20States&amp;t=m&amp;z=12&amp;ie=UTF8&amp;iwloc=&amp;output=embed" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" aria-label="Carte"></iframe>
            <iframe *ngIf="parcel?.location" [src]="mapUrl" width="100%" height="350px" frameborder="0" style="border:0;" allowfullscreen></iframe>
            <div class="s_map_color_filter"></div>
        </div>
    </section>
</div>