import { Component } from '@angular/core';

@Component({
  selector: 'app-legales',
  templateUrl: './legales.component.html',
  styleUrl: './legales.component.css'
})
export class LegalesComponent {

}
