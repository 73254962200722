import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-empoyee',
  templateUrl: './manage-empoyee.component.html',
  styleUrl: './manage-empoyee.component.css'
})
export class ManageEmpoyeeComponent {

}
