import { Component } from '@angular/core';
import { TranslationService } from './translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  constructor(private translationService : TranslationService){ }
  title = 'BeFret';

  ngOnInit() {
    this.translationService.loadSavedLanguage();
  }
}
