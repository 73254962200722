<div  id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
    <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
        <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
        <div class="o_we_bg_filter bg-black-0"></div>
        <div class="container">
            <h1><font style="color: rgb(0, 0, 0);">{{"Suivez votre colis" | translate}}</font></h1>
        </div>
    </section>
    <div >
        <section class="s_searchbar o_colored_level pb40 pt40 o_cc" data-snippet="s_searchbar" 
        data-name="Rechercher">
            <div class="container">
                <div class="row">
                    <div class="o_colored_level col-lg-10 offset-lg-1 pb8" >
                        <p>{{"Localisez votre colis en saisissant le code ci-dessous" | translate}}</p>
                        <!-- <p class="o_default_snippet_text"><span class="h5-fs"><strong>Suivre votre colis</strong></span></p> -->
                        <form style="height: 100%; align-items: center; justify-content: center; display: flex;"
                        class="o_searchbar_form o_wait_lazy_js s_searchbar_input" (ngSubmit)="onSubmit()" [formGroup]="trackingForm">
                            <div role="search" class="input-group " style="display: flex;">
                                <input type="search" name="search" class="search-query form-control oe_search_box None" 
                                placeholder="{{'Code de suivi' | translate}}" data-search-type="all" data-limit="5" formControlName="trackingID"
                                data-display-image="true" data-display-description="true" data-display-extra-link="true" 
                                data-display-detail="true" data-order-by="name asc" autocomplete="off" style="border-radius: 10px 0px 0px 10px;">
                                
                            </div>
                            <div >
                                <button type="submit" title="Suivre" class="btn btn-primary" 
                                style="border-radius: 0px 15px 15px 0px; margin: 0px;">
                                {{"Suivre" | translate}}
                                </button>
                            </div>
                        </form>
                       
                    </div>
                </div>
            </div>
        </section>
        
        <!-- <section class="s_text_image pt32 pb32 o_colored_level" data-snippet="s_text_image" data-name="Texte - Image">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 o_colored_level pt8 pb152">
                        <h2 class="o_default_snippet_text">&nbsp;Votre colis</h2>
                        <ul class="o_default_snippet_text">
                            <li><strong><span class="h5-fs">Description :&nbsp;</span></strong><br></li>
                            <li><strong><span class="h5-fs">Poids :</span></strong></li>
                            <li><strong><span class="h5-fs">Date d'envoi :</span></strong></li>
                            <li><strong><span class="h5-fs">Date de livraison :&nbsp;</span></strong></li>
                            <li><strong><span class="h5-fs">Status :&nbsp;</span></strong></li>
                        </ul>
                    </div>
                    <div class="col-lg-6 pt16 pb16 o_colored_level">
                        <section class="s_map pt256 pb72" data-map-type="m" data-map-zoom="12" data-snippet="s_map" data-map-address="Rue Ilya PRIGOGINE 35 Tubize Belgique" data-name="Carte">
            <div class="map_container o_not_editable">
                <div class="css_non_editable_mode_hidden">
                    <div class="missing_option_warning alert alert-info rounded-0 fade show d-none d-print-none o_default_snippet_text">
                        Une adresse doit être spécifiée pour qu'une carte soit intégrée
                    </div>
                </div>
                <iframe class="s_map_embedded o_not_editable" src="https://maps.google.com/maps?q=250%20Executive%20Park%20Blvd%2C%20Suite%203400%20San%20Francisco%20California%20(US)%20United%20States&amp;t=m&amp;z=12&amp;ie=UTF8&amp;iwloc=&amp;output=embed" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" aria-label="Carte"></iframe>
                <div class="s_map_color_filter"></div>
            </div>
        </section>
                    </div>
                </div>
            </div>
        </section> -->
    </div>
</div>