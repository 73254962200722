<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Contactez-nous" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="col-lg-8 mt-4 mt-lg-0 o_colored_level">
                        <p>{{"Contactez-nous pour toute question sur notre entreprise ou nos services, et nous vous répondrons dans les plus brefs délais" | translate}}
                            
                            <!-- Contactez-nous pour tout ce qui concerne notre entreprise ou nos services.<br>
                            Nous ferons de notre mieux pour vous répondre dans les plus brefs délais. -->
                        </p>
                        <section class="s_website_form" data-vcss="001" data-snippet="s_website_form" data-name="Formulaire">
                            <div class="container">
                                <form id="contactus_form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                    <div class="s_website_form_rows row s_col_no_bgcolor">
                                        <div class="mb-0 py-2 col-12 s_website_form_field s_website_form_custom s_website_form_required" data-type="char" data-name="Field">
                                            <div class="row s_col_no_resize s_col_no_bgcolor">
                                                <label class="col-form-label col-sm-auto s_website_form_label" style="width: 200px" for="contact1">
                                                    <span class="s_website_form_label_content">{{"Nom" | translate}}</span>
                                                    <span class="s_website_form_mark"> *</span>
                                                </label>
                                                <div class="col-sm">
                                                    <input id="contact1" type="text" class="form-control s_website_form_input" formControlName="contact_sender_name" required="" data-fill-with="name" [value]="userProfile?.fullname">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="mb-0 py-2 col-12 s_website_form_field s_website_form_custom" data-type="char" data-name="Field">
                                            <div class="row s_col_no_resize s_col_no_bgcolor">
                                                <label class="col-form-label col-sm-auto s_website_form_label" style="width: 200px" for="contact2">
                                                    <span class="s_website_form_label_content">{{"Téléphone" | translate}}</span>
                                                </label>
                                                <div class="s_website_form_field mb-3 col-3 s_website_form_custom s_website_form_required">
                                                <select *ngIf="userProfile?.country" id="prefix" formControlName="prefix" class="form-select s_website_form_input"
                                                [ngModel]="userProfile?.country">
                                                    <option *ngFor="let prefix of phonePrefixes"  [value]="prefix.label">
                                                        {{ prefix.label }} 
                                                    </option>
                                                </select>
                                                <select *ngIf="userProfile?.country == null" id="phonePrefix2" formControlName="phonePrefix2" class="form-select s_website_form_input" >
                                                    <option *ngFor="let prefix of phonePrefixes" [value]="prefix.label">
                                                        {{prefix.label}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="mb-3 col-6" style="margin: 0px 0px 0px 5px;">
                                                <input class="form-control s_website_form_input" type="tel" placeholder="{{'Votre téléphone' | translate}}"
                                                formControlName="phoneNumber" id="phoneNumber" maxlength="15" pattern="\d{0,15}" [value]="userProfile?.phoneNumber">
                                            </div>
                                            </div>
                                        </div>
                                        <div class="mb-0 py-2 col-12 s_website_form_field s_website_form_required s_website_form_model_required" data-type="email" data-name="Field">
                                            <div class="row s_col_no_resize s_col_no_bgcolor">
                                                <label class="col-form-label col-sm-auto s_website_form_label" style="width: 200px" for="contact3">
                                                    <span class="s_website_form_label_content">{{"Email" | translate}}</span>
                                                    <span class="s_website_form_mark"> *</span>
                                                </label>
                                                <div class="col-sm">
                                                    <input id="contact3" type="email" class="form-control s_website_form_input" formControlName="email" required="" data-fill-with="email" [value]="userProfile?.email">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="mb-0 py-2 col-12 s_website_form_field s_website_form_required s_website_form_model_required" data-type="char" data-name="Field">
                                            <div class="row s_col_no_resize s_col_no_bgcolor">
                                                <label class="col-form-label col-sm-auto s_website_form_label" style="width: 200px" for="contact5">
                                                    <span class="s_website_form_label_content">{{"Sujet" | translate}}</span>
                                                    <span class="s_website_form_mark"> *</span>
                                                </label>
                                                <div class="col-sm">
                                                    <input id="contact5" type="text" class="form-control s_website_form_input" formControlName="subject" required="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-0 py-2 col-12 s_website_form_field s_website_form_custom s_website_form_required" data-type="text" data-name="Field">
                                            <div class="row s_col_no_resize s_col_no_bgcolor">
                                                <label class="col-form-label col-sm-auto s_website_form_label" style="width: 200px" for="contact6">
                                                    <span class="s_website_form_label_content">{{"Question" | translate}}</span>
                                                    <span class="s_website_form_mark"> *</span>
                                                </label>
                                                <div class="col-sm">
                                                    <textarea id="contact6" class="form-control s_website_form_input" formControlName="question" required=""></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div><h6 style="color: red;">{{this.errorMessage}}</h6></div>
                                        
                                        <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_dnone"><div class="row s_col_no_resize s_col_no_bgcolor"><label class="col-form-label col-sm-auto s_website_form_label" style="width: 200px"><span class="s_website_form_label_content"></span></label><div class="col-sm"><input type="hidden" class="form-control s_website_form_input" name="email_to" value="infos@befret.be"><input type="hidden" value="d7fabc0dbc1a9c952a75224574aa16cd337397a8f88ab40f65a27c010fee5ebb" class="form-control s_website_form_input s_website_form_custom" name="website_form_signature"></div></div></div><div class="mb-0 py-2 col-12 s_website_form_submit" data-name="Submit Button">
                                            <div style="width: 200px;" class="s_website_form_label"></div>
                                            <!-- <a href="#" role="button">Soumettre</a> -->
                                            <button type="submit"  class="btn btn-primary s_website_form_send">{{"Envoyer" | translate}}</button>
                                            <span id="s_website_form_result"></span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                    <div class="col-lg-4 mt-4 mt-lg-0 o_colored_level">
                        <ul class="list-unstyled mb-0 ps-2">
                            <li>BeFret</li>
                            <li><i class="fa fa-map-marker fa-fw me-2"></i>
                                <span class="o_force_ltr">35,&nbsp;Rue Ilya&nbsp;PRIGOGINE,&nbsp;1480&nbsp;Saintes (Tubize)</span>
                            </li>
                            <li><i></i><span class="o_force_ltr">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Belgique</span></li>
                            <li><i class="fa fa-phone fa-fw me-2"></i><span class="o_force_ltr">+32 465 26 58 48</span></li>
                            <li><i class="fa fa-1x fa-fw fa-envelope me-2"></i>info&#64;befret.be</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>

<div id="o_shared_blocks" class="oe_unremovable" data-oe-id="1361" data-oe-xpath="/data/xpath/div" data-oe-model="ir.ui.view" data-oe-field="arch"></div>
        </main>