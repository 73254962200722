<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Avis de confidentialité" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="privacy-policy col-lg-6">
                        <!-- Long Privacy Policy Content Here -->
                        <!-- <h2>1. Introduction</h2> -->
                        <p>
                            {{"Dernière mise à jour : Le 15.10.2024" | translate}}<br>
                            {{"La protection de vos données personnelles est essentielle pour BEFRET SRL. Cet avis de confidentialité explique comment nous recueillons, utilisons, stockons et protégeons vos informations personnelles lorsque vous utilisez notre site web befret.be ou interagissez avec nous." | translate}}La protection de vos données personnelles est essentielle pour BEFRET SRL. Cet avis de confidentialité explique comment nous recueillons, utilisons, stockons et protégeons vos informations personnelles lorsque vous utilisez notre site web befret.be ou interagissez avec nous.
                        </p>
                        <h2>{{"1. Responsable du traitement des données" | translate}}</h2>
                        <p>
                            <strong>{{"BEFRET SRL" | translate}}</strong><br>
                            {{"Adresse : 35, Rue Ilya PRIGOGINE, 1480 Saintes (Tubize), Belgique" | translate}}<br>
                            {{"Email : info&#64;befret.be" | translate}}<br>
                            {{"Téléphone : " | translate}}(à compléter)<br>
                            <strong>{{"BEFRET SRL" | translate}}</strong> {{"est responsable du traitement des données personnelles que nous collectons auprès de vous conformément à la législation en vigueur, y compris le" | translate}}<strong> {{"Règlement Général sur la Protection des Données (RGPD)." | translate}}</strong>
                        </p>
                        <h2>{{"2. Données personnelles collectées" | translate}}</h2>
                        <p>
                            {{"Nous collectons les données personnelles que vous nous fournissez directement lors de l'utilisation de nos services, ainsi que les informations collectées automatiquement lorsque vous naviguez sur notre site." | translate}}
                        </p>
                        <h3>{{"2.1. Données collectées directement" | translate}}</h3>
                        <!-- <p>
                            Toute personne peut reproduire une partie du contenu de ces pages Web sous réserve des conditions suivantes :Add your long privacy policy text here
                        </p> -->
                        <ul>
                            <li><strong>{{"Informations d'identification : " | translate}}</strong>{{"Nom, prénom, adresse postale, numéro de téléphone, adresse e-mail." | translate}}</li>
                            <li><strong>{{"Informations de livraison : " | translate}}</strong>{{"Adresse de livraison, destinataire, coordonnées du destinataire." | translate}}</li>
                            <li><strong>{{"Données de facturation : " | translate}}</strong>{{"Adresse de facturation, numéro de TVA (pour les entreprises), moyens de paiement." | translate}}</li>
                            <li><strong>{{"Données de connexion : " | translate}}</strong>{{"Identifiant de compte, mot de passe." | translate}}</li>
                            
                        </ul>
                        <h3>{{"2.2. Données collectées automatiquement" | translate}}</h3>
                        <p>
                            {{"Toute personne peut reproduire une partie du contenu de ces pages Web sous réserve des conditions suivantes :" | translate}}<!-- Add your long privacy policy text here -->
                        </p>
                        <ul>
                            <li><strong>{{"Données de navigation : " | translate}}</strong>{{"Adresse IP, type de navigateur, pages visitées, durée de visite, cookies." | translate}}</li>
                            <li><strong>{{"Données de géolocalisation " | translate}}</strong>{{"(si activée)." | translate}}</li>
                            
                        </ul>
                        <h2>{{"3. Utilisation des données personnelles" | translate}}</h2>
                        <p>
                            {{"Les données personnelles que nous collectons sont utilisées pour :" | translate}}</p>
                            <ul>
                                <li><strong>{{"Gérer vos commandes : " | translate}}</strong>{{"Traitement des commandes, envois de colis, gestion des retours, notifications sur le suivi des colis." | translate}}</li>
                                <li><strong>{{"Communiquer avec vous : " | translate}}</strong>{{"Répondre à vos questions, vous envoyer des informations relatives à vos commandes et services." | translate}}</li>
                                <li><strong>{{"Améliorer notre service : " | translate}}</strong>{{"Personnaliser votre expérience utilisateur, analyser les performances de notre site." | translate}}</li>
                                <li><strong>{{"Conformité légale : " | translate}}</strong>{{"Respect des obligations légales et réglementaires, y compris la facturation et la déclaration fiscale." | translate}}</li>
                                
                            </ul>
                        
                        <h2>{{"4. Partage des données" | translate}}</h2>
                        <p>
                            {{"Nous ne partageons vos données personnelles avec des tiers que dans les circonstances suivantes :" | translate}}
                        </p>
                        <ul>
                            <li><strong>{{"Partenaires logistiques et fournisseurs : " | translate}}</strong>{{"Pour assurer la livraison de vos colis." | translate}}</li>
                            <li><strong>{{"Services financiers et bancaires : " | translate}}</strong>{{"Pour traiter les paiements." | translate}}</li>
                            <li><strong>{{"Autorités légales : " | translate}}</strong>{{"En cas d'obligation légale ou de demande officielle des autorités compétentes." | translate}}</li>
                            <!-- <li><strong>•	Conformité légale : </strong>Respect des obligations légales et réglementaires, y compris la facturation et la déclaration fiscale.</li> -->
                            
                        </ul>
                        {{"Nous nous engageons à ne pas vendre, louer ou céder vos informations personnelles à des tiers à des fins commerciales." | translate}}

                    <h2>{{"5. Conservation des données" | translate}}</h2>
                    <p>
                        {{"Vos données personnelles sont conservées aussi longtemps que nécessaire pour accomplir les finalités pour lesquelles elles ont été collectées :" | translate}}
                    </p>
                    <ul>
                        <li><strong>{{"Données de commande : " | translate}}</strong>{{"Conservées pendant toute la durée de la relation commerciale et jusqu'à 10 ans après pour des obligations légales et fiscales." | translate}}</li>
                        <li><strong>{{"Données de navigation : " | translate}}</strong>{{"Conservées pour une durée maximale de 13 mois." | translate}}</li>
                        <!-- <li><strong>Autorités légales : </strong>En cas d'obligation légale ou de demande officielle des autorités compétentes.</li> -->
                        <!-- <li><strong>•	Conformité légale : </strong>Respect des obligations légales et réglementaires, y compris la facturation et la déclaration fiscale.</li> -->
                        
                    </ul>
                    <h2>{{"6. Sécurité des données" | translate}}</h2>
                    <p>
                    <p>
                        {{"Nous mettons en place des mesures de sécurité techniques et organisationnelles pour protéger vos données personnelles contre la perte, l'accès non autorisé, la modification ou la divulgation. Cela inclut :" | translate}}
                    </p>
                    <ul>
                        <li>{{"L'utilisation de serveurs sécurisés." | translate}}</li>
                        <li>{{"Le chiffrement des données de paiement." | translate}}</li>
                        <li>{{"La protection par mot de passe et l'authentification à plusieurs facteurs (le cas échéant)." | translate}}</li>
                        <!-- <li>Toute reproduction doit inclure la mention de droit d'auteur suivante :
                            Copyright © BEFRET. Tous droits réservés.
                            </li> -->
                    </ul>
                    <h2>{{"7. Vos droits" | translate}}</h2>
                    <p>
                        {{"Conformément au RGPD, vous disposez des droits suivants concernant vos données personnelles :" | translate}}
                    </p>
                    <ul>
                        <li><strong>{{"Droit d'accès : " | translate}}</strong>{{"Vous pouvez demander à consulter les données personnelles que nous détenons à votre sujet." | translate}}</li>
                        <li><strong>{{"Droit de rectification : " | translate}}</strong>{{"Vous avez le droit de corriger vos données si elles sont inexactes ou incomplètes." | translate}}</li>
                        <li><strong>{{"Droit à l’effacement : " | translate}}</strong>{{"Vous pouvez demander la suppression de vos données personnelles, sous certaines conditions." | translate}}</li>
                        <li><strong>{{"Droit d’opposition : " | translate}}</strong>{{"Vous pouvez vous opposer au traitement de vos données pour des motifs légitimes." | translate}}</li>
                        <li><strong>{{"Droit à la portabilité des données : " | translate}}</strong>{{"Vous avez le droit de recevoir vos données personnelles dans un format structuré et couramment utilisé." | translate}}</li>
                        <li><strong>{{"Droit de retirer votre consentement : " | translate}}</strong>{{"Si vous avez donné votre consentement à un traitement de données, vous pouvez le retirer à tout moment." | translate}}</li>
                        <!-- <li><strong>•	Conformité légale : </strong>Respect des obligations légales et réglementaires, y compris la facturation et la déclaration fiscale.</li> -->
                        
                    </ul>
                    <p>
                        {{"Pour exercer ces droits, vous pouvez nous contacter par email à : info@befret.be." | translate}}
                    </p>
                    <h2>{{"8. Cookies et technologies similaires" | translate}}</h2>
                    <p>
                        {{"Nous utilisons des cookies et des technologies similaires pour améliorer votre expérience sur notre site. Un cookie est un fichier texte placé sur votre appareil lors de votre visite sur le site. Il permet de mémoriser certaines informations concernant vos préférences." | translate}}<br>
                        {{"Vous pouvez gérer vos préférences en matière de cookies directement via les paramètres de votre navigateur." | translate}}
                    </p>
                    <h2>{{"9. Transfert international de données" | translate}}</h2>
                    <p>
                        {{"Dans certains cas, les données que nous collectons peuvent être transférées et traitées en dehors de l'Union européenne. Si cela devait arriver, nous veillerions à ce que vos données bénéficient du même niveau de protection en conformité avec les lois européennes, notamment en utilisant des clauses contractuelles types approuvées par la Commission européenne." | translate}}
                    </p>
                    <h2>{{"10. Modifications de l'avis de confidentialité" | translate}}</h2>
                    <p>
                        {{"Cet avis de confidentialité peut être mis à jour à tout moment. Toute modification sera publiée sur cette page et, si elle est substantielle, nous vous informerons par e-mail ou via une notification sur notre site." | translate}}<br>
                        {{"Nous vous encourageons à consulter régulièrement cet avis de confidentialité pour rester informé des éventuels changements." | translate}}
                    </p>
                    <h2>{{"11. Contact" | translate}}</h2>
                    <p>
                        {{"Pour toute question ou demande d’information supplémentaire concernant cet avis de confidentialité, vous pouvez nous contacter à :" | translate}}<br>
                        <strong>{{"BEFRET SRL" | translate}}</strong><br>
                        {{"35, Rue Ilya PRIGOGINE, 1480 Saintes (Tubize), Belgique" | translate}}<br>
                        {{"Email : info&#64;befret.be" | translate}}<br>
                        {{"Téléphone : " | translate}}

                    </p>
                    
                    </div>
                </div></div></section>
        <!-- <section>
            
              
        </section> -->
    </div></main>