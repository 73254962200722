<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Mentions légales" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="privacy-policy col-lg-6">
                        <!-- Long Privacy Policy Content Here -->
                        <h3>{{"1. Identification de la société" | translate}}</h3>
                        <p>
                            <strong>{{"Nom de la société :" | translate}}</strong> {{"BEFRET SRL" | translate}}<br>
                            <strong>{{"Forme juridique :" | translate}}</strong> {{"Société à Responsabilité Limitée (SRL)" | translate}}<br>
                            <strong>{{"Adresse : " | translate}}</strong>{{"35, Rue Ilya PRIGOGINE, 1480 Saintes (Tubize), Belgique" | translate}}<br>
                            <strong>{{"Numéro d'entreprise : " | translate}}</strong><br>
                            <strong>{{"Numéro de TVA : " | translate}}</strong><br>
                            <strong>{{"Email : " | translate}}</strong>info&#64;befret.be<br>
                            <strong>{{"Téléphone : " | translate}}</strong><br>

                        </p>
                        <h3>{{"2. Hébergeur du site" | translate}}</h3>
                        <p>
                            <strong>{{"Hébergeur : " | translate}}</strong>COMBELL<br>
                            <strong>{{"Adresse de l'hébergeur : " | translate}}</strong>Skaldenstraat 121, 9042 Gent<br>
                            <strong>{{"Téléphone de l'hébergeur : " | translate}}</strong> 080085678<br>
                            <strong>{{"Site web de l'hébergeur :" | translate}}</strong> www.combell.com<br>

                        </p>
                        <h3>{{"3. Directeur de publication" | translate}}</h3>
                        <p>
                            <strong>{{"Directeur de publication : " | translate}}</strong>Kingwaya Pistis<br>
                            <strong>{{"Contact : " | translate}}</strong>info&#64;befret.be<br>
                        </p>
                        <h3>{{"4. Objet du site" | translate}}</h3>
                        <p>
                            {{"Le site befret.be a pour objectif de fournir des informations sur les services d’envoi de colis en ligne de l'Europe vers la RDCongo, proposés par la société BEFRET SRL. Le site permet aux utilisateurs de commander des services d’expédition, de suivre leurs colis, et d'accéder à d'autres informations liées à leurs envois." | translate}}
                        </p>
                        <h3>{{"5. Propriété intellectuelle" | translate}}</h3>
                        <p>
                            {{"Tout le contenu du site befret.be (textes, images, logos, vidéos, graphismes, icônes, logiciels, etc.) est la propriété exclusive de BEFRET SRL, ou est utilisé avec l'autorisation des titulaires des droits d'auteur concernés." | translate}}<br>
                            {{"Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord écrit préalable de BEFRET SRL." | translate}}

                        </p>
                        <h3>{{"6. Responsabilité" | translate}}</h3>
                        <p>
                            {{"BEFRET SRL s'efforce de fournir des informations exactes et mises à jour sur son site. Cependant, BEFRET ne garantit pas l'exactitude, la complétude ou l'actualité des informations disponibles. L’utilisation des informations et services offerts sur le site se fait sous l’entière responsabilité de l’utilisateur." | translate}}<br><br>
                            {{"BEFRET SRL ne peut être tenue responsable des dommages, directs ou indirects, résultant de l'accès au site ou de son utilisation, y compris, mais sans s’y limiter, aux pertes de données, virus, bugs ou autres inconvénients informatiques." | translate}}
                            
                        
                        <h3>{{"7. Collecte et protection des données personnelles" | translate}}</h3>
                        <p>
                            {{"Les données personnelles collectées via le site befret.be (nom, adresse, numéro de téléphone, e-mail, etc.) sont utilisées exclusivement dans le cadre de la gestion des services proposés par BEFRET SRL." | translate}}<br>
                            {{"Ces informations sont confidentielles et ne sont en aucun cas partagées avec des tiers sans votre consentement, sauf obligation légale." | translate}}<br><br>
                            {{"Conformément au " | translate}}<strong>{{"Règlement Général sur la Protection des Données (RGPD)" | translate}}</strong>{{", vous disposez d'un droit d'accès, de rectification, et de suppression de vos données personnelles. Pour exercer ces droits, vous pouvez contacter BEFRET à l’adresse suivante : " | translate}}<strong>info&#64;befret.be</strong>
                        </p>

                    <h3>{{"8. Cookies" | translate}}</h3>
                    <p>
                        {{"Le site befret.be utilise des cookies pour améliorer l'expérience utilisateur, notamment pour les statistiques de fréquentation et l'accès aux services. Vous pouvez à tout moment désactiver les cookies depuis les paramètres de votre navigateur." | translate}}
                    </p>
                    <h3>{{"9. Liens hypertexte" | translate}}s</h3>
                    <p>
                        {{"Le site befret.be peut contenir des liens vers des sites tiers. BEFRET SRL décline toute responsabilité concernant le contenu, l’exactitude ou le bon fonctionnement de ces sites externes. Les utilisateurs naviguent sur ces sites sous leur propre responsabilité." | translate}}
                    </p>
                    <h3>{{"10. Loi applicable et juridiction" | translate}}</h3>
                    <p>
                        {{"Les présentes mentions légales sont régies par les lois belges. En cas de litige, et après l'échec de toute tentative de recherche de solution amiable, les tribunaux belges seront seuls compétents pour connaître de ce litige." | translate}}                    </p>

                    <h3>{{"11. Modification des mentions légales" | translate}}</h3>
                    <p>
                        {{"BEFRET SRL se réserve le droit de modifier les présentes mentions légales à tout moment. Les modifications seront effectives dès leur publication sur le site. Nous encourageons les utilisateurs à consulter régulièrement cette page pour prendre connaissance des éventuels changements." | translate}}
                    </p>
                    
                    </div>
                </div></div></section>
        <!-- <section>
            
              
        </section> -->
    </div></main>
