import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth.service';
import { Route, Router } from '@angular/router';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrl: './history.component.css'
})
export class HistoryComponent implements OnInit{
  parcels: any[] = []; // Array to store the parcels
  userId?: string; // Store the user's ID

  constructor(
    private firestore: AngularFirestore, 
    private authService: AuthService,
    private router: Router
  ){

  }
  ngOnInit(): void {
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.fetchUserParcels();
        console.log("User: ", this.userId);
      }
    });
  }

  
  fetchUserParcels() {
    // Fetch parcels where the userId matches the current user's ID
    this.firestore.collection('parcel', ref => ref.where('uid', '==', this.userId))
      .valueChanges()
      .subscribe((data: any[]) => {
        this.parcels = data;
      });

      this.firestore.collection('parcel', ref => ref
        .where('uid', '==', this.userId)
        .orderBy('command_date', 'asc')) // Order by command_date descending
      // .valueChanges({ idField: 'id' })
      .valueChanges()
      .subscribe((data: any[]) => {
        this.parcels = data;
        // this.loading = false;
      });
      
  }
  viewDetails(trackingID: string): void {
    this.router.navigate(['/details'], { queryParams: { trackingID } });
  }

}
