import { Component, OnInit } from '@angular/core';
import { UserProfile } from '../models/user-profile';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrl: './create-employee.component.css'
})
export class CreateEmployeeComponent implements OnInit{
  userProfile?: UserProfile | null = null;
  credentialCorrect = true;
  employeeForm!: FormGroup;
  isLoading=false;
  cities = {'kinshasa': "Kinshasa", 'lubumbashi': "Lubumbashi", 'tubize': "Tubize"};
  abilities = {'delivery': "Livreur", 'stock': "Entrêpot", 'transit':"Transitaire", 'manager': "Manager", 'topManager': "Top Manager"};
  errorPassword = "";
  phonePrefixes = [{ label: 'Afghanistan (+93)', value: '+93', short: 'AF (+93)' },
    { label: 'Albania (+355)', value: '+355', short: 'AL (+355)' },
    { label: 'Algeria (+213)', value: '+213', short: 'DZ (+213)' },
    { label: 'American Samoa (+1-684)', value: '+1-684', short: 'AS (+1-684)' },
    { label: 'Andorra (+376)', value: '+376', short: 'AD (+376)' },
    { label: 'Angola (+244)', value: '+244', short: 'AO (+244)' },
    { label: 'Anguilla (+1-264)', value: '+1-264', short: 'AI (+1-264)' },
    { label: 'Antarctica (+672)', value: '+672', short: 'AQ (+672)' },
    { label: 'Antigua and Barbuda (+1-268)', value: '+1-268', short: 'AG (+1-268)' },
    { label: 'Argentina (+54)', value: '+54', short: 'AR (+54)' },
    { label: 'Armenia (+374)', value: '+374', short: 'AM (+374)' },
    { label: 'Aruba (+297)', value: '+297', short: 'AW (+297)' },
    { label: 'Australia (+61)', value: '+61', short: 'AU (+61)' },
    { label: 'Austria (+43)', value: '+43', short: 'AT (+43)' },
    { label: 'Azerbaijan (+994)', value: '+994', short: 'AZ (+994)' },
    { label: 'Bahamas (+1-242)', value: '+1-242', short: 'BS (+1-242)' },
    { label: 'Bahrain (+973)', value: '+973', short: 'BH (+973)' },
    { label: 'Bangladesh (+880)', value: '+880', short: 'BD (+880)' },
    { label: 'Barbados (+1-246)', value: '+1-246', short: 'BB (+1-246)' },
    { label: 'Belarus (+375)', value: '+375', short: 'BY (+375)' },
    { label: 'Belgium (+32)', value: '+32', short: 'BE (+32)' },
    { label: 'Belize (+501)', value: '+501', short: 'BZ (+501)' },
    { label: 'Benin (+229)', value: '+229', short: 'BJ (+229)' },
    { label: 'Bermuda (+1-441)', value: '+1-441', short: 'BM (+1-441)' },
    { label: 'Bhutan (+975)', value: '+975', short: 'BT (+975)' },
    { label: 'Bolivia (+591)', value: '+591', short: 'BO (+591)' },
    { label: 'Bosnia and Herzegovina (+387)', value: '+387', short: 'BA (+387)' },
    { label: 'Botswana (+267)', value: '+267', short: 'BW (+267)' },
    { label: 'Brazil (+55)', value: '+55', short: 'BR (+55)' },
    { label: 'Brunei (+673)', value: '+673', short: 'BN (+673)' },
    { label: 'Bulgaria (+359)', value: '+359', short: 'BG (+359)' },
    { label: 'Burkina Faso (+226)', value: '+226', short: 'BF (+226)' },
    { label: 'Burundi (+257)', value: '+257', short: 'BI (+257)' },
    { label: 'Cabo Verde (+238)', value: '+238', short: 'CV (+238)' },
    { label: 'Cambodia (+855)', value: '+855', short: 'KH (+855)' },
    { label: 'Cameroon (+237)', value: '+237', short: 'CM (+237)' },
    { label: 'Canada (+1)', value: '+1', short: 'CA (+1)' },
    { label: 'Central African Republic (+236)', value: '+236', short: 'CF (+236)' },
    { label: 'Chad (+235)', value: '+235', short: 'TD (+235)' },
    { label: 'Chile (+56)', value: '+56', short: 'CL (+56)' },
    { label: 'China (+86)', value: '+86', short: 'CN (+86)' },
    { label: 'Colombia (+57)', value: '+57', short: 'CO (+57)' },
    { label: 'Comoros (+269)', value: '+269', short: 'KM (+269)' },
    { label: 'Congo (+242)', value: '+242', short: 'CG (+242)' },
    { label: 'Congo, Democratic Republic of the (+243)', value: '+243', short: 'COD (+243)' },
    { label: 'Cook Islands (+682)', value: '+682', short: 'CK (+682)' },
    { label: 'Costa Rica (+506)', value: '+506', short: 'CR (+506)' },
    { label: 'Croatia (+385)', value: '+385', short: 'HR (+385)' },
    { label: 'Cuba (+53)', value: '+53', short: 'CU (+53)' },
    { label: 'Cyprus (+357)', value: '+357', short: 'CY (+357)' },
    { label: 'Czech Republic (+420)', value: '+420', short: 'CZ (+420)' },
    { label: 'Denmark (+45)', value: '+45', short: 'DK (+45)' },
    { label: 'Djibouti (+253)', value: '+253', short: 'DJ (+253)' },
    { label: 'Dominica (+1-767)', value: '+1-767', short: 'DM (+1-767)' },
    { label: 'Dominican Republic (+1-809)', value: '+1-809', short: 'DO (+1-809)' },
    { label: 'Ecuador (+593)', value: '+593', short: 'EC (+593)' },
    { label: 'Egypt (+20)', value: '+20', short: 'EG (+20)' },
    { label: 'El Salvador (+503)', value: '+503', short: 'SV (+503)' },
    { label: 'Equatorial Guinea (+240)', value: '+240', short: 'GQ (+240)' },
    { label: 'Eritrea (+291)', value: '+291', short: 'ER (+291)' },
    { label: 'Estonia (+372)', value: '+372', short: 'EE (+372)' },
    { label: 'Eswatini (+268)', value: '+268', short: 'SZ (+268)' },
    { label: 'Ethiopia (+251)', value: '+251', short: 'ET (+251)' },
    { label: 'Fiji (+679)', value: '+679', short: 'FJ (+679)' },
    { label: 'Finland (+358)', value: '+358', short: 'FI (+358)' },
    { label: 'France (+33)', value: '+33', short: 'FR (+33)' },
    { label: 'Gabon (+241)', value: '+241', short: 'GA (+241)' },
    { label: 'Gambia (+220)', value: '+220', short: 'GM (+220)' },
    { label: 'Georgia (+995)', value: '+995', short: 'GE (+995)' },
    { label: 'Germany (+49)', value: '+49', short: 'DE (+49)' },
    { label: 'Ghana (+233)', value: '+233', short: 'GH (+233)' },
    { label: 'Greece (+30)', value: '+30', short: 'GR (+30)' },
    { label: 'Grenada (+1-473)', value: '+1-473', short: 'GD (+1-473)' },
    { label: 'Guatemala (+502)', value: '+502', short: 'GT (+502)' },
    { label: 'Guinea (+224)', value: '+224', short: 'GN (+224)' },
    { label: 'Guinea-Bissau (+245)', value: '+245', short: 'GW (+245)' },
    { label: 'Guyana (+592)', value: '+592', short: 'GY (+592)' },
    { label: 'Haiti (+509)', value: '+509', short: 'HT (+509)' },
    { label: 'Honduras (+504)', value: '+504', short: 'HN (+504)' },
    { label: 'Hungary (+36)', value: '+36', short: 'HU (+36)' },
    { label: 'Iceland (+354)', value: '+354', short: 'IS (+354)' },
    { label: 'India (+91)', value: '+91', short: 'IN (+91)' },
    { label: 'Indonesia (+62)', value: '+62', short: 'ID (+62)' },
    { label: 'Iran (+98)', value: '+98', short: 'IR (+98)' },
    { label: 'Iraq (+964)', value: '+964', short: 'IQ (+964)' },
    { label: 'Ireland (+353)', value: '+353', short: 'IE (+353)' },
    { label: 'Israel (+972)', value: '+972', short: 'IL (+972)' },
    { label: 'Italy (+39)', value: '+39', short: 'IT (+39)' },
    { label: 'Jamaica (+1-876)', value: '+1-876', short: 'JM (+1-876)' },
    { label: 'Japan (+81)', value: '+81', short: 'JP (+81)' },
    { label: 'Jordan (+962)', value: '+962', short: 'JO (+962)' },
    { label: 'Kazakhstan (+7)', value: '+7', short: 'KZ (+7)' },
    { label: 'Kenya (+254)', value: '+254', short: 'KE (+254)' },
    { label: 'Kiribati (+686)', value: '+686', short: 'KI (+686)' },
    { label: 'Kuwait (+965)', value: '+965', short: 'KW (+965)' },
    { label: 'Kyrgyzstan (+996)', value: '+996', short: 'KG (+996)' },
    { label: 'Laos (+856)', value: '+856', short: 'LA (+856)' },
    { label: 'Latvia (+371)', value: '+371', short: 'LV (+371)' },
    { label: 'Lebanon (+961)', value: '+961', short: 'LB (+961)' },
    { label: 'Lesotho (+266)', value: '+266', short: 'LS (+266)' },
    { label: 'Liberia (+231)', value: '+231', short: 'LR (+231)' },
    { label: 'Libya (+218)', value: '+218', short: 'LY (+218)' },
    { label: 'Liechtenstein (+423)', value: '+423', short: 'LI (+423)' },
    { label: 'Lithuania (+370)', value: '+370', short: 'LT (+370)' },
    { label: 'Luxembourg (+352)', value: '+352', short: 'LU (+352)' },
    { label: 'Madagascar (+261)', value: '+261', short: 'MG (+261)' },
    { label: 'Malawi (+265)', value: '+265', short: 'MW (+265)' },
    { label: 'Malaysia (+60)', value: '+60', short: 'MY (+60)' },
    { label: 'Maldives (+960)', value: '+960', short: 'MV (+960)' },
    { label: 'Mali (+223)', value: '+223', short: 'ML (+223)' },
    { label: 'Malta (+356)', value: '+356', short: 'MT (+356)' },
    { label: 'Marshall Islands (+692)', value: '+692', short: 'MH (+692)' },
    { label: 'Mauritania (+222)', value: '+222', short: 'MR (+222)' },
    { label: 'Mauritius (+230)', value: '+230', short: 'MU (+230)' },
    { label: 'Mexico (+52)', value: '+52', short: 'MX (+52)' },
    { label: 'Micronesia (+691)', value: '+691', short: 'FM (+691)' },
    { label: 'Moldova (+373)', value: '+373', short: 'MD (+373)' },
    { label: 'Monaco (+377)', value: '+377', short: 'MC (+377)' },
    { label: 'Mongolia (+976)', value: '+976', short: 'MN (+976)' },
    { label: 'Montenegro (+382)', value: '+382', short: 'ME (+382)' },
    { label: 'Montserrat (+1-664)', value: '+1-664', short: 'MS (+1-664)' },
    { label: 'Morocco (+212)', value: '+212', short: 'MA (+212)' },
    { label: 'Mozambique (+258)', value: '+258', short: 'MZ (+258)' },
    { label: 'Myanmar (+95)', value: '+95', short: 'MM (+95)' },
    { label: 'Namibia (+264)', value: '+264', short: 'NA (+264)' },
    { label: 'Nauru (+674)', value: '+674', short: 'NR (+674)' },
    { label: 'Nepal (+977)', value: '+977', short: 'NP (+977)' },
    { label: 'Netherlands (+31)', value: '+31', short: 'NL (+31)' },
    { label: 'New Zealand (+64)', value: '+64', short: 'NZ (+64)' },
    { label: 'Nicaragua (+505)', value: '+505', short: 'NI (+505)' },
    { label: 'Niger (+227)', value: '+227', short: 'NE (+227)' },
    { label: 'Nigeria (+234)', value: '+234', short: 'NG (+234)' },
    { label: 'North Korea (+850)', value: '+850', short: 'KP (+850)' },
    { label: 'North Macedonia (+389)', value: '+389', short: 'MK (+389)' },
    { label: 'Norway (+47)', value: '+47', short: 'NO (+47)' },
    { label: 'Oman (+968)', value: '+968', short: 'OM (+968)' },
    { label: 'Pakistan (+92)', value: '+92', short: 'PK (+92)' },
    { label: 'Palau (+680)', value: '+680', short: 'PW (+680)' },
    { label: 'Palestine (+970)', value: '+970', short: 'PS (+970)' },
    { label: 'Panama (+507)', value: '+507', short: 'PA (+507)' },
    { label: 'Papua New Guinea (+675)', value: '+675', short: 'PG (+675)' },
    { label: 'Paraguay (+595)', value: '+595', short: 'PY (+595)' },
    { label: 'Peru (+51)', value: '+51', short: 'PE (+51)' },
    { label: 'Philippines (+63)', value: '+63', short: 'PH (+63)' },
    { label: 'Poland (+48)', value: '+48', short: 'PL (+48)' },
    { label: 'Portugal (+351)', value: '+351', short: 'PT (+351)' },
    { label: 'Qatar (+974)', value: '+974', short: 'QA (+974)' },
    { label: 'Romania (+40)', value: '+40', short: 'RO (+40)' },
    { label: 'Russia (+7)', value: '+7', short: 'RU (+7)' },
    { label: 'Rwanda (+250)', value: '+250', short: 'RW (+250)' },
    { label: 'Saint Kitts and Nevis (+1-869)', value: '+1-869', short: 'KN (+1-869)' },
    { label: 'Saint Lucia (+1-758)', value: '+1-758', short: 'LC (+1-758)' },
    { label: 'Saint Vincent and the Grenadines (+1-784)', value: '+1-784', short: 'VC (+1-784)' },
    { label: 'Samoa (+685)', value: '+685', short: 'WS (+685)' },
    { label: 'San Marino (+378)', value: '+378', short: 'SM (+378)' },
    { label: 'Sao Tome and Principe (+239)', value: '+239', short: 'ST (+239)' },
    { label: 'Saudi Arabia (+966)', value: '+966', short: 'SA (+966)' },
    { label: 'Senegal (+221)', value: '+221', short: 'SN (+221)' },
    { label: 'Serbia (+381)', value: '+381', short: 'RS (+381)' },
    { label: 'Seychelles (+248)', value: '+248', short: 'SC (+248)' },
    { label: 'Sierra Leone (+232)', value: '+232', short: 'SL (+232)' },
    { label: 'Singapore (+65)', value: '+65', short: 'SG (+65)' },
    { label: 'Slovakia (+421)', value: '+421', short: 'SK (+421)' },
    { label: 'Slovenia (+386)', value: '+386', short: 'SI (+386)' },
    { label: 'Solomon Islands (+677)', value: '+677', short: 'SB (+677)' },
    { label: 'Somalia (+252)', value: '+252', short: 'SO (+252)' },
    { label: 'South Africa (+27)', value: '+27', short: 'ZA (+27)' },
    { label: 'South Korea (+82)', value: '+82', short: 'KR (+82)' },
    { label: 'South Sudan (+211)', value: '+211', short: 'SS (+211)' },
    { label: 'Spain (+34)', value: '+34', short: 'ES (+34)' },
    { label: 'Sri Lanka (+94)', value: '+94', short: 'LK (+94)' },
    { label: 'Sudan (+249)', value: '+249', short: 'SD (+249)' },
    { label: 'Suriname (+597)', value: '+597', short: 'SR (+597)' },
    { label: 'Sweden (+46)', value: '+46', short: 'SE (+46)' },
    { label: 'Switzerland (+41)', value: '+41', short: 'CH (+41)' },
    { label: 'Syria (+963)', value: '+963', short: 'SY (+963)' },
    { label: 'Taiwan (+886)', value: '+886', short: 'TW (+886)' },
    { label: 'Tajikistan (+992)', value: '+992', short: 'TJ (+992)' },
    { label: 'Tanzania (+255)', value: '+255', short: 'TZ (+255)' },
    { label: 'Thailand (+66)', value: '+66', short: 'TH (+66)' },
    { label: 'Timor-Leste (+670)', value: '+670', short: 'TL (+670)' },
    { label: 'Togo (+228)', value: '+228', short: 'TG (+228)' },
    { label: 'Tonga (+676)', value: '+676', short: 'TO (+676)' },
    { label: 'Trinidad and Tobago (+1-868)', value: '+1-868', short: 'TT (+1-868)' },
    { label: 'Tunisia (+216)', value: '+216', short: 'TN (+216)' },
    { label: 'Turkey (+90)', value: '+90', short: 'TR (+90)' },
    { label: 'Turkmenistan (+993)', value: '+993', short: 'TM (+993)' },
    { label: 'Tuvalu (+688)', value: '+688', short: 'TV (+688)' },
    { label: 'Uganda (+256)', value: '+256', short: 'UG (+256)' },
    { label: 'Ukraine (+380)', value: '+380', short: 'UA (+380)' },
    { label: 'United Arab Emirates (+971)', value: '+971', short: 'AE (+971)' },
    { label: 'United Kingdom (+44)', value: '+44', short: 'GB (+44)' },
    { label: 'United States (+1)', value: '+1', short: 'US (+1)' },
    { label: 'Uruguay (+598)', value: '+598', short: 'UY (+598)' },
    { label: 'Uzbekistan (+998)', value: '+998', short: 'UZ (+998)' },
    { label: 'Vanuatu (+678)', value: '+678', short: 'VU (+678)' },
    { label: 'Vatican City (+39)', value: '+39', short: 'VA (+39)' },
    { label: 'Venezuela (+58)', value: '+58', short: 'VE (+58)' },
    { label: 'Vietnam (+84)', value: '+84', short: 'VN (+84)' },
    { label: 'Yemen (+967)', value: '+967', short: 'YE (+967)' },
    { label: 'Zambia (+260)', value: '+260', short: 'ZM (+260)' },
    { label: 'Zimbabwe (+263)', value: '+263', short: 'ZW (+263)' }];
  
  constructor(
    private router: Router,
    private af : AuthService,
    private fb: FormBuilder
  ){}
  ngOnInit(): void {
    this.employeeForm = this.fb.group({
      email: ['', [Validators.email]], // Wrap validators in an array
      password: ['', [Validators.minLength(6)]],
      password2: [''],
      phoneNumber: ['', Validators.required],
      phonePrefix: ["", Validators.required],
      // country:[""],
      // phoneNumber2: ['', [Validators.required, Validators.pattern(/^\+?\d{10,15}$/)]],
      fullname: [''],
      code: [''],
      ability:['', Validators.required],
      city:['']
    });
  }

  async onSubmit(){
    // const fullname = this.employeeForm.get("fullname")?.value;
    // const email = this.employeeForm.get("email")?.value;
    // const password = this.employeeForm.get("password")?.value;
    // const password2 = this.employeeForm.get("password2")?.value;
    // const ability = this.employeeForm.get("ability")?.value;
    // const city = this.employeeForm.get("city")?.value;
    const {fullname, email, password, password2, ability, city, phonePrefix, phoneNumber} = this.employeeForm.value;
    if (this.employeeForm.valid){
      if (password === password2){
        try {
          const { email, fullname, password, ability } = this.employeeForm.value;
    
          // Wait for the user creation to complete
          this.af.signUp4(email, password, fullname).then((user) => {
            if (user) {
              // If user exists, create the employee profile
              this.af.createEmployeeProfile({
                fullname,
                email,
                ability,
                isEmployee: true,
              }, user.uid);  // Pass the user UID here
            } else {
              console.error("User creation failed");
            }
          }).catch(error => {
            console.error("Error during employee creation: ", error);
          });
        }catch(error){}
      }else {
        console.log("Password didn't match");
        this.errorPassword = "Le mot de passe ne correspond pas"
      }
    }else {
      console.error("Form is not valid");
      this.errorPassword = "Veuillez remplir toutes les informations demandées";
    }
  }

}
