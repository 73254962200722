<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Conditions générales d'utilisation" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="privacy-policy col-lg-6">
                        <!-- Long Privacy Policy Content Here -->
                        <h2>{{"1. Introduction" | translate}}</h2>
                        <p>
                            {{"Bienvenue sur BeFret. En utilisant notre site web et nos services, vous acceptez de respecter les présentes conditions d'utilisation. Nous vous prions de les lire attentivement avant d'utiliser notre plateforme." | translate}}
                          <!-- Add your long privacy policy text here -->
                        </p>
                        <h2>{{"2. Droits d'auteur" | translate}}</h2>
                        <p>
                            {{"Les droits d'auteur de cette publication sont détenus par BEFRET SRL. BEFRET détient tous les droits liés aux publications sur ce site." | translate}}<!-- Add your long privacy policy text here -->
                        </p>
                        <h3>{{"Autorisation de reproduction" | translate}}</h3>
                        <p>
                            {{"Toute personne peut reproduire une partie du contenu de ces pages Web sous réserve des conditions suivantes :" | translate}}<!-- Add your long privacy policy text here -->
                        </p>
                        <ul>
                            <li>{{"Le matériel ne peut être utilisé qu'à des fins d'information et non commerciales." | translate}}</li>
                            <li>{{"Il ne peut être modifié d'aucune façon." | translate}}</li>
                            <li>{{"Aucune copie non autorisée d'une marque déposée BEFRET ne peut être effectuée." | translate}}</li>
                            <li>{{"Toute reproduction doit inclure la mention de droit d'auteur suivante :" | translate}}
                                {{"Copyright © BEFRET. Tous droits réservés." | translate}}
                                </li>
                        </ul>
                        <h2>{{"3. BEFRET, une marque déposée" | translate}}</h2>
                        <p>
                            {{"BEFRET est une marque déposée du groupe243. Aucune licence d'utilisation de cette marque n'est accordée. Cette marque ne peut être copiée, téléchargée, reproduite, utilisée, modifiée ou distribuée de quelque manière que ce soit, sauf dans le cadre d'une copie autorisée de matériel apparaissant sur ces pages, comme indiqué précédemment, sans autorisation écrite préalable." | translate}}<!-- Add your long privacy policy text here -->
                        </p>
                        <h2>{{"4. Vos commentaires" | translate}}</h2>
                        <p>
                            {{"BEFRET apprécie vos avis et suggestions. Bien que nous ne puissions pas répondre à chaque commentaire individuellement, nous vous remercions pour vos idées et critiques, et nous nous réservons la liberté d'intervenir comme bon nous semble." | translate}}
                        </p>
                        <h2>{{"5. Accès au service" | translate}}</h2>
                        <p>
                            {{"Pour utiliser BeFret, vous devez être majeur et avoir la capacité légale de contracter. L'inscription et l'accès à certains services peuvent nécessiter la création d'un compte avec des informations exactes et complètes." | translate}}
                        </p>
                        <h2>{{"6. Utilisation autorisée" | translate}}</h2>
                        <p>
                            {{"L'utilisation de BeFret est réservée à des fins légales et personnelles. Vous vous engagez à ne pas :" | translate}}</p>
                            <ul>
                                <li>{{"Utiliser la plateforme à des fins frauduleuses ou illégales." | translate}}</li>
                                <li>{{"Altérer ou perturber l’intégrité du service." | translate}}</li>
                                <li>{{"Accéder sans autorisation à des zones sécurisées de la plateforme" | translate}}.</li>
                                <!-- <li>Toute reproduction doit inclure la mention de droit d'auteur suivante :
                                    Copyright © BEFRET. Tous droits réservés.
                                    </li> -->
                            </ul>
                        
                        <h2>{{"7. Création de compte" | translate}}</h2>
                        <p>
                            {{"Les utilisateurs doivent fournir des informations exactes lors de la création de leur compte. Vous êtes responsable de la confidentialité de vos identifiants et des activités effectuées via votre compte." | translate}}
                        </p>

                    <h2>{{"8. Tarification et paiement" | translate}}</h2>
                    <p>
                        {{"Le montant à payer pour chaque service sera affiché avant confirmation. Toutes les transactions financières effectuées sur BeFret sont sécurisées. Vous acceptez de payer tous les frais applicables liés aux services utilisés." | translate}}
                    </p>
                    <h2>{{"9. Expédition et livraison" | translate}}</h2>
                    <p>
                        {{"BeFret propose deux types de livraison :" | translate}}
                    </p>
                    <ul>
                        <li>{{"Livraison à domicile : Votre colis est livré directement à l'adresse indiquée." | translate}}</li>
                        <li>{{"Livraison dans un point de rencontre : Les points de rencontre sont déterminés par BeFret, généralement situés dans des stations-service ou des supermarchés. Ces points de collecte ne peuvent pas être modifiés par l'utilisateur ou le destinataire." | translate}}</li>
                        <!-- <li>Accéder sans autorisation à des zones sécurisées de la plateforme.</li> -->
                        <!-- <li>Toute reproduction doit inclure la mention de droit d'auteur suivante :
                            Copyright © BEFRET. Tous droits réservés.
                            </li> -->
                    </ul>
                    {{"Les livraisons via BeFret sont effectuées uniquement entre 09h00 et 15h30, du lundi au samedi. Aucun envoi n'est effectué en dehors de ces horaires ou les jours fériés." | translate}}
                    <h2>{{"10. Délais de livraison" | translate}}</h2>
                    <p>
                        {{"Le délai de livraison, dès réception du colis à l'entrepôt BeFret, est de :" | translate}}
                    </p>
                    <ul>
                        <li>{{"2 à 3 jours pour Kinshasa" | translate}}</li>
                        <li>{{"2 à 5 jours pour Lubumbashi" | translate}}</li>
                        <!-- <li>Toute reproduction doit inclure la mention de droit d'auteur suivante :
                            Copyright © BEFRET. Tous droits réservés.
                            </li> -->
                    </ul>
                    {{"En cas de retard, notre service contactera le client pour l'informer de la situation." | translate}}
                    <h2>{{"11. Envoi via des services tiers" | translate}}</h2>
                    <p>
                        {{"Lorsque le client envoie son colis à BeFret via un service d'envoi tel que La Poste, Mondial Relay, Post NL ou tout autre, le délai d'envoi entre ce service et la réception chez BeFret dépend entièrement de ce service et non de BeFret. Nous ne pouvons être tenus responsables des retards occasionnés par ces services externes." | translate}}
                    </p>
                    <h2>{{"12. Poids du colis et exactitude des informations" | translate}}</h2>
                    <p>
                        {{"Les utilisateurs doivent fournir des informations précises concernant le poids de leur colis lors de l'envoi. Si le poids réel du colis diffère de celui indiqué, le colis ne sera pas expédié. BeFret contactera le client pour régulariser la différence de poids. Une fois la différence payée, le colis sera expédié." | translate}}
                    </p>
                    <h2>{{"13. Politique de remboursement" | translate}}</h2>
                    <p>
                        {{"Les paiements sont généralement non remboursables, sauf en cas d'erreur de notre part ou si un remboursement est prévu par la loi applicable. Les demandes de remboursement doivent être soumises via le formulaire de contact et seront examinées au cas par cas." | translate}}
                    </p>
                    <h2>{{"14. Propriété intellectuelle" | translate}}</h2>
                    <p>
                        {{"Tout contenu disponible sur BeFret, y compris les textes, graphiques, logos et logiciels, est protégé par des droits d'auteur et ne peut être utilisé sans autorisation préalable." | translate}}
                    </p>
                    <h2>{{"15. Responsabilité" | translate}}</h2>
                    <p>
                        {{"Nous nous efforçons de maintenir BeFret sécurisé et fonctionnel, mais nous ne pouvons garantir que l’application sera toujours disponible sans interruption ni erreur. BeFret décline toute responsabilité pour les dommages directs ou indirects résultant de l’utilisation de la plateforme." | translate}}
                    </p>
                    <h2>{{"16. Modification des conditions" | translate}}</h2>
                    <p>
                        {{"Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les modifications seront effectives dès leur publication sur cette page. L'utilisation continue de BeFret après modification signifie que vous acceptez les nouvelles conditions." | translate}}
                    </p>
                    <h2>{{"17. Loi applicable" | translate}}</h2>
                    <p>
                        {{"Ces conditions d'utilisation sont régies par les lois de la Belgique, et tout litige sera soumis à la compétence exclusive des tribunaux belges." | translate}}
                    </p>
                    <h2>{{"18. Contact" | translate}}</h2>
                    <p>
                        {{"Pour toute question concernant ces conditions d'utilisation, vous pouvez nous contacter à info&#64;befret.be ou via notre formulaire de contact." | translate}}
                    </p>
                    <h2>{{"19. Dangerosité du colis" | translate}}</h2>
                    <p>
                        {{"Tout colis contenant des matières dangereuses, illégales ou interdites sera immédiatement signalé aux autorités compétentes. BeFret coopérera pleinement avec les autorités et divulguera, sans aucune hésitation, l'identité complète de l'expéditeur concerné. Nous nous réservons également le droit de prendre des mesures supplémentaires, y compris la suspension du compte de l'utilisateur." | translate}}
                    </p>
                    </div>
                </div></div></section>
        <!-- <section>
            
              
        </section> -->
    </div></main>