import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css'
})
export class ResetPasswordComponent {
  resetForm: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {
    this.resetForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
    });
}
onSubmit() {
  if (this.resetForm.valid) {
      const email = this.resetForm.get('email')?.value;
      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
          .then(() => {
              alert('Un email de réinitialisation a été envoyé.');
              this.router.navigate(['/auth']);
          })
          .catch((error) => {
              console.error(error);
              alert('Une erreur est survenue. Veuillez vérifier votre email et réessayer.');
          });
  }
}

}
