import { Component, OnInit } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router';

@Component({
  selector: 'app-parcel-send',
  templateUrl: './parcel-send.component.html',
  styleUrl: './parcel-send.component.css'
})
export class ParcelSendComponent implements OnInit{
isFound = false;
isLoading = false;
detectForm!: FormGroup;
errorMessage = '';
constructor(
  private fb: FormBuilder,
  private router: Router,
){}
ngOnInit(): void {
  this.detectForm = this.fb.group({
    trackingID: ['', Validators.required],
  })
}
onSubmit() {
  if (this.detectForm.valid){
    this.isLoading = true;
    const trackingID = (this.detectForm.get("trackingID")?.value).toUpperCase();
    console.log("Tracking ID is ", trackingID);
    this.detectForm.get('trackingID')?.setValue("");
    this.router.navigate(['/status'], {queryParams: {trackingID}});
  } else {
    this.errorMessage = "Veuillez entrer un trackingID correct"
  }
}
  formats = [BarcodeFormat.QR_CODE];
onScanSuccess(parcelId: string) {
  console.log("Scanned parcel : ", parcelId);
  this.router.navigate(['status'], {queryParams: {parcelId}});
// throw new Error('Method not implemented.');
}

}
