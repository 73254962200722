import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router';
@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrl: './tracking.component.css'
})
export class TrackingComponent implements OnInit{
  trackingForm! : FormGroup;
  isFound = false;

  constructor(
    private fb: FormBuilder,
    private router: Router
  ){

  }
  ngOnInit(): void {
    this.isFound= true;
    this.trackingForm = this.fb.group({
      trackingID: ['', Validators.required],
      
    });
  }

  onSubmit(): void {
    if (this.trackingForm.valid){
      //Handle the form submission and get parcel by ID
      const trackingID = (this.trackingForm.get("trackingID")?.value).toUpperCase();
      console.log("Tracking ID is ", trackingID);
      this.trackingForm.get('trackingID')?.setValue("");
      this.router.navigate(['/details'], { queryParams: { trackingID } });
    }
  }

}
