<!-- <p>user-profile works!</p> -->
<main>
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Mon compte" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block" data-snippet="s_text_block" data-name="Texte">
            <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">

                <div class="s_allow_columns container">
                    <div class="row">
                        <div class="col-lg-12 pt16 mt-4 mt-lg-0 o_colored_level">
                            <div>
                                <label class="s_website_form_label" style="width: 200px" for="address">
                                    <span class="s_website_form_label_content">{{"Nom complet" | translate}}</span>
                                </label>
                                <div name="name" class="o_field_widget o_required_modifier o_field_field_partner_autocomplete text-break ">
                                    <input class="form-control s_website_form_input" formControlName="fullname" placeholder="{{'Nom Complet' | translate}}"
                                    [defaultValue]="userProfile?.fullname" [value]="userProfile?.fullname">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pt16 mt-4 mt-lg-0 o_colored_level">
                            <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required" 
                            data-type="text">
                                <label class="s_website_form_label" style="width: 200px" for="address">
                                    <span class="s_website_form_label_content">{{"Adresse" | translate}}</span>
                                </label>
                                <textarea class="form-control s_website_form_input" formControlName="address"  
                                placeholder="" id="address" rows="3" [defaultValue]="userProfile?.address" [value]="userProfile?.address || ''"></textarea>
                            </div>{{'' | translate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pt16 mt-4 mt-lg-0 o_colored_level">
                            <div>
                                <!-- <label class="s_website_form_label" style="width: 200px" for="address">
                                    <span class="s_website_form_label_content">Nom complet</span>
                                </label> -->
                                <div name="name" class="o_field_widget o_required_modifier o_field_field_partner_autocomplete text-break ">
                                    <input class="form-control s_website_form_input" formControlName="email" placeholder="{{'Email' | translate}}" type="email"
                                    [defaultValue]="email">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div style="display: flex; flex-direction:row;">
                            <div class="s_website_form_field mb-3 col-4 s_website_form_custom s_website_form_required">
                                <label class="s_website_form_label" style="width: 200px" for="phonePrefix1">
                                    <span class="s_website_form_label_content">{{"Pays" | translate}}</span>
                                </label>
                                <select id="phonePrefix1" formControlName="country" class="form-select s_website_form_input" [value]="userProfile?.country">
                                    <option *ngFor="let prefix of phonePrefixes" [value]="prefix.label">
                                        {{ prefix.label }}
                                    </option>
                                </select>
                                <!-- <select id="phonePrefix1" formControlName="phonePrefix1" class="form-select s_website_form_input">
                                    <option *ngFor="let prefix of phonePrefixes" [value]="prefix.value">
                                        {{ prefix.label }}
                                    </option>
                                </select> -->
                            </div>
                            <!-- {{profileForm.get('country')?.value}} -->
                            <div class="mb-3 col-8" style="margin: 0px 0px 0px 5px;">
                                <label class="s_website_form_label" style="width: 200px" for="phonePrefix1">
                                    <span class="s_website_form_label_content">{{"Numéro de téléphone" | translate}}</span>
                                </label>
                                <input class="form-control s_website_form_input" type="tel" placeholder="{{'Numéro de téléphone' | translate}}"
                                formControlName="phoneNumber" id="phone1" maxlength="15" pattern="\d{1,15}">
                            </div>
                        </div>
                        <!--  -->
                    </div>
                    <div class="mb-0 py-2 col-12 s_website_form_submit pb96" data-name="Submit Button">
                        <div class="s_website_form_label col-8">
                            <!-- <button class="btn btn-secondary" style="margin: 0px 10px 0px 0px;" [routerLink]="['/home']">Annuler</button> -->
                             <span class="text-info" *ngIf="isSuccess">{{"Vos informations personnelles ont été correctement mise à jour!" | translate}}</span>
                            <button type="submit" class="btn btn-primary" style="display: flex; flex-direction: row;">
                                <span class="spinner-container" *ngIf="isLoading">
                                    <mat-spinner [diameter]="20" strokeWidth="3" color="accent"></mat-spinner>
                                </span>
                                <span *ngIf="!isLoading">{{"Enregister" | translate}}</span>
                                <span *ngIf="isLoading">{{"Enregistrement..." | translate}}</span>
                                <!-- <span></span> -->
                                
                            </button>
                            <!-- <button></button> -->
                        </div>
                    </div>
                    
                </div>


            </form>
            
            
        </section>
        
    </div>
</main>