<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Résolution des Litiges" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="privacy-policy col-lg-6">
                        <!-- Long Privacy Policy Content Here -->
                        <!-- <h2>1. Introduction</h2> -->
                        <p>
                            {{"Chez" | translate}}<strong> {{"BEFRET SRL" | translate}},</strong> {{"nous accordons une importance primordiale à la satisfaction de nos clients et faisons tout notre possible pour offrir des services de qualité. Cependant, si un litige venait à surgir concernant l’utilisation de nos services ou toute autre question liée à votre expérience sur" | translate}} <strong>befret.be</strong>, {{"nous mettons en place des procédures claires pour résoudre ce différend de manière équitable et rapide." | translate}}
                        </p>
                        <h2>{{"1. Étapes de résolution amiable" | translate}}</h2>
                        <p>
                            {{"Nous vous encourageons à nous contacter directement en cas de problème afin de trouver une solution rapide et à l’amiable. La plupart des litiges peuvent être résolus de cette manière, sans avoir recours à des procédures juridiques." | translate}}
                        </p>
                        <h3>{{"Contactez notre service client :" | translate}}</h3>
                        <!-- <p>
                            Toute personne peut reproduire une partie du contenu de ces pages Web sous réserve des conditions suivantes :
                        </p> -->
                        <ul>
                            <li><strong>{{"Email : " | translate}}</strong>info&#64;befret.be</li>
                            <li><strong>{{"Téléphone : " | translate}}</strong></li>
                            <li><strong>{{"Adresse : " | translate}}</strong>{{"35, Rue Ilya PRIGOGINE, 1480 Saintes (Tubize), Belgique" | translate}}</li>
                        </ul>
                        <!-- <h2>3. BEFRET, une marque déposée</h2> -->
                        <p>
                            {{"Merci de nous fournir autant d’informations que possible concernant le litige (numéro de commande, description du problème, pièces justificatives, etc.). Nous nous engageons à examiner votre demande et à vous répondre dans un délai de" | translate}}<strong> {{"7 jour ouvrable." | translate}}</strong>
                        </p>
                        <h2>{{"2. Médiation" | translate}}</h2>
                        <p>
                            {{"Si nous ne parvenons pas à trouver une solution amiable satisfaisante, vous avez la possibilité de recourir à un service de médiation indépendant. La médiation est une procédure volontaire dans laquelle un tiers neutre aide les parties à trouver un accord." | translate}}<br>
                            {{"Vous pouvez contacter le service de médiation belge suivant pour tenter de résoudre le différend de manière extrajudiciaire :" | translate}}
                        </p>
                        <ul>
                            <li><strong>{{"Service de Médiation pour le Consommateur" | translate}} </strong>{{"(SPF Economie)" | translate}}</li>
                            <li><strong>{{"Adresse : " | translate}}</strong>{{"Boulevard du Roi Albert II 8, 1000 Bruxelles, Belgique" | translate}}</li>
                            <li><strong>{{"Site web :" | translate}} </strong><a href="https://www.mediationconsommateur.be" class="link">www.mediationconsommateur.be</a></li>
                            <li><strong>{{"Email : " | translate}}</strong>contact&#64;mediationconsommateur.be</li>
                        </ul>
                        
                        <!-- <h2>7. Création de compte</h2> -->
                        <p>
                            {{"La médiation est un processus gratuit ou peu coûteux, et elle permet souvent de trouver des solutions plus rapidement qu’une procédure judiciaire." | translate}}
                        </p>

                    <h2>{{"3. Plateforme européenne de résolution des litiges en ligne (ODR)" | translate}}</h2>
                    <p>
                        {{"Si vous résidez dans un autre pays de l'Union européenne, vous pouvez également soumettre votre litige via la plateforme en ligne de résolution des litiges mise en place par la Commission européenne." | translate}}
                    </p>
                    <ul>
                        <li><strong>{{"Lien vers la plateforme ODR :" | translate}} </strong><a href="https://ec.europa.eu/consumers/odr" class="link">https://ec.europa.eu/consumers/odr</a></li>
                    </ul>
                    <!-- <h2>9. Expédition et livraison</h2> -->
                    <p>
                        {{"Cette plateforme est spécialement conçue pour résoudre les litiges transfrontaliers en ligne et vise à faciliter la communication entre consommateurs et entreprises." | translate}}
                    </p>
                    <h2>{{"4. Recours judiciaires" | translate}}</h2>
                    <p>
                        {{"Si aucune solution amiable ou médiation ne permet de résoudre le différend, vous avez toujours la possibilité d’engager une procédure judiciaire." | translate}}
                    </p>
                    <ul>
                        <li><strong>{{"Compétence juridictionnelle :" | translate}} </strong>{{"Tout litige lié à l’utilisation de" | translate}} <strong>befret.be</strong> {{"sera soumis à la compétence exclusive des tribunaux belges." | translate}}</li>
                        <li><strong>{{"Droit applicable :" | translate}} </strong>{{"Les présentes conditions d’utilisation et tout différend relatif à l’utilisation du site seront régis et interprétés selon les lois de la Belgique." | translate}}</li>
                    </ul>
                    <!-- En cas de retard, notre service contactera le client pour l'informer de la situation. -->
                    <h2>{{"5. Frais de litiges" | translate}}</h2>
                    <p>
                        {{"BEFRET SRL s’efforce de réduire au minimum les frais engagés dans le cadre d’un litige. Toutefois, en cas de procédure judiciaire, les frais peuvent être répartis entre les parties conformément à la décision du tribunal compétent. Les frais de médiation sont généralement pris en charge par les parties de manière égale, sauf accord contraire." | translate}}
                    </p>
                    <h2>{{"6. Réclamations non justifiées" | translate}}</h2>
                    <p>
                        {{"Si une réclamation s'avère être infondée ou mal intentionnée," | translate}} <strong>{{"BEFRET SRL" | translate}}</strong> {{"se réserve le droit de prendre des mesures légales pour obtenir réparation pour tout dommage subi, y compris les frais encourus pour la gestion de la réclamation." | translate}}
                    </p>
                    <h2>{{"7. Délai de réclamation" | translate}}</h2>
                    <p>
                        {{"Les clients ont un délai de" | translate}} <strong>{{"30 jours" | translate}}</strong> {{"à compter de la date de réception du colis ou du service pour déposer une réclamation ou un litige concernant un envoi. Au-delà de ce délai," | translate}} <strong>{{"BEFRET SRL" | translate}}</strong> {{"se réserve le droit de refuser d’examiner la réclamation, sauf cas de force majeure." | translate}}
                    </p>
                    
                    </div>
                </div></div></section>
        <!-- <section>
            
              
        </section> -->
    </div></main>