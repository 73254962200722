<!-- <p>parcel-send works!</p> -->
<main>
<div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
  <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
    <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
    <div class="o_we_bg_filter bg-black-0"></div>
    <div class="container">
        <h1><font style="color: rgb(0, 0, 0);">Identifier le colis</font></h1>
    </div>
</section>
  <div class="scan-container">
    <h4>Scannez ici</h4>
    <zxing-scanner 
      (scanSuccess)="onScanSuccess($event)" 
      [formats]="formats" 
      [autostart]="true">
  </zxing-scanner>
  
  <h4>Ou Tapez le code ci-bas</h4>
  <form class="o_searchbar_form o_wait_lazy_js s_searchbar_input" (ngSubmit)="onSubmit()" [formGroup]="detectForm">
    <input class="form-control s_website_form_input" placeholder="Tracking Code" formControlName="trackingID">
    <div><h6 style="color: red;">{{this.errorMessage}}</h6></div>
  <button class="btn btn-primary md-8" type="submit" [disabled]="isLoading">
    <span *ngIf="isLoading" class="spinner-container">
      <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
    </span>
    Vérifier</button>
</form>
  </div>
</div>
</main>

