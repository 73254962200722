import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-parcel-list',
  templateUrl: './parcel-list.component.html',
  styleUrl: './parcel-list.component.css'
})
export class ParcelListComponent implements OnInit{
  parcels: any[] = [];
  userId?: string;
  country: string | null = null;
  delivered: string | null = null;
  paquet: string | null = null;
  mail: string | null = null;
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ){}
ngOnInit(): void {
  this.country = this.route.snapshot.queryParamMap.get('country');
  this.delivered = this.route.snapshot.queryParamMap.get('delivered');
  this.paquet = this.route.snapshot.queryParamMap.get('paquet');
  this.mail = this.route.snapshot.queryParamMap.get('mail');
  
  this.authService.getUser().subscribe(user => {
    if (user) {
      this.userId = user.uid;
      if (this.country){
        this.fetchCountryParcel(this.country);
      } else if (this.delivered){
        this.fetchDeliveredParcel(this.delivered);
      } else if (this.paquet){
        this.fetchPaquetParcel(this.paquet);
      }else if (this.mail){
        this.fetchMailParcel(this.mail);
      }
      else {
        this.fetchParcels();
      }
      console.log("User: ", this.userId);
      console.log("Stored parcels: ", this.parcels);
    }
  });
}

fetchCountryParcel(countryID: string){
  if (countryID == 'Autres'){
    this.fetchParcels();
  } else {
    this.firestore.collection('parcel', ref => ref.where('status', '!=', 'draft').where('phonePrefix2', '==', countryID))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
  }
  
}
fetchMailParcel(mail: string){
  // this.firestore.collection('parcel', ref => ref.where('status', '!=', 'draft' && 'type', '==', mail))
  // .valueChanges()
  // .subscribe((data: any[]) => {
  //   this.parcels = data;
  // });
  this.firestore.collection('parcel', ref => 
    // ref.where('status', '!=', 'draft').where('type', '==', mail))
    ref.where('type', '==', mail).where('status', '!=', 'draft'))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
}
fetchPaquetParcel(paquet: string){
  // this.firestore.collection('parcel', ref => ref.where('status', '!=', 'draft').where('type', '==', paquet))
  this.firestore.collection('parcel', ref => ref.where('type', '==', paquet).where('status', '!=', 'draft'))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
}
fetchDeliveredParcel(delivered: string){
  this.firestore.collection('parcel', ref => ref.where('status', '==', delivered))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
}

fetchParcels() {
  // Fetch parcels where the userId matches the current user's ID
  this.firestore.collection('parcel', ref => ref.where('status', '!=', "draft"))
  // this.firestore.collection('parcel', ref => ref.where('uid', '==', this.userId))
  // this.firestore.collection('parcel', ref => ref.where('uid', '==', this.userId))
    .valueChanges()
    .subscribe((data: any[]) => {
      this.parcels = data;
    });

    // this.firestore.collection('parcel', ref => ref
    //   // .where('uid', '==', this.userId)
    //   .orderBy('command_date', 'asc')) // Order by command_date descending
    // // .valueChanges({ idField: 'id' })
    // .valueChanges()
    // .subscribe((data: any[]) => {
    //   this.parcels = data;
    //   // this.loading = false;
    // });
    
}

viewDetails(trackingID: any) {
  this.router.navigate(['/status'], { queryParams: { trackingID } });
}

}
