<main>
    <div class="oe_website_login_container">
        <form [formGroup]="resetForm" (ngSubmit)="onSubmit()" class="oe_reset_password_form" role="form">
            <div class="mb-3 field-login">
                <label for="email" class="col-form-label">Votre email</label>
                <input type="email" formControlName="email" id="email" class="form-control" required autofocus />
            </div>
            <div class="clearfix oe_login_buttons d-grid mt-3">
                <button type="submit" class="btn btn-primary">Réinitialiser le mot de passe</button>
                <div class="d-flex justify-content-between align-items-center small mt-2">
                    <a routerLink="/auth">Retour à la page de connexion</a>
                </div>
                <div class="o_login_auth"></div>
            </div>
        </form>
    </div>
    <div id="o_shared_blocks" class="oe_unremovable"></div>
</main>
