<!-- <p>create-employee works!</p> -->
<main>
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">Création d'un employé</font></h1>
            </div>
        </section>
        <section class="s_text_block" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="col-lg-8 mt-4 mt-lg-0 o_colored_level">
                        <!-- old Contact form -->
                        <section class="s_website_form o_colored_level" data-vcss="001" data-snippet="s_website_form" data-name="Formulaire" id="Formulaire" data-anchor="true">
                            <div class="o_container_small">
                                <form  data-mark="*" data-pre-fill="true" [formGroup]="employeeForm" (ngSubmit)="onSubmit()"
                                class="o_mark_optional">
                                <div class="s_website_form_rows row s_col_no_bgcolor">
                                    <div data-name="Field"
                                    class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required"
                                    data-type="char">
                                        <label class="s_website_form_label" style="width: 200px" for="fullname">
                                            <span class="s_website_form_label_content">Coordonnées de l'employé*</span>
                                        </label>
                                        <input class="form-control s_website_form_input" type="text" 
                                        placeholder="Nom complet" id="fullname" formControlName="fullname">
                                    </div>
                                    <div style="display: flex; flex-direction:row;">
                                        <div class="s_website_form_field mb-3 col-4 s_website_form_custom s_website_form_required">
                                            <select id="phonePrefix" formControlName="phonePrefix" class="form-select s_website_form_input"
                                            [ngModel]="userProfile?.country">
                                                <option *ngFor="let prefix of phonePrefixes"  [value]="prefix.label">
                                                    {{ prefix.label }} 
                                                </option>
                                            </select>
                                        </div>
                                        <div class="mb-3 col-8" style="margin: 0px 0px 0px 5px;">
                                            <input class="form-control s_website_form_input" type="tel" placeholder="Téléphone"
                                            formControlName="phoneNumber" id="phoneNumber" maxlength="15">
                                        </div>
                                    </div>
                                    <div data-name="Field"
                                    class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required"
                                    data-type="char">
                                        <!-- <label class="s_website_form_label" style="width: 200px" for="fullname">
                                            <span class="s_website_form_label_content">Coordonnées de l'employé*</span>
                                        </label> -->
                                        <input class="form-control s_website_form_input" type="email" 
                                        placeholder="Email" id="email" formControlName="email">
                                    </div>
                                    <div data-name="Field"
                                    class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required"
                                    data-type="char">
                                        <!-- <label class="s_website_form_label" style="width: 200px" for="fullname">
                                            <span class="s_website_form_label_content">Coordonnées de l'employé*</span>
                                        </label> -->
                                        <input class="form-control s_website_form_input" type="password" 
                                        placeholder="Mot de passe" id="password" formControlName="password">
                                    </div>
                                    <div data-name="Field"
                                    class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required"
                                    data-type="char">
                                        <!-- <label class="s_website_form_label" style="width: 200px" for="fullname">
                                            <span class="s_website_form_label_content">Coordonnées de l'employé*</span>
                                        </label> -->
                                        <input class="form-control s_website_form_input" type="password" 
                                        placeholder="Confirmez le mot de passe" id="password2" formControlName="password2">
                                    </div>
                                    <div class="s_hr pb16 pt16" data-name="Séparateur">
                                        <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid; border-color: var(--600);">
                                    </div>
                                    
                                    <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom" data-type="many2one">
                                        <label class="s_website_form_label" style="width: 200px" for="type">
                                            <span class="s_website_form_label_content">Niveau d'abilitation *</span>
                                        </label>
                                        <select class="form-select s_website_form_input" id="type" formControlName="ability">
                                            <option *ngFor="let type of abilities | keyvalue" [value]="type.value">{{type.value}}</option>
                                        </select>
                                    </div>
                                    
                                    <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom" data-type="many2one">
                                        <label class="s_website_form_label" style="width: 200px" for="type">
                                            <span class="s_website_form_label_content">Affectation</span>
                                        </label>
                                        <select class="form-select s_website_form_input" id="type" formControlName="city">
                                            <option *ngFor="let city of cities | keyvalue" [value]="city.value">{{city.value}}</option>
                                        </select>
                                    </div>
                                    <div><h6 style="color: red;">{{this.errorPassword}}</h6></div>

                                            <div class="mb-0 py-2 col-12 s_website_form_submit pb96" data-name="Submit Button">
                                                <div class="s_website_form_label col-6">
                                                    <button class="btn btn-secondary" style="margin: 0px 10px 0px 0px;" [routerLink]="['/home']">Annuler</button>
                                                    <button type="submit" class="btn btn-primary" [disabled]="isLoading">
                                                        <span *ngIf="isLoading" class="spinner-container">
                                                            <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                                                        </span>
                                                        <span *ngIf="!isLoading">Créer l'employé</span>
                                                        <span *ngIf="isLoading">Chargement...</span>
                                                        <!-- <span></span> -->
                                                        
                                                    </button>
                                                    <!-- <button></button> -->
                                                </div>
                                                <span id="s_website_form_result"></span>

                                            </div>
                                </div>
                            </form>

                            </div></section></div></div></div></section></div></main>