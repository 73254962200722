<footer id="bottom" data-anchor="true" data-name="Footer" class="o_footer o_colored_level o_cc  o_footer_slideout" style="background-color: #1f981f18;">
    <div id="footer" class="oe_structure oe_structure_solo shadow" style="box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 16px 0px !important;">

<section class="s_text_block pt56" data-snippet="s_text_block" data-name="Text">
<div class="container allow_columns">
    <div class="s_social_media o_not_editable text-end no_icon_color" data-snippet="s_social_media" data-name="Réseaux sociaux">
<h4 class="s_social_media_title o_default_snippet_text"><span class="text-black">{{"Suivez-nous" | translate}}</span></h4>
<a target="_blank" href="https://www.facebook.com/share/15VzVH32wn/?mibextid=LQQJ4d" class="s_social_media_facebook" aria-label="Facebook"><i class="fa o_editable_media fa-facebook rounded-empty-circle shadow-sm"></i></a>
<!-- <a target="_blank" routerLink="/website/social/twitter" class="s_social_media_twitter" aria-label="Twitter"><i class="fa o_editable_media fa-twitter rounded-empty-circle shadow-sm"></i></a> -->
<a target="_blank" href="https://www.linkedin.com/company/befret-be/" class="s_social_media_linkedin" aria-label="LinkedIn"><i class="fa o_editable_media fa-linkedin rounded-empty-circle shadow-sm"></i></a>
<a target="_blank" href="https://www.instagram.com/befret_be/profilecard/?igsh=MXN2ZTk5dnFxYm8wYQ==" class="s_social_media_instagram" aria-label="Instagram">
    <i class="fa o_editable_media fa-instagram rounded-empty-circle shadow-sm"></i>
</a>
<a target="_blank" href="https://www.tiktok.com/@befret.be?_t=8s2uF9mNdyA&_r=1" class="s_social_media_instagram" aria-label="Tiktok">
    <i class="fa o_editable_media rounded-empty-circle shadow-sm">
        <span class="material-icons">tiktok</span>
    </i>
</a>
</div><div class="s_hr pb16 pt16" data-name="Séparateur">
        <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid; border-color: var(--600);">
    </div>
</div>
</section>
<section class="s_text_block" data-snippet="s_text_block" data-name="Text">
<div class="container">
    <div class="row o_grid_mode" data-row-count="4">
        <div style="grid-area: 1 / 1 / 3 / 3; z-index: 1;">
            <a routerLink="/" title="BeFret">
                <!-- <img src="/BeFret/src/assets/befret_logo.png" alt="Logo BeFret" class="img-fluid img o_we_custom_image o_animate o_anim_slide_in o_anim_from_left o_animate_both_scroll" data-mimetype="image/webp" data-original-id="297" data-original-src="/BeFret/src/assets/befret_logo.png" data-mimetype-before-conversion="image/png" data-resize-width="690" style="--wanim-intensity: 81; animation-duration: 1.5s; animation-name: o_anim_slide_in_left; visibility: visible; animation-play-state: paused;" loading="eager"> -->
                <img src="assets/befret_logo.png" alt="Logo BeFret" style="--wanim-intensity: 81; animation-duration: 1.5s; animation-name: o_anim_slide_in_left; visibility: visible;">
                <!-- <img src="/BeFret/src/assets/befret_logo.png" alt="Logo BeFret" data-resize-width="690" style="--wanim-intensity: 81; animation-duration: 1.5s; animation-name: o_anim_slide_in_left; visibility: visible; animation-play-state: paused;" loading="eager"> -->
            </a>
        </div>
        
    <div class="text-end o_colored_level o_grid_item g-height-2 g-col-lg-3 col-lg-3" style="z-index: 3; grid-area: 3 / 4 / 5 / 7;">
            <ul class="list-inline mb-0">
                <p style="text-align: right;">
                    <span class="base-fs text-black">
                        <a routerLink="/legal" data-bs-original-title="" title="">{{"Mentions légales" | translate}}</a>
                    </span>
                </p>
                <p style="text-align: right;">
                    <span class="base-fs text-black">
                        <a routerLink="/privacy" data-bs-original-title="" title="">{{"Avis de confidentialité" | translate}}</a>
                    </span>
                    <!-- <a routerLink="/" class="text-black" data-bs-original-title="" title="">
                        <span class="base-fs">Avis de confidentialité</span>
                    </a><br> -->
                </p>
            </ul>
        </div>
        <div class="text-end o_colored_level o_grid_item g-height-2 g-col-lg-3 col-lg-3" style="z-index: 3; grid-area: 3 / 7 / 5 / 10;">
            <ul class="list-inline mb-0">
                <p style="text-align: right;">
                    <span class="base-fs text-black">
                        <a routerLink="/cookies">{{"Paramètres des cookies" | translate}}</a>
                    </span>
                </p>
                <p style="text-align: right;">
                    <!-- <a routerLink="/" data-bs-original-title="" class="text-black" title="">
                        <span class="base-fs"></span>
                    </a><br> -->
                    <span class="base-fs text-black">
                        <a routerLink="/litiges">{{"Résolution des litiges" | translate}}&nbsp;</a>
                    </span>
                </p>
            </ul>
        </div>
        <div class="text-end o_colored_level o_grid_item g-height-2 g-col-lg-4 col-lg-4" style="z-index: 3; grid-area: 3 / 1 / 5 / 5;">
            <ul class="list-inline mb-0">
                <p style="text-align: right;">
                    <span class="base-fs text-black">{{"Veuillez lire les" | translate}}&nbsp;</span>
                </p>
                <p style="text-align: right;">
                    <span class="base-fs text-black">
                        <a routerLink="/conditions">{{"Conditions générales d'utilisation" | translate}}</a>
                    </span>
                    <!-- <a class="text-black" routerLink="/" data-bs-original-title="" title="">
                        <span class="base-fs">Conditions générales d'utilisation</span>
                    </a><br> -->
                </p>
            </ul>
        </div>
        <div class="text-end o_colored_level o_grid_item g-height-2 g-col-lg-3 col-lg-3" style="z-index: 3; grid-area: 3 / 10 / 5 / 13;">
            <ul class="list-inline mb-0">
                <p style="text-align: right;">
                    <span class="base-fs text-black">
                        <a routerLink="/fraud" data-bs-original-title="" title="">{{"Sensibilisation à la fraude" | translate}}</a>
                    </span>
                </p>
                <p style="text-align: right;">
                    <span class="base-fs text-black">
                        <a routerLink="/faq" data-bs-original-title="" title="">{{"Foire aux questions(FAQ)" | translate}}</a>
                    </span><br>
                </p>
                <p style="text-align: right;"><br></p>
            </ul>
        </div>
        <div class="text-end o_colored_level o_grid_item g-height-2 g-col-lg-3 col-lg-3" style="z-index: 3; grid-area: 1 / 10 / 3 / 13;">
            <ul class="list-inline mb-0">
                <p style="text-align: right;"><strong style="color: rgb(3, 37, 84);">{{"Envoyez vos colis depuis chez vous" | translate}} &nbsp;</strong></p>
                <!-- <p style="text-align: right;"><strong style="color: rgb(3, 37, 84);">à partir de chez vous</strong></p> -->
            </ul>
        </div>
        <div class="text-end o_colored_level o_grid_item g-height-2 g-col-lg-3 col-lg-3" style="z-index: 3; grid-area: 1 / 7 / 3 / 10;">
            <ul class="list-inline mb-0">
                <p style="text-align: right;">
                    <span class="o_force_ltr">
                        <a routerLink="tel://+32 465 26 58 48" data-bs-original-title="" title="">+32 465 26 58 48 <span class="fa fa-phone"></span>
                        </a>
                    </span>
                </p>
                <p style="text-align: right;" class="mb-2">
                    <a routerLink="mailto:infos@befret.be" data-bs-original-title="" title=""><span class="base-fs">
                        info&#64;befret.be&nbsp;
                    <span class="fa fa-envelope-o"></span></span></a></p><h5 style="text-align: right;"><span class="o_force_ltr"></span><span class="h5-fs">​</span><br></h5></ul></div></div>
</div>
</section>
</div>

<div id="o_footer_scrolltop_wrapper" class="container h-0 d-flex align-items-center justify-content-center">
<a href="#top" id="o_footer_scrolltop" role="button" title="" class="d-flex align-items-center justify-content-center btn btn-custom rounded-circle" data-bs-original-title="Faire défiler vers le haut" style="color: rgb(161, 12, 12); border-width: 1px; border-style: solid;">
<!-- <span><mat-icon>arrow_upward</mat-icon></span> -->
<!-- <span class="oi oi-chevron-up"></span> -->
<!-- <mat-icon>up</mat-icon> -->
<span class="oi fa-1x oi-chevron-up"></span>


</a>
</div>
    <div class="o_footer_copyright o_colored_level o_cc" data-name="Copyright">
        <div class="container py-3">
            <div class="row">
                <div class="col-sm text-center text-sm-start text-muted">
                    <span class="o_footer_copyright_name me-2">Copyright® BeFret SRL</span>

                    <div class="js_language_selector d-print-none">
                        <div role="menu" class="list-inline">
                          <a
                            class="js_change_lang list-inline-item"
                            role="menuitem"
                            routerLink="/nl"
                            data-url_code="nl"
                            title="Nederlands (BE)"
                            [class.disabled]="currentLanguage === 'nl'"
                            (click)="setLanguage('nl')"
                          >
                            <span>Nederlands (NL)</span>
                          </a>
                          <span class="list-inline-item">|</span>
                          <a
                            class="js_change_lang list-inline-item"
                            role="menuitem"
                            routerLink="/en"
                            data-url_code="en"
                            title="English (UK)"
                            [class.disabled]="currentLanguage === 'en'"
                            (click)="setLanguage('en')"
                          >
                            <span>English (UK)</span>
                          </a>
                          <span class="list-inline-item">|</span>
                          <a
                            class="js_change_lang list-inline-item"
                            role="menuitem"
                            routerLink="/fr"
                            data-url_code="fr"
                            title="Français"
                            [class.disabled]="currentLanguage === 'fr'"
                            (click)="setLanguage('fr')"
                          >
                            <span>Français (FR)</span>
                          </a>
                        </div>
                      </div>
                      

<!-- <div class="js_language_selector  d-print-none">
    <div role="menu" class=" list-inline">
        <a class="js_change_lang  list-inline-item" role="menuitem" (click)="changeLanguage('nl')" data-url_code="nl" title=" Nederlands (nl)">
            <span class=""> Nederlands (BE)</span>
        </a>
        <span class="list-inline-item">|</span>
        <a class="js_change_lang  list-inline-item" role="menuitem" (click)="changeLanguage('en')" data-url_code="en" title="English (UK)">
            <span class="">English (UK)</span>
        </a>
        <span class="list-inline-item">|</span>
        <a class="js_change_lang active list-inline-item" role="menuitem" (click)="changeLanguage('fr')" data-url_code="fr" title=" Français">
            <span class=""> Français</span>
        </a>
    </div>
</div> -->

</div>
                <div class="col-sm text-center text-sm-end o_not_editable">
<div class="o_brand_promotion">


</div>
                </div>
            </div>
        </div>
    </div>
</footer>