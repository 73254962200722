<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Paramètres de Cookies" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="privacy-policy col-lg-6">
                        <!-- Long Privacy Policy Content Here -->
                        <h2>{{"Introduction" | translate}}</h2>
                        <p>
                            {{"Sur notre site web befret.be, nous utilisons des cookies et des technologies similaires pour améliorer votre expérience, personnaliser le contenu, analyser le trafic, et vous offrir des services adaptés à vos besoins. Vous pouvez gérer vos préférences en matière de cookies à tout moment en ajustant les paramètres ci-dessous." | translate}}
                        </p>
                        <h2>{{"Qu'est-ce qu'un cookie ?" | translate}}</h2>
                        <p>
                            {{"Un cookie est un petit fichier texte déposé sur votre appareil (ordinateur, smartphone, tablette) lors de la visite d'un site web. Il permet à ce site de mémoriser vos actions et préférences (comme la langue, la taille de la police, etc.) sur une période donnée, afin que vous n'ayez pas à les réindiquer à chaque fois que vous consultez ce site ou naviguez d'une page à une autre." | translate}}
                        </p>
                        <h3>{{"Types de cookies utilisés sur Befret :" | translate}}</h3>
                        <p>
                            {{"Nous utilisons différents types de cookies pour assurer le bon fonctionnement de notre site et améliorer nos services :" | translate}}
                        </p>
                        <h4>{{"1. Cookies strictement nécessaires" | translate}}</h4>
                        <p>
                            {{"Ces cookies sont essentiels pour que vous puissiez naviguer sur notre site et utiliser ses fonctionnalités. Sans ces cookies, certains services que vous avez demandés ne peuvent être fournis, comme l'accès à des zones sécurisées du site." | translate}}</p>
                        <p><strong>{{"Exemple : " | translate}}</strong></p>
                        <ul>
                            <li>{{"Authentification de compte" | translate}}</li>
                            <li>{{"Mémorisation des articles ajoutés à votre panier" | translate}}</li>
                            <!-- <li></li> -->
                        </ul>
                        <p><strong>{{"Durée de conservation : " | translate}}</strong>{{"Ces cookies sont supprimés à la fin de votre session de navigation." | translate}}</p>

                        <h4>{{"2. Cookies de performance" | translate}}</h4>
                        <p>
                            {{"Ces cookies nous permettent de comprendre comment les utilisateurs interagissent avec notre site, afin d'améliorer son fonctionnement et de vous offrir une meilleure expérience utilisateur. Ces cookies collectent des informations anonymes sur les pages visitées, les liens cliqués, et le temps passé sur le site." | translate}}                        <p><strong>{{"Exemple : " | translate}}</strong></p>
                        <ul>
                            <li>{{"Google Analytics" | translate}}</li>
                            <li>{{"Outils d’analyse de performance" | translate}}e</li>
                            <!-- <li></li> -->
                        </ul>
                        <p><strong>{{"Durée de conservation : " | translate}}</strong>{{"Ils peuvent être conservés pendant une durée allant de quelques heures à un maximum de 13 mois." | translate}}</p>

                        <h4>{{"3. Cookies de fonctionnalité" | translate}}</h4>
                        <p>
                            {{"Ces cookies permettent à notre site de mémoriser les choix que vous faites (comme votre langue préférée ou la région où vous vous trouvez) et de vous fournir des fonctionnalités améliorées et personnalisées." | translate}}                        <ul>
                            <li>{{"Mémorisation de vos préférences de langue" | translate}}</li>
                            <li>{{"Paramètres de personnalisation" | translate}}</li>
                            <!-- <li></li> -->
                        </ul>
                        <p><strong>{{"Durée de conservation : " | translate}}</strong>{{"Jusqu'à 13 mois." | translate}}</p>
                        <!-- <p><strong>Durée de conservation : </strong>Ces cookies sont supprimés à la fin de votre session de navigation.</p> -->

                        <h4>{{"4. Cookies de ciblage ou publicitaires" | translate}}</h4>
                        <p>
                            {{"Ces cookies sont utilisés pour vous proposer des publicités plus pertinentes en fonction de vos intérêts. Ils peuvent également limiter le nombre de fois que vous voyez une publicité et mesurer l'efficacité des campagnes publicitaires." | translate}}                        <p><strong>{{"Exemple : " | translate}}</strong></p>
                        <ul>
                            <li>{{"Cookies de suivi pour afficher des publicités adaptées à vos préférences sur d'autres sites" | translate}}</li>
                            <!-- <li>Mémorisation des articles ajoutés à votre panier</li> -->
                            <!-- <li></li> -->
                        </ul>
                        <p><strong>{{"Durée de conservation : " | translate}}</strong>{{"Jusqu'à 13 mois." | translate}}</p>

                        <h4>{{"5. Cookies de réseaux sociaux" | translate}}</h4>
                        <p>
                            {{"Ces cookies vous permettent de partager des contenus sur des plateformes de réseaux sociaux comme Facebook, Twitter, ou LinkedIn. Ils peuvent également être utilisés par ces réseaux pour suivre votre navigation sur d'autres sites et vous proposer des publicités ciblées." | translate}}                        <ul>
                            <li>{{"Boutons de partage" | translate}}</li>
                            <li>{{"Widgets sociaux" | translate}}</li>
                            <!-- <li></li> -->
                        </ul>
                        <p><strong>{{"Durée de conservation : " | translate}}</strong>{{"Varie selon le réseau social." | translate}}</p>

                        <h2>{{"Gestion des cookies" | translate}}</h2>
                        <p>
                            {{"Vous avez le contrôle total sur l'utilisation des cookies sur notre site. Vous pouvez ajuster vos préférences en matière de cookies via les paramètres de votre navigateur ou directement ici en utilisant notre outil de gestion des cookies." | translate}}                        </p>
                        <h4>{{"1. Via les paramètres de votre navigateur" | translate}}</h4>
                        <p>
                            {{"La plupart des navigateurs vous permettent de gérer vos préférences concernant les cookies. Vous pouvez choisir d'accepter ou de refuser les cookies, ou d'être averti lorsque des cookies sont déposés. Les réglages peuvent être effectués individuellement pour chaque navigateur que vous utilisez." | translate}}<br>
                            {{"Voici comment gérer les cookies pour certains navigateurs populaires : " | translate}}   
                        <ul>
                            <li>{{"Google Chrome : Instructions Chrom" | translate}}e</li>
                            <li>{{"Mozilla Firefox : " | translate}}<a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences" class="link">Instructions Firefox</a></li>
                            <li>{{"Microsoft Edge : " | translate}}<a href="https://support.microsoft.com/fr-fr/help/4027947/microsoft-edge-delete-cookies" class="link">Instructions Edge</a></li>
                            <li>{{"Safari : " | translate}}<a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac" class="link">Instructions Safari</a></li>
                            <!-- <li></li>
                            <li></li> -->
                        </ul>






                        <h2>{{"2. Via notre outil de gestion des cookies" | translate}}</h2>
                        <p>
                            {{"Nous vous offrons la possibilité de modifier vos préférences à tout moment en accédant à notre outil de gestion des cookies [lien vers l'outil de gestion des cookies]." | translate}} <br>
                            {{"Vous pouvez choisir de désactiver certains types de cookies, sauf les cookies strictement nécessaires, qui sont essentiels au bon fonctionnement du site." | translate}}
                        </p>
                        <h2>{{"Modification de notre politique de cookies" | translate}}</h2>
                        <p>
                            {{"Nous pouvons mettre à jour cet avis de cookies de temps en temps afin de refléter les changements dans notre utilisation des cookies ou pour se conformer à la législation en vigueur. Toute modification sera publiée sur cette page et, si nécessaire, vous en serez informé par un avis sur le site." | translate}}<br>
                            {{"Nous vous encourageons à consulter cette page régulièrement pour rester informé de la manière dont nous utilisons les cookies." | translate}}                        </p>
                        <h2>{{"Contact" | translate}}</h2>
                        <p>
                            {{"Si vous avez des questions concernant notre utilisation des cookies, n’hésitez pas à nous contacter :" | translate}}<br>
                        <strong>{{"BEFRET SRL" | translate}}</strong><br>
                        {{"Adresse : 35, Rue Ilya PRIGOGINE, 1480 Saintes (Tubize), Belgique" | translate}}<br>{{"Email : info&#64;befret.be" | translate}}<br>{{"Téléphone : " | translate}}</p>
                           
                    </div>
                </div></div></section>
        <!-- <section>
            
              
        </section> -->
    </div></main>