import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translate: TranslateService) {}

  changeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('appLanguage', language);
  }

  loadSavedLanguage() {
    const language = localStorage.getItem('appLanguage') || 'en';
    this.translate.use(language);
  }
}
