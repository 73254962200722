import { Component } from '@angular/core';

@Component({
  selector: 'app-answer-mail',
  templateUrl: './answer-mail.component.html',
  styleUrl: './answer-mail.component.css'
})
export class AnswerMailComponent {

}
