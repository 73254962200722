<main>
                    
        <div id="wrap" class="oe_structure oe_empty" >​
            ​<section class="s_title o_colored_level pb4 pt0 d-lg-block d-none o_snippet_mobile_invisible" data-vcss="001" 
            data-snippet="s_title" data-name="Titre" >
        <div  style="display: flex; justify-content: center; padding-top: 5px;">
            <p  style="text-align: center;">
        <font style="color: rgba(3, 37, 84, 0.95);"><span class="h3-fs">{{ "DEV ​Expédier vos colis en 4 étapes vers la RD Congo" | translate}}</span></font></p>
        </div>
            </section>
            ​<section class="s_title o_colored_level pb4 o_cc o_cc2 pt0 d-lg-none" data-vcss="001" 
            data-snippet="s_title" data-name="Titre" style="background-color: rgba(31, 152, 31, 0.15);">
        <div class="s_allow_columns container-fluid" style="display: flex; justify-content: center; padding-top: 20px;">
            <p class="o_default_snippet_text" style="text-align: center;">
                <font style="color: rgba(3, 37, 84, 0.95);">
                    <span class="h3-fs">{{"​Expédier vos colis en 4 étapes vers la RD Congo" | translate}}</span>
                </font>
            </p>
            
        </div>
            </section>

    <section class="s_process_steps o_colored_level pb0 s_process_steps_connector_line pt0 d-lg-none o_snippet_desktop_invisible" 
    data-vcss="001" data-snippet="s_process_steps" data-name="Étapes" data-invisible="1">
        <div>
            <div class="col-12" style="display: flex; align-items: center; justify-content: center;">
                <svg class="s_process_step_svg_defs position-absolute">
                    <defs>
                        <marker class="s_process_steps_arrow_head" markerwidth="10" markerheight="10" refx="6" refy="6" orient="auto" id="s_process_steps_arrow_head1720769821172">
                            <path d="M 2,2 L10,6 L2,10 L6,6 L2,2" vector-effect="non-scaling-size" style="fill: rgba(0, 0, 0, 0.75);"/>
                        </marker>
                    </defs>
                </svg>
                <div class="col-3 s_process_step pb24 o_colored_level pt0" style="margin: 0px 5px 0px 5px;">
                    <svg class="s_process_step_connector" viewbox="0 0 0 0" preserveaspectratio="none" style="left: calc(50% + 0px); height: 0px; width: calc(100% + 0px);">
                        <path d="M 0 0 L 0 0" vector-effect="non-scaling-stroke" style="stroke: rgba(0, 0, 0, 0.75);" marker-end=""/>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-edit mx-auto bg-o-color-5 rounded-circle"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><font style="color: rgb(3, 37, 84);"><span class="h6-fs">{{'Décrire le colis' | translate}}</span></font></p></div>
                </div>
                <div class="col-3 s_process_step pb24 o_colored_level pt0" style="margin: 0px 5px 0px 5px;">
                    <svg class="s_process_step_connector" viewbox="0 0 0 0" preserveaspectratio="none" style="left: calc(50% + 0px); height: 0px; width: calc(100% + 0px);">
                        <path d="M 0 0 L 0 0" vector-effect="non-scaling-stroke" style="stroke: rgba(0, 0, 0, 0.75);" marker-end=""/>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-unlock-alt mx-auto bg-o-color-5 rounded-circle"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><font style="color: rgb(3, 37, 84);"><span class="h6-fs">{{"Se connecter" | translate}}</span></font></p></div>
                </div>
                <div class="col-3 s_process_step pb24 o_colored_level pt0" style="margin: 0px 5px 0px 5px;">
                    <svg class="s_process_step_connector" viewbox="0 0 0 0" preserveaspectratio="none" style="left: calc(50% + 0px); height: 0px; width: calc(100% + 0px);">
                        <path d="M 0 0 L 0 0" vector-effect="non-scaling-stroke" style="stroke: rgba(0, 0, 0, 0.75);" marker-end=""/>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-euro mx-auto bg-o-color-5 rounded-circle"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><font style="color: rgb(3, 37, 84);"><span class="h6-fs">{{"Payer votre colis" | translate}} 

                        </span></font></p></div>
                </div>
                <div class="col-3 s_process_step pb24 o_colored_level pt0" style="margin: 0px 5px 0px 5px;">
                    <svg class="s_process_step_connector" viewbox="0 0 0 0" preserveaspectratio="none" style="left: calc(50% + 0px); height: 0px; width: calc(100% + 0px);">
                        <path d="M 0 0 L 0 0" vector-effect="non-scaling-stroke" style="stroke: rgba(0, 0, 0, 0.75);" marker-end=""/>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-plane mx-auto bg-o-color-5 rounded-circle"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><font style="color: rgb(3, 37, 84);"><span class="h6-fs">{{"Envoyer votre colis" | translate}}</span></font></p></div>
                </div>
                <!-- <div class="col-3 s_process_step pb24 o_colored_level pt0" style="margin: 0px 5px 0px 5px;">
                    <svg class="s_process_step_connector" viewbox="0 0 0 0" preserveaspectratio="none" style="left: calc(50% + 0px); height: 0px; width: calc(100% + 0px);">
                        <path d="M 0 0 L 0 0" vector-effect="non-scaling-stroke" style="stroke: rgba(0, 0, 0, 0.75);" marker-end=""/>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-unlock-alt mx-auto rounded-circle bg-o-color-5"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><font style="color: rgb(3, 37, 84);"><span class="h6-fs">{{"Se connecter" | translate}}</span></font></p>
                    </div>
                </div>
                <div class="col-3 s_process_step pb24 o_colored_level pt0" style="margin: 0px 5px 0px 5px;">
                    <svg class="s_process_step_connector" viewbox="0 0 0 0" preserveaspectratio="none" style="left: calc(50% + 0px); height: 0px; width: calc(100% + 0px);">
                        <path d="M 0 0 L 0 0" vector-effect="non-scaling-stroke" style="stroke: rgba(0, 0, 0, 0.75);" marker-end=""/>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-euro mx-auto bg-o-color-5 rounded-circle"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><font style="color: rgb(3, 37, 84);"><span class="h6-fs">{{"Payer votre colis" | translate}}</span></font></p></div>
                </div>
                <div class="col-3 s_process_step pb24 o_colored_level pt0" style="margin: 0px 5px 0px 5px;">
                    <svg class="s_process_step_connector" viewbox="0 0 100 20" preserveaspectratio="none">
                        <path d="M 0 10 L 100 10" vector-effect="non-scaling-stroke" style="stroke: rgba(0, 0, 0, 0.75);" marker-end=""/>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-plane mx-auto rounded-circle bg-o-color-5"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><font style="color: rgb(3, 37, 84);"><span class="h6-fs">{{"Envoyer votre colis" | translate}}</span></font></p></div>
                </div> -->
            </div>
        </div>
        
        </section>

    <section class="s_process_steps o_colored_level pb0 pt0 s_process_steps_connector_line d-lg-block d-none o_snippet_mobile_invisible" data-invisible="1" data-vcss="001" data-snippet="s_process_steps" data-name="Étapes" style="background-color: rgba(198, 233, 245, 0.05);">
        <svg class="s_process_step_svg_defs position-absolute">
            <defs>
                <marker class="s_process_steps_arrow_head" markerWidth="15" markerHeight="10" refX="6" refY="6" orient="auto" id="s_process_steps_arrow_head1720769821172">
                    <path d="M 2,2 L10,6 L2,10 L6,6 L2,2" vector-effect="non-scaling-size" style="fill: rgba(0, 0, 0, 0.75);"></path>
                </marker>
            </defs>
        </svg>
        <div class="container">
            <div class="row g-0">
                <div class="col-lg-3 s_process_step pb24 o_colored_level pt0">
                    <svg class="s_process_step_connector" viewBox="0 0 197.50001525878906 80.00000762939453" preserveAspectRatio="none" style="left: calc(50% + 40px); height: 80px; width: calc(100% - 80px);">
                        <path d="M 0 40.000003814697266 L 197.50001525878906 40.000003814697266" vector-effect="non-scaling-stroke" style="stroke: rgba(31, 152, 31, 0.518);" marker-end=""></path>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-edit mx-auto bg-o-color-5 rounded-circle fa-2x"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><span class="h4-fs"><font style="color: rgb(3, 37, 84);">{{"Décrire le colis" | translate}}</font></span></p>
                        <p class="o_default_snippet_text" class="base-fs"><span>{{"Remplir le formulaire avec les informations exactes de votre colis, et le prix de votre envoi sera automatiquement affiché." | translate}}</span></p>
                        <!-- <p class="o_default_snippet_text" class="base-fs"><span >Remplissez les formulaires avec les <font >renseignent réels de votre colis</font>. Il vous donnera le prix à payer&nbsp;</span></p> -->
                    </div>
                </div>
                <div class="col-lg-3 s_process_step o_colored_level pt0 pb0">
                    <svg class="s_process_step_connector" viewBox="0 0 197.50001525878906 80.00000762939453" preserveAspectRatio="none" style="left: calc(50% + 40px); height: 80px; width: calc(100% - 80px);">
                        <path d="M 0 40.000003814697266 L 197.50001525878906 40.000003814697266" vector-effect="non-scaling-stroke" style="stroke: rgba(31, 152, 31, 0.518);" marker-end=""></path>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-unlock-alt fa-2x mx-auto rounded-circle bg-o-color-5"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><span class="h4-fs"><font style="color: rgb(3, 37, 84);">{{"Se connecter" | translate}}</font></span></p>
                        <p class="o_default_snippet_text">
                            <p class="o_default_snippet_text" class="base-fs"><span >{{"Pour un envoi de colis personnalisé, veuillez créer votre compte ou vous connecter à votre espace client." | translate}}</span></p>
                            <!-- <p class="o_default_snippet_text"><span class="base-fs">Connectez-vous à BeFret </span><span class="base-fs"><font class="text-black">afin</font></span><span class="base-fs"><font ></font></span><span class="base-fs"><font class="text-black">d'envoyer <span class="base-fs"><font class="text-black">votre colis en </font></span><span class="base-fs"><font >votre</font></span></font></span><span class="base-fs"><font ><span class="base-fs"><font >nom&nbsp;</font></span>et de suivre&nbsp;à l'aide d'un code</font>.</span></p> -->
                    </div>
                </div>
                <div class="col-lg-3 s_process_step pb24 o_colored_level pt0">
                    <svg class="s_process_step_connector" viewBox="0 0 197.50001525878906 80.00000762939453" preserveAspectRatio="none" style="left: calc(50% + 40px); height: 80px; width: calc(100% - 80px);">
                        <path d="M 0 40.000003814697266 L 197.50001525878906 40.000003814697266" vector-effect="non-scaling-stroke" style="stroke: rgba(31, 152, 31, 0.518);" marker-end=""></path>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-euro mx-auto fa-2x bg-o-color-5 rounded-circle"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><span class="h4-fs"><font style="color: rgb(3, 37, 84);">{{"Payer votre colis" | translate}}</font></span></p>
                        <!-- <p class="o_default_snippet_text"><span class="h4-fs"><font style="color: rgb(3, 37, 84);">Payer la facture</font></span></p> -->
                        <p class="o_default_snippet_text"><span class="base-fs">{{"Veuillez effectuer votre paiement sécurisé. Vous recevrez tous les détails de votre envoi, ainsi qu'un code vous permettant de suivre votre colis" | translate}}</span></p>
                        <!-- <p class="o_default_snippet_text"><span class="base-fs">La facture contiendra <font >tous les éléments nécessaires</font>. Vous n'aurez rien à payer à la réception</span></p> -->
                    </div>
                </div>
                <div class="col-lg-3 s_process_step o_colored_level pt0 pb0">
                    <svg class="s_process_step_connector" viewBox="0 0 100 20" preserveAspectRatio="none">
                        <path d="M 0 10 L 100 10" vector-effect="non-scaling-stroke" style="stroke: rgba(31, 152, 31, 0.518);" marker-end=""></path>
                    </svg>
                    <div class="s_process_step_icon">
                        <i class="fa fa-plane mx-auto rounded-circle fa-2x bg-o-color-5"></i>
                    </div>
                    <div class="s_process_step_content">
                        <p class="o_default_snippet_text"><span class="h4-fs"><font style="color: rgb(3, 37, 84);">{{"Envoyer votre colis" | translate}}&nbsp;</font></span></p>
                        <p class="o_default_snippet_text"><span class="base-fs">{{"Veuillez expédier votre colis à Befret en suivant les étapes d'envoi affichées à l'écran et envoyées par mail" | translate}}</span></p>
                        
                        <!-- <p class="o_default_snippet_text"><span class="base-fs">Expédiez votre colis par la </span><span class="base-fs"><font >poste</font></span><span class="base-fs"> <font >à l'adresse de BeFret</font> en belgique et on s'occupe de le livrer en RDC.</span></p> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="s_call_to_action o_colored_level pb0 pt0 o_cc o_cc3" data-snippet="s_call_to_action" data-name="Appel à l&amp;#x27;action" style="background-color: rgba(198, 233, 245, 0.05);">
        <div class="container">
            <div class="row o_grid_mode" data-row-count="3">
                
                
            <div class="o_colored_level o_grid_item g-height-2 g-col-lg-12 col-lg-12" style="z-index: 2; grid-area: 2 / 1 / 4 / 13;">
                    <p style="text-align: center;">
                        <a class="btn btn-primary btn-lg" routerLink="/destination" data-bs-original-title="" title=""><strong>{{"Envoyer votre colis maintenant" | translate}}</strong></a>
                    </p>
                </div></div>
        </div>
    </section>
    <section class="s_searchbar o_colored_level pb40 pt40 o_cc o_cc2" data-snippet="s_searchbar" data-name="Rechercher" style="background-color: rgba(31, 152, 31, 0.15);">
        <div class="container">
            <div class="row">
                <div class="o_colored_level col-lg-10 offset-lg-1 pb8" >
                    <p class="o_default_snippet_text"><span class="h5-fs"><strong>{{"Suivre votre colis" | translate}}</strong></span></p>
                    <form method="get" data-snippet="s_searchbar_input" [formGroup]="homeForm" (ngSubmit)="onSubmit()" style="height: 100%; align-items: center; justify-content: center; display: flex;"
                    class="o_searchbar_form o_wait_lazy_js s_searchbar_input" action="/website/search" data-name="Suivi Colis">
                        <div role="search" class="input-group " style="display: flex;">
                            <input type="search" name="search" class="search-query form-control oe_search_box None" 
                            placeholder="{{'Code du colis' | translate }}" data-search-type="all" data-limit="5" formControlName="trackingID"
                            data-display-image="true" data-display-description="true" data-display-extra-link="true" 
                            data-display-detail="true" data-order-by="name asc" autocomplete="off" style="border-radius: 10px 0px 0px 10px;">
                            
                        </div>
                        <div >
                            <button type="submit" aria-label="Suivre" title="Rechercher" class="btn btn-primary" 
                            style="border-radius: 0px 15px 15px 0px; margin: 0px;">
                                {{"Suivre" | translate}}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>
    
        <div id="o_shared_blocks" class="oe_unremovable"></div>
                </main>