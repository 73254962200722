import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ParcelDestinationComponent } from './parcel-destination/parcel-destination.component';
import { ParcelDescriptionComponent } from './parcel-description/parcel-description.component';
import { ParcelSendComponent } from './parcel-send/parcel-send.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { PaymentComponent } from './payment/payment.component';
import { AuthComponent } from './auth/auth.component';
import { PostComponent } from './post/post.component';
import { FooterComponent } from './footer/footer.component';
import { TrackingComponent } from './tracking/tracking.component';
import { HistoryComponent } from './history/history.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ParcelDetailsComponent } from './parcel-details/parcel-details.component';
import { ParcelStatusComponent } from './parcel-status/parcel-status.component';
import { ParcelListComponent } from './parcel-list/parcel-list.component';
import { ManageEmpoyeeComponent } from './manage-empoyee/manage-empoyee.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { SuccessMessageSentComponent } from './success-message-sent/success-message-sent.component';
import { MailListComponent } from './mail-list/mail-list.component';
import { AnswerMailComponent } from './answer-mail/answer-mail.component';
import { ConditionsComponent } from './conditions/conditions.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LegalesComponent } from './legales/legales.component';
import { LitigesComponent } from './litiges/litiges.component';
import { FraudesComponent } from './fraudes/fraudes.component';
import { CookiesComponent } from './cookies/cookies.component';
import { QuestionsComponent } from './questions/questions.component';
const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'home', component: HomeComponent},
    {path: 'destination', component: ParcelDestinationComponent},
    {path: 'dashboard', component: ParcelDescriptionComponent},
    {path: 'manageEmployee', component: ManageEmpoyeeComponent},
    {path: 'createEmployee', component: CreateEmployeeComponent},
    {path: 'status', component: ParcelStatusComponent},
    {path: 'scan', component: ParcelSendComponent},
    {path: 'parcel-list', component: ParcelListComponent},
    {path: 'thank-you', component: PaymentComponent},
    {path: 'auth', component: AuthComponent},
    {path: 'post', component: PostComponent},
    {path: 'top', component: TopBarComponent},
    {path: 'footer', component: FooterComponent},
    {path: 'history', component: HistoryComponent},
    {path: 'tracking', component: TrackingComponent},
    {path: 'contactus', component: ContactusComponent},
    {path: 'reset-password', component: ResetPasswordComponent},
    {path: 'userprofile', component: UserProfileComponent},
    {path: 'details', component: ParcelDetailsComponent},
    {path: 'sucess-message', component: SuccessMessageSentComponent},
    {path: 'mail-list', component: MailListComponent},
    {path: 'answer-mail', component: AnswerMailComponent},
    {path: 'mail-list', component: MailListComponent},
    {path: 'read-mail', component: AnswerMailComponent},
    {path: 'conditions', component: ConditionsComponent},
    {path: 'privacy', component:PrivacyComponent},
    {path: 'legal', component:LegalesComponent},
    {path: 'litiges', component:LitigesComponent},
    {path: 'fraud', component:FraudesComponent},
    {path: 'cookies', component:CookiesComponent},
    {path:'faq', component:QuestionsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
