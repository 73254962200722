<!-- <p>parcel-list works!</p> -->
<!-- <p>history don't yet works!</p> -->

<main>
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Liste des colis" | translate}}</font></h1>
            </div>
        </section>

        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="col-12 mt-4 mt-lg-0 o_colored_level">
                        <div class="" tabindex="-1">
                            <!-- <table class="o_list_table table table-sm table-hover position-relative mb-0 o_list_table_ungrouped table-striped col-12"> -->
                            <table class="o_list_table table table-sm table-hover position-relative mb-0 o_list_table_ungrouped col-12">
                                <thead>
                                    <tr>                   
                                        <th data-tooltip-delay="1000" tabindex="-1" data-name="activity_ids" class="col-lg-2" style="width: 5px;" >
                                            <div class="d-flex">
                                                <span class="d-block min-w-0 text-truncate flex-grow-1"></span>
                                                <!-- <i class="d-none fa-angle-down opacity-0 opacity-75-hover"></i> -->
                                            </div>
                                            <span class="o_resize position-absolute top-0 end-0 bottom-0 ps-1 bg-black-25 opacity-0 opacity-50-hover z-index-1"></span>
                                        </th>
                                        <th data-tooltip-delay="1000" tabindex="-1" data-name="name" 
                                        class="o_column_sortable position-relative cursor-pointer opacity-trigger-hover" 
                                        data-tooltip-template="web.ListHeaderTooltip" 
                                        style="width: 20px;">
                                            <div class="d-flex">
                                                <span class="d-block min-w-0 text-truncate flex-grow-1">{{"ID" | translate}}</span>
                                                <!-- <i class="fa fa-lg px-2 fa-angle-down opacity-0 opacity-75-hover"></i> -->
                                            </div>
                                            <span class="o_resize position-absolute top-0 end-0 bottom-0 ps-1 bg-black-25 opacity-0 opacity-50-hover z-index-1"></span>
                                        </th>
                                        <!-- <th data-tooltip-delay="1000" tabindex="-1" 
                                        data-name="create_date" class="align-middle o_column_sortable position-relative cursor-pointer o_date_cell opacity-trigger-hover" 
                                        style="width: 30px;">
                                            <div class="d-flex">
                                                <span class="d-block min-w-0 text-truncate flex-grow-1">Le</span>
                                                <i class="fa fa-lg px-2 fa-angle-down opacity-0 opacity-75-hover"></i>
                                            </div>
                                            <span class="o_resize position-absolute top-0 end-0 bottom-0 ps-1 bg-black-25 opacity-0 opacity-50-hover z-index-1"></span>
                                        </th> -->
                                        <th tabindex="-1" class="cursor-pointer" style="width: 40px;" title="">
                                            <div class="d-flex">
                                                <span>{{"Pour" | translate}}</span>
                                                <!-- <i class="fa fa-lg px-2 fa-angle-down opacity-0 opacity-75-hover"></i> -->
                                            </div>
                                            <span class="o_resize position-absolute top-0 end-0 bottom-0 ps-1 bg-black-25 opacity-0 opacity-50-hover z-index-1"></span>
                                        </th>
                                        <!-- <th data-tooltip-delay="1000" tabindex="-1" data-name="user_id" class="align-middle o_column_sortable position-relative cursor-pointer o_many2one_avatar_user_cell opacity-trigger-hover"  style="width: 30px;">
                                            <div class="d-flex">
                                                <span class="d-block min-w-0 text-truncate flex-grow-1">Ville de</span>
                                                <i class="fa fa-lg px-2 fa-angle-down opacity-0 opacity-75-hover"></i>
                                            </div>
                                            <span class="o_resize position-absolute top-0 end-0 bottom-0 ps-1 bg-black-25 opacity-0 opacity-50-hover z-index-1"></span>
                                        </th> -->
                                        <th data-tooltip-delay="1000" tabindex="-1" data-name="amount_total" class="align-middle o_column_sortable position-relative cursor-pointer o_many2one_avatar_user_cell opacity-trigger-hover" style="width: 30px;">
                                            <div class="d-flex">
                                                <span class="d-block min-w-0 text-truncate flex-grow-1">{{"Coût" | translate}}</span>
                                                <!-- <i class="fa fa-lg px-2 fa-angle-down opacity-0 opacity-75-hover"></i> -->
                                            </div>
                                            <span class="o_resize position-absolute top-0 end-0 bottom-0 ps-1 bg-black-25 opacity-0 opacity-50-hover z-index-1"></span>
                                        </th>
                                        <!-- <th data-tooltip-delay="1000" tabindex="-1" data-name="state" class="align-middle o_column_sortable position-relative cursor-pointer o_badge_cell opacity-trigger-hover" style="width: 20px;">
                                            <div class="d-flex">
                                                <span class="d-block min-w-0 text-truncate flex-grow-1">Statut</span>
                                                <i class="fa fa-lg px-2 fa-angle-down opacity-0 opacity-75-hover"></i>
                                            </div>
                                            <span class="o_resize position-absolute top-0 end-0 bottom-0 ps-1 bg-black-25 opacity-0 opacity-50-hover z-index-1"></span>
                                        </th> -->
                                    </tr>
                                </thead>
                                <tbody class="ui-sortable">
                                    <tr class="o_data_row text-info" data-id="datapoint_10" *ngFor="let parcel of parcels" (click)="viewDetails(parcel.trackingID)">
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_activity_cell" data-tooltip-delay="1000" tabindex="-1" name="activity_ids" data-tooltip="Aucun enregistrement">
                                            <div name="activity_ids" class="o_field_widget o_field_list_activity">
                                                <a class="o_ActivityButtonView" *ngIf="parcel.type === 'Courrier' " role="button"><i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-envelope" role="img"></i></a>
                                                <a class="o_ActivityButtonView" *ngIf="parcel.type === 'Paquet' " role="button"><i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-cube" role="img"></i></a>
                                                <span class="o_ListFieldActivityView_summary"></span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_char o_required_modifier o_readonly_modifier fw-bold" data-tooltip-delay="1000" tabindex="-1" name="name" data-tooltip="S00056">{{parcel.trackingID || 'MV45C2'}} 
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info" *ngIf="parcel.status === 'draft'">
                                                <span class="badge rounded-pill text-bg-info">{{"En Brouillon" | translate}}</span>
                                            </div>
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info" *ngIf="parcel.status === 'pending'">
                                                <span class="badge rounded-pill text-bg-info">{{"En attente ( Poste )" | translate}}</span>
                                            </div>
                                            <!-- <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info" *ngIf="parcel.status === 'rece'">
                                                <span class="badge rounded-pill text-bg-dark">En attente</span>
                                            </div> -->
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-light" *ngIf="parcel.status === 'received'">
                                                <span class="badge rounded-pill text-bg-secondary">{{"En Préparation ( Belgique )" | translate}}</span>
                                            </div>
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'arrived'">
                                                <span class="badge rounded-pill text-bg-warning">{{"Arrivé à" | translate}} ({{parcel.city}})</span>
                                            </div>
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'sent'">
                                                <span class="badge rounded-pill text-bg-primary">{{"Belgique vers" | translate}} {{parcel.city}}</span>
                                            </div>
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'delivered'">
                                                <span class="badge rounded-pill text-bg-success">{{"Livré" | translate}}</span>
                                            </div>
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'invalid'">
                                                <span class="badge rounded-pill text-bg-danger">{{"Invalidé (Contactez-nous)" | translate}}</span>
                                            </div>
                                            <i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-home" *ngIf="parcel.pickupMethod === 'home_delivery'"></i>
                                            <i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-building" *ngIf="parcel.pickupMethod === 'warehouse'"></i>
                                        </td>
                                        <!-- <td class="o_data_cell cursor-pointer o_field_cell o_date_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="create_date">
                                            <div name="create_date" class="o_field_widget o_readonly_modifier o_field_date">
                                                <span>18/05/2024</span>
                                            </div>
                                        </td> -->
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_many2one o_required_modifier o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="partner_id" data-tooltip="Client">{{parcel.receiver_name || 'Client'}}
                                            <div name="user_id" class="o_field_widget o_field_many2one_avatar_user o_field_many2one_avatar">
                                                <div class="d-flex">
                                                    <!-- <span class="o_m2o_avatar"><img src="/web/image/res.users/2/avatar_128"></span> -->
                                                    <span>
                                                        <span>{{(parcel.city).toUpperCase() || 'Kinshasa'}}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div name="create_date" class="o_field_widget o_readonly_modifier o_field_date">
                                                <span>{{parcel.create_date || '01/01/2024'}}</span>
                                            </div>
                                        </td>
                                        <!-- <td class="o_data_cell cursor-pointer o_field_cell o_list_many2one o_many2one_avatar_user_cell" data-tooltip-delay="1000" tabindex="-1" name="user_id" data-tooltip="Lubumbashi">
                                            <div name="user_id" class="o_field_widget o_field_many2one_avatar_user o_field_many2one_avatar">
                                                <div class="d-flex" data-tooltip="Lubumbashi">
                                                    <span class="o_m2o_avatar"><img src="/web/image/res.users/2/avatar_128"></span>
                                                    <span>
                                                        <span>Lubumbashi</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td> -->
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_number o_monetary_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="amount_total">
                                            <div name="amount_total" class="o_field_widget o_readonly_modifier o_field_monetary fw-bold">
                                                <span>{{parcel.cost || '20.0'}}<i class="fa fa-fw fa-eur "></i></span>
                                            </div>
                                            <div name="amount_total" class="o_field_widget o_readonly_modifier o_field_monetary fw-bold">
                                                <span *ngIf="parcel.type == 'Courrier'">0-1 Kg</span>
                                                <span *ngIf="parcel.type == 'Paquet'">{{parcel.weight + ' Kg'}} </span>
                                            </div>
                                            
                                        </td>
                                        <!-- <td class="o_data_cell cursor-pointer o_field_cell o_badge_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="state" data-tooltip="Devis">
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info">
                                                <span class="badge rounded-pill text-bg-info">Brouillon</span>
                                            </div>
                                        </td> -->
                                    </tr>
                                    <!-- <tr class="o_data_row text-info">
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_activity_cell">
                                            <div name="activity_ids" class="o_field_widget o_field_list_activity">
                                                <a class="o_ActivityButtonView" role="button">
                                                    <i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-cube" role="img"></i>
                                                </a>
                                                <span class="o_ListFieldActivityView_summary"></span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_char o_required_modifier o_readonly_modifier fw-bold">S00058
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info">
                                                <span class="badge rounded-pill text-bg-dark">En attente</span>
                                            </div>
                                            <i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-home"></i>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_date_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="create_date">
                                            <div name="create_date" class="o_field_widget o_readonly_modifier o_field_date">
                                                <span>07/08/2024</span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_many2one o_required_modifier o_readonly_modifier">Darryl Kalambayi
                                            <div class="d-flex" data-tooltip="Kinshasa">
                                                <span>
                                                    <span>Kinshasa</span>
                                                </span>
                                            </div>
                                            <div name="create_date" class="o_field_widget o_readonly_modifier o_field_date">
                                                <span>07/08/2024</span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_many2one o_many2one_avatar_user_cell" data-tooltip-delay="1000" tabindex="-1" name="user_id" data-tooltip="Kinshasa">
                                            <div name="user_id" class="o_field_widget o_field_many2one_avatar_user o_field_many2one_avatar">
                                                <div class="d-flex" data-tooltip="Kinshasa">
                                                    <span>
                                                        <span>Kinshasa</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                    
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_number o_monetary_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="amount_total">
                                            <div name="amount_total" class="o_field_widget o_readonly_modifier o_field_monetary fw-bold">
                                                <span>94,000<i class="fa fa-fw fa-eur "></i></span>
                                            </div>
                                            <div name="amount_total" class="o_field_widget o_readonly_modifier o_field_monetary fw-bold">
                                                <span>9 Kg</span>
                                            </div>
                                            
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_badge_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="state" data-tooltip="Devis">
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info">
                                                <span class="badge rounded-pill text-bg-dark">En attente</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="o_data_row text-info">
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_activity_cell">
                                            <div name="activity_ids" class="o_field_widget o_field_list_activity">
                                                <a class="o_ActivityButtonView" role="button"><i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-cube" role="img"></i></a>
                                                <span class="o_ListFieldActivityView_summary"></span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_char o_required_modifier o_readonly_modifier fw-bold" >S00057
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success">
                                                <span class="badge rounded-pill text-bg-success">Livré</span>
                                            </div>
                                            <i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-building"></i>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_date_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="create_date">
                                            <div name="create_date" class="o_field_widget o_readonly_modifier o_field_date">
                                                <span>24/07/2024</span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_many2one o_required_modifier o_readonly_modifier">La Confiance ASBL
                                            <div name="user_id" class="o_field_widget o_field_many2one_avatar_user o_field_many2one_avatar">
                                                <div class="d-flex" data-tooltip="Kinshasa">
                                                    <span><span>Kinshasa</span></span>
                                                </div>
                                            </div>
                                            <div name="create_date" class="o_field_widget o_readonly_modifier o_field_date">
                                                <span>24/07/2024</span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_many2one o_many2one_avatar_user_cell" data-tooltip-delay="1000" tabindex="-1" name="user_id" data-tooltip="Kinshasa">
                                            <div name="user_id" class="o_field_widget o_field_many2one_avatar_user o_field_many2one_avatar">
                                                <div class="d-flex" data-tooltip="Kinshasa">
                                                    <span><span>Kinshasa</span></span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_number o_monetary_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="amount_total">
                                            <div name="amount_total" class="o_field_widget o_readonly_modifier o_field_monetary fw-bold">
                                                <span>288,100<i class="fa fa-fw fa-eur "></i></span>
                                            </div>
                                            <div name="amount_total" class="o_field_widget o_readonly_modifier o_field_monetary fw-bold">
                                                <span>7 Kg</span>
                                            </div>
                                            
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_badge_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="state" data-tooltip="Devis">
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info">
                                                <span class="badge rounded-pill text-bg-secondary">Livré</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="o_data_row text-info">
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_activity_cell" data-tooltip-delay="1000" tabindex="-1" name="activity_ids" data-tooltip="Aucun enregistrement">
                                            <div name="activity_ids" class="o_field_widget o_field_list_activity">
                                                <a class="o_ActivityButtonView" role="button">
                                                    <i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-cube" role="img"></i>
                                                </a>
                                                <span class="o_ListFieldActivityView_summary"></span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_char o_required_modifier o_readonly_modifier fw-bold" data-tooltip-delay="1000" tabindex="-1" name="name" data-tooltip="S00050">S00050
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success">
                                                <span class="badge rounded-pill text-bg-success">Livré</span>
                                            </div>
                                            <i class="o_ActivityButtonView_icon fa fa-fw fa-lg text-muted fa-home"></i>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_date_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="create_date">
                                            <div name="create_date" class="o_field_widget o_readonly_modifier o_field_date">
                                                <span>12/03/2024</span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_many2one o_required_modifier o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="partner_id" data-tooltip="Bennani &amp; Associés">Bennani &amp; Associés
                                            <div name="user_id" class="o_field_widget o_field_many2one_avatar_user o_field_many2one_avatar">
                                                <div class="d-flex" data-tooltip="Kinshasa">
                                                    <span><span>Kinshasa</span></span>
                                                </div>
                                            </div>
                                            <div name="create_date" class="o_field_widget o_readonly_modifier o_field_date">
                                                <span>12/03/2024</span>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_many2one o_many2one_avatar_user_cell" data-tooltip-delay="1000" tabindex="-1" name="user_id" data-tooltip="Kinshasa">
                                            <div name="user_id" class="o_field_widget o_field_many2one_avatar_user o_field_many2one_avatar">
                                                <div class="d-flex" data-tooltip="Kinshasa">
                                                    <span><span>Kinshasa</span></span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_list_number o_monetary_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="amount_total">
                                            <div name="amount_total" class="o_field_widget o_readonly_modifier o_field_monetary fw-bold">
                                                
                                                <span>301,50<i class="fa fa-fw fa-eur "></i></span>
                                            </div>
                                            <div name="amount_total" class="o_field_widget o_readonly_modifier o_field_monetary fw-bold">
                                                
                                                <span>10 Kg</span>
                                            </div>
                                            
                                        </td>
                                        <td class="o_data_cell cursor-pointer o_field_cell o_badge_cell o_readonly_modifier" data-tooltip-delay="1000" tabindex="-1" name="state" data-tooltip="Bon de commande">
                                            <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success">
                                                <span class="badge rounded-pill text-bg-success">Payé</span>
                                            </div>
                                        </td>
                                    </tr> -->
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>
            </div>
            



        </section>
         
        

    </div>
    
    
    
    
    
    
    
    
    
    
</main>

           