import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { loadStripe } from '@stripe/stripe-js';
import { ref } from 'firebase/storage';
// import { Router } from '@angular/router';

@Component({
  selector: 'app-parcel-details',
  templateUrl: './parcel-details.component.html',
  styleUrl: './parcel-details.component.css'
})
// export class ParcelDetailsComponent implements OnInit, AfterViewInit{
export class ParcelDetailsComponent implements OnInit{
  private stripePromise = loadStripe('pk_live_51PjczsRo141DzeBj2m3BxL6Cgdia4NTaT7eUsYLGArUg2tvaHVejpDLzixAksSnWXuRTKAAvXIXl1VDurThCKW8p00neC7Y1PI')
  parcel : any | null; 
  parcelID: string | null = null;
  user!: null;
  mapUrl: SafeResourceUrl | null = null;
  trackingID: string | null = null;
  isLoading = false;
  waitingText: string = "";
  errorMessage = "";
  statuses: Array<'pending' | 'received' | 'sent' | 'arrived' | 'delivered'> = ['pending', 'received', 'sent', 'arrived', 'delivered'];
  progressWidth = '0%';

  constructor(
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authService : AuthService,
    private sanitizer: DomSanitizer 
  ){}

  // isActive(status: string): boolean {
  //   return this.parcel?.status === status;
  // }

  // isActive(status: string): boolean {
  //   const currentIndex = this.statuses.indexOf(this.parcel.status as any);
  //   const statusIndex = this.statuses.indexOf(status as any);
  //   return statusIndex <= currentIndex;
  // }
  isActive(status: string): boolean {
    const statusOrder = ['pending', 'received', 'sent', 'arrived', 'delivered'];
    const currentIndex = statusOrder.indexOf(this.parcel?.status);
    const statusIndex = statusOrder.indexOf(status);
    return statusIndex <= currentIndex;
  }

  calculateProgress() {
    const currentIndex = this.statuses.indexOf(this.parcel.status as any);
    const totalSteps = this.statuses.length - 1;
    const progressPercentage = (currentIndex / totalSteps) * 100;
    this.progressWidth = `${progressPercentage}%`;
  }
  ngOnInit(): void {
    this.trackingID = this.route.snapshot.queryParamMap.get('trackingID');
    console.log('For parcel: ', this.trackingID);
    
    if (this.trackingID) {
      this.firestore.collection('parcel', ref => ref.where('trackingID', '==', this.trackingID))
        .snapshotChanges()
        .subscribe(snapshot => {
          if (snapshot && snapshot.length > 0) {
            const parcelDoc = snapshot[0]; // Get the first matching document
            this.parcel = parcelDoc.payload.doc.data(); // Get the parcel data
            this.parcelID = parcelDoc.payload.doc.id; // Store the document ID in parcelID
            console.log("Parcel: ", this.parcel);
            const listItems = document.querySelectorAll('#olList li');
            listItems.forEach(item => item.classList.remove('active'));

            this.calculateProgress();
            switch (this.parcel.status) {
              case 'pending':
                console.log("Status : ", this.parcel.status);
                document.getElementById('pendingB')?.classList.add('active');
                break;
              case 'received':
                console.log("Status : ", this.parcel.status);
                document.getElementById('receivedB')?.classList.add('active');
                break;
              case 'sent':
                console.log("Status : ", this.parcel.status);
                document.getElementById('sentB')?.classList.add('active');
                break;
              case 'arrived':
                console.log("Status : ", this.parcel.status);
                document.getElementById('arrivedB')?.classList.add('active');
                break;
              case 'delivered':
                console.log("Status : ", this.parcel.status);
                const deliveredElement = document.getElementById('deliveredB');
                console.log("Delivered Element: ", deliveredElement);
                if (deliveredElement) {
                  deliveredElement.classList.add('active');
                } else {
                  console.error("Element with ID 'deliveredB' not found.");
                }
                break;
              default:
                console.log("Status : ", this.parcel.status);
                console.log('Unknown status:', this.parcel.status);


            }
            console.log("Parcel Document ID: ", this.parcelID);
            if (this.parcel.location) {
              const { latitude, longitude } = this.parcel.location;
              console.log("lat: ", latitude, "and lon: ", longitude);
              this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${latitude},${longitude}&z=12&ie=UTF8&iwloc=&output=embed`);
              console.log("Map URL: ", this.mapUrl);
            } else {
              console.warn("No location data available for this parcel.");
            }
          } else {
            console.error("No parcel found with the provided tracking ID.");
          }
        }, error => {
          console.error("Error fetching parcel data: ", error);
        });
    } else {
      console.error("No tracking ID found in route.");
    }
  
  }

  async draftPayment (){
    const user = this.authService.user;
    if (!(user === null)){
      
      localStorage.setItem('parcelFormData', JSON.stringify(this.parcel));
      console.log("Data stored : ", localStorage.getItem('parcelFormData'));
      // this.isLoading = false;
      this.router.navigate(["/destination"], {queryParams: {restore: true}});
    }else {
      this.router.navigate(['/auth'], { queryParams: { returnUrl: `/details?trackingID=${this.trackingID}`,}});
    }
    
  }
  async deleteParcel() {
    const user = this.authService.user;
    if (this.parcel.status === 'draft'){
      if (this.parcel.uid === user?.uid){
        const querySnapshot = await this.firestore.collection('parcel', ref => ref.where('trackingID', '==', this.trackingID)).get().toPromise();
            if (querySnapshot && !querySnapshot.empty) {
                querySnapshot.forEach(async (doc) => {
                    await this.firestore.doc(`parcel/${doc.id}`).delete();
                    console.log("Parcel has been deleted");
                    this.router.navigate(['/history']);
                });
              }
      }else {
        this.errorMessage = "Seul le propiétaire du colis peut le retirer"
      }
    }else {
      this.errorMessage = "Impossible de supprimer un colis commandé"
    }
    
    }
}
