import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit{
  homeForm! : FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router
  ){

  }
  ngOnInit(): void {
    this.homeForm = this.fb.group({
      trackingID: ['', Validators.required],
      
    });
  }

  onSubmit(): void {
    if (this.homeForm.valid){
      const trackingID = (this.homeForm.get("trackingID")?.value).toUpperCase();
      console.log("Tracking ID is ", trackingID);
      this.homeForm.get('trackingID')?.setValue("");
      this.router.navigate(['/details'], { queryParams: { trackingID } });
    }
  }
}
