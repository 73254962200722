<!-- <p>payment works!</p> -->


<main>
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <!-- <h1><font style="color: rgb(0, 0, 0);">Félicitations</font></h1> -->
                <h1><font style="color: rgb(0, 0, 0);">{{"Félicitations" | translate}}</font> 🎉</h1>
            </div>
        </section>
          
        <div id="wrap" class="oe_structure oe_empty">
            <section class="s_text_block pt40 pb40 o_colored_level " data-snippet="s_text_block">
                <div class="container s_allow_columns">
                    <div class="row">
                        <div class="col-lg-4 col-xl-6 me-lg-auto">
                            <span class="d-block fa fa-4x fa-thumbs-o-up mx-auto rounded-circle border" ></span><br>
                            <h1 class="text-center">{{"Merci" | translate}} !</h1>
                            <div class="s_hr" data-snippet="s_hr" data-name="Separator">
                                <hr class="mx-auto border-top w-50 border-dark text-center">
                            </div>
                            <h5 class="text-center">
                                <span class="fa fa-check-circle"></span>
                                <span>{{"Merci d'avoir choisi " | translate}} <b>BeFret</b> {{"pour vos envois" | translate}} ! {{"Nous apprécions votre confiance." | translate}}<br> {{"Voici le code pour suivre votre colis :" | translate}}</span>
                            </h5>
                            <!-- <p class="text-center">Voici le code pour traker votre colis à tous moment : <br></p> -->
                            <span>
                                <a style="align-self: center; align-items: center; justify-content: center; display: flex;" (click)="copyToClipBoard()"><i class="fa fa-1x fa-clipboard"></i></a>
                                <a> <h1 class="text-primary" style="text-align: center; cursor: pointer;" (click)="copyToClipBoard()"><strong>{{this.trackingID || 'MM46C2'}}</strong></h1></a>
                           </span>
                             
                            
                            
                        </div>
                        <div class="col-lg-6" style="align-items: center; justify-content: center; display: flex; flex-direction: column;">
                            <div *ngIf="!trackingID && !parcelID">
                                <p>Loading...</p>
                              </div>
                            <div *ngIf="trackingID && parcelID" #pdfContent style=" display: flex; width: 250px; align-items: center; justify-content: center;" >
                                <div >
                                    <table class="table-bordered">
                                        <tr class="text-center" style="align-items: center; justify-content: center;">
                                            <td style="align-items: center; justify-content: center;"><img src="/assets/befret_logo.png" alt="Company Logo" class="company-logo" style="width: 85px; height: 30px; margin-inline-start: 10px;"></td>
                                            <td>{{"Envoyer à" | translate}}: <strong><br>35, Rue Ilya PRIGOGINE <br>1480 SAINTE (TUBIZE) <br>{{"BELGIQUE" | translate}}</strong></td>
                                        </tr>
                                        <tr class="text-center">
                                            <td><img *ngIf="qrImg" [src]="qrImg" alt="Parcel QR Code" style="align-self: center; width: 85px; height: 85px;"></td>
                                            <td >{{"Code de suivi" | translate}}<br><h3><strong>{{ trackingID }}</strong></h3></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-center"><strong>BeFret : </strong>{{this.parcelID}}</td>
                                            <!-- <td class="text-left"></td> -->
                                            <!-- <td></td> -->
                                        </tr>
                                    </table>
                                    <!-- <ul class="list-unstyled mb-0 ps-2" >
                                        <p><strong>BeFret : </strong> {{ parcelID }}</p>
                                        <p>Code de suivi : <strong>{{ trackingID }}</strong></p>
                                        <div class="qr-code">
                                            <img *ngIf="qrImg" [src]="qrImg" alt="Parcel QR Code" style="align-self: center; width: 85px; height: 85px;">
                                            <img src="/assets/befret_logo.png" alt="Company Logo" class="company-logo" style="width: 85px; height: 30px; margin-inline-start: 10px;">
                                        </div>
                                        
                                        
                                        <li><strong>Adresse: </strong> 35, Rue Ilya PRIGOGINE,</li>
                                        <li>1480 Saintese (Tubize, Belgique)</li>
                                        <li></li>
                                        <li></li>
                                    </ul> -->
                                </div>
                              </div>
                            <ul class="list-unstyled mb-0 ps-2">
                                <li> <br>{{"Pour garantir le bon déroulement du processus, nous vous invitons à suivre attentivement les instructions ci-dessous:" | translate}}</li>
                                <!-- <li><h2>Les Etapes</h2></li> -->
                                <li><strong>{{"Etape 1 : Télécharger l’Étiquette" | translate}}</strong></li>
                                
                                <li class="nav-link" (click)="generatePDF()" style="cursor: pointer;">
                                    <span class="o_force_ltr" >
                                        <a style="text-decoration:underline;">{{"Cliquez ici pour télécharger et imprimer l’étiquette :" | translate}} </a>
                                    </span> <i class="fa fa-download fa-fw me-2"></i></li>
                                <li><strong>{{"Etape 2 : Coller l’Étiquette sur le Paquet ou courrier" | translate}}</strong></li>
                                <li><span class="o_force_ltr">{{"Veuillez découper soigneusement l'étiquette et la coller solidement sur le paquet ou courrier de manière que le QR code et l’adresse de Befret soient bien visibles et lisibles." | translate}}</span></li>
                                <li><strong>{{"OU " | translate}}</strong></li>
                                <li>{{"Si vous n'avez pas la possibilité d'imprimer l'étiquette, il suffit de" | translate}} <strong>{{"noter clairement et lisiblement le code :" | translate}} {{trackingID}} {{"et l'adresse de Befret" | translate}}</strong> {{"directement sur le paquet ou courrier." | translate}}</li>
                                <li><strong><br>{{"Etape 3 : Envoyer le Paquet ou courrier à Befret" | translate}}</strong></li>
                                <li><span class="o_force_ltr">{{"Envoyez votre paquet ou courrier au point d’envoi de votre choix, soit par La Poste, Mondial Relay, ou un autre service de livraison adapté." | translate}}</span></li>
                                <!-- <li><span class="o_force_ltr"><h5><br><i class="fa fa-1x fa-warning"></i> Si vous ne pouvez pas imprimer le PDF il vous suffit d'écrire le code <strong>{{trackingID}}</strong><br> sur votre colis de façon tres lisible</h5></span></li> -->
                                <!-- <li><i class="fa fa-1x fa-fw fa-envelope me-2"></i><span></span></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>         
    

<div id="o_shared_blocks" class="oe_unremovable"></div>
</main>
