import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ParcelDestinationComponent } from './parcel-destination/parcel-destination.component';
import { ParcelDescriptionComponent } from './parcel-description/parcel-description.component';
import { ParcelSendComponent } from './parcel-send/parcel-send.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PaymentComponent } from './payment/payment.component';
import { AuthComponent } from './auth/auth.component';
import { PostComponent } from './post/post.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from './environment';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { HistoryComponent } from './history/history.component';
import { TrackingComponent } from './tracking/tracking.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// Firebase related imports
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
import { ParcelDetailsComponent } from './parcel-details/parcel-details.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ParcelStatusComponent } from './parcel-status/parcel-status.component';
import { ChangeStatusComponent } from './change-status/change-status.component';
import { ParcelListComponent } from './parcel-list/parcel-list.component';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { ManageEmpoyeeComponent } from './manage-empoyee/manage-empoyee.component';
import { SuccessMessageSentComponent } from './success-message-sent/success-message-sent.component';
import { MailListComponent } from './mail-list/mail-list.component';
import { AnswerMailComponent } from './answer-mail/answer-mail.component';
import { ReadMailComponent } from './read-mail/read-mail.component';
import { ConditionsComponent } from './conditions/conditions.component';
import { FraudesComponent } from './fraudes/fraudes.component';
import { LegalesComponent } from './legales/legales.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './cookies/cookies.component';
import { LitigesComponent } from './litiges/litiges.component';
import { QuestionsComponent } from './questions/questions.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ParcelDestinationComponent,
    ParcelDescriptionComponent,
    ParcelSendComponent,
    TopBarComponent,
    PaymentComponent,
    AuthComponent,
    PostComponent,
    FooterComponent,
    UserProfileComponent,
    HistoryComponent,
    TrackingComponent,
    ContactusComponent,
    ResetPasswordComponent,
    ParcelDetailsComponent,
    ParcelStatusComponent,
    ChangeStatusComponent,
    ParcelListComponent,
    CreateEmployeeComponent,
    ManageEmpoyeeComponent,
    SuccessMessageSentComponent,
    MailListComponent,
    AnswerMailComponent,
    ReadMailComponent,
    ConditionsComponent,
    FraudesComponent,
    LegalesComponent,
    PrivacyComponent,
    CookiesComponent,
    LitigesComponent,
    QuestionsComponent
  ],
  imports: [
    BrowserModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireAuthModule,
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FormsModule,
    MatIconModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatInputModule,
    BaseChartDirective,
    ZXingScannerModule,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),
    provideCharts(withDefaultRegisterables()),
    
    // provideAppCheck(() => {
    //   const provider = new ReCaptchaEnterpriseProvider('YOUR_RECAPTCHA_KEY');
    //   return initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });
    // })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
