<!-- <p>parcel-status works!</p> -->
<!-- <p>parcel-details works!</p> -->
<div  id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
    <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
        <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
        <div class="o_we_bg_filter bg-black-0"></div>
        <div class="container">
            <h1><font style="color: rgb(0, 0, 0);">Infos du colis</font></h1>
        </div>
    </section>
    <section class="s_text_image pt32 pb32 o_colored_level" data-snippet="s_text_image" data-name="Texte - Image">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 o_colored_level ">
                <!-- <div *ngIf="parcel" class="col-lg-6 o_colored_level "> -->
                    <h2 class="o_default_snippet_text">&nbsp;Code: <strong>{{parcel.trackingID}}</strong></h2>
                    <h2 class="o_default_snippet_text">&nbsp;ID: <strong>{{parcelID}}</strong></h2>
                    <table class="o_list_table table table-sm">
                        <thead>
                            <th>.</th>
                            <th>.</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Type</td>
                                <td>{{parcel.type || 'Paquet'}}</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td>{{parcel.description || 'Colis pour papa'}}</td>
                            </tr>
                            <tr>
                                <td>Poids</td>
                                <td>{{parcel.weight + ' Kg'}}</td>
                            </tr>
                            <tr *ngIf="parcel.real_weight">
                                <td>Poids Effectif</td>
                                <td>{{parcel.real_weight + ' Kg'}}</td>
                            </tr>
                            <tr>
                                <td>Expediteur</td>
                                <td>{{parcel.sender_name || 'Jean-Marie Kalemba'}}</td>
                            </tr>
                            <tr>
                                <td>Destinataire</td>
                                <td>{{parcel.receiver_name || 'Jean-Marie Kalemba'}}</td>
                            </tr>
                            <tr>
                                <td>Livraison</td>
                                <td *ngIf="parcel.pickupMethod === 'home_delivery'">A domicile</td>
                                <td *ngIf="parcel.pickupMethod === 'warehouse'">A l'entrêpot BeFret {{parcel.city}}</td>
                            </tr>
                            <tr *ngIf="parcel.pickupMethod === 'home_delivery'">
                                <td>Adresse de livraison</td>
                                <td>{{parcel.address + ', '+ (parcel.city).toUpperCase() || 'Av Luzumu, Q/Bangu C/Ngaliema, '+ (parcel.city).toUpperCase()}}</td>
                            </tr>
                            <tr>
                                <td>Date de commande</td>
                                <td>{{parcel.create_date || '25/05/2024'}}</td>
                            </tr>
                            <tr *ngIF="(parcel.status === 'received')">
                                <td>Date de reception</td>
                                <td>{{parcel.receive_date || '25/05/2024'}}</td>
                            </tr>
                            <tr *ngIF="(parcel.status === 'received' || parcel.status === 'sent' || parcel.status === 'arrived' || parcel.status === 'delivered')">
                                <td>Réçu par</td>
                                <td>{{parcel.receive_agent || parcel.receive_agent_uid || 'Admin'}}</td>
                            </tr>
                            <tr *ngIF="!(parcel.status === 'pending' || parcel.status === 'draft')">
                                <td>Réçu par</td>
                                <td>{{parcel.receive_agent || 'Mathieu Mombele'}}</td>
                            </tr>
                            <tr *ngIf="parcel.delivery_date">
                                <td>Date de livraison</td>
                                <td>{{parcel.delivery_date || '27/05/2024'}}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td><div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info" *ngIf="parcel.status === 'draft'">
                                    <span class="badge rounded-pill text-bg-info">En Brouillon</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info" *ngIf="parcel.status === 'pending'">
                                    <span class="badge rounded-pill text-bg-info">En attente ( Poste )</span>
                                </div>
                                <!-- <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-info" *ngIf="parcel.status === 'rece'">
                                    <span class="badge rounded-pill text-bg-dark">En attente</span>
                                </div> -->
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-light" *ngIf="parcel.status === 'received'">
                                    <span class="badge rounded-pill text-bg-secondary">En Préparation ( {{"Belgique"}} )</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-light" *ngIf="parcel.status === 'sent'">
                                    <span class="badge rounded-pill text-bg-primary">Belgique vers {{parcel.city}}</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'arrived'">
                                    <span class="badge rounded-pill text-bg-warning">A destination ({{parcel.city}})</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'delivered'">
                                    <span class="badge rounded-pill text-bg-success">Livré à {{parcel.city}}</span>
                                </div>
                                <div name="state" class="o_field_widget o_readonly_modifier o_field_badge text-success" *ngIf="parcel.status === 'invalid'">
                                    <span class="badge rounded-pill text-bg-danger">Invalidé (Contactez-nous)</span>
                                </div></td>
                                <!-- <td>{{parcel.status || 'Livré'}}</td> -->
                            </tr>
                            <tr *ngIf="parcel.status === 'invalid'">
                               <td>Raison d'invalidation</td> 
                               <td><strong>{{parcel.invalidation_reason || 'OverWeigth'}}</strong></td> 
                            </tr>
                            <tr *ngIf="parcel.status === 'invalid' && parcel.invalidation_reason === 'overWeight'">
                               <td>Surpoids</td> 
                               <td><strong>{{parcel.overWeight || '13KG'}}</strong></td> 
                            </tr>
                            <tr>
                                <td>Coût</td>
                                <td>€ {{parcel.cost || 15}}</td>
                            </tr>
                            <!-- <tr>
                                <td>Date de commande</td>
                                <td>{{(parcel.create_date).split('T')[0] || '01-01-2024'}}</td>
                            </tr> -->
                        </tbody>
                    </table>
                    <h6 style="color: red;">{{errorMessage}}</h6>
                    <!-- <div *ngIf="parcel.status === 'pending'" > -->
                    <div *ngIf="parcel.status === 'pending' && isValid === true" class="change-status">
                        <button class="btn btn-primary" (click)="receiveAntenne()" >
                            <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span>
                            <span >Recevoir</span>
                            </button>
                        <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" 
                        (click)="notValid()">Invalider</button>
                    </div>
                    <div *ngIf="parcel.status === 'pending' && isValid === !true" class="change-status">
                        <h4>Raison d'invalidation</h4>
                        <button class="btn btn-primary" (click)="overWeight()" >
                            <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span>
                            <span >Surpoids</span>
                            </button>
                        <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" 
                        (click)="invalidParcel()">Non-conformité</button>
                    </div>
                    <div *ngIf="parcel.status === 'pending' && isValid === !true && isOverWeight === true" class="change-status">
                        <h5>Surpoids :</h5>
                        <form  data-mark="*" data-pre-fill="true" [formGroup]="overWeightForm" (ngSubmit)="onSubmit()"
                                class="o_mark_optional">
                                <label class="s_website_form_label" style="width: 200px" for="weight">
                                    <span class="s_website_form_label_content">Excédant (KG)*</span>
                                </label>
                                <input class="form-control s_website_form_input" type="number"
                                id="weight" data-fill-with="weight" formControlName="weight" min="1.0" (change)="calculateCost()" >
                                <h3>Coût suplementaire : €{{cost}}</h3>
                                <div style="margin-top: 5px;">
                                    <button class="btn btn-primary" type="submit" >
                                        <!-- <span *ngIf="isLoading" class="spinner-container">
                                            <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                                        </span> -->
                                        <span >Confirmer</span>
                                    </button>
                                    <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" 
                                        (click)="cancelOverWeigth()">Annuler</button>
                                </div>
                                
                        </form>
                        <!-- <button class="btn btn-primary" (click)="overWeight()" >
                            <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span>
                            <span >Surpoids</span>
                            </button>
                        <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" 
                        (click)="invalidParcel()">Non-conformité</button> -->
                    </div>
                    <div *ngIf="parcel.status === 'invalid'" class="change-status">
                        <button class="btn btn-primary" (click)="receiveAntenne()" >
                            <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span>
                            <span>Recevoir</span>
                            </button>
                        <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" 
                        (click)="deleteParcel()">
                        <span *ngIf="isLoading" class="spinner-container">
                            <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                        </span>
                        <span>Supprimmer</span></button>
                    </div>
                    <div *ngIf="parcel.status === 'received'" class="change-status">
                        <button class="btn btn-primary" (click)="expedition()" >
                            <!-- <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span> -->
                            <span >Expédier</span>
                            </button>
                        <!-- <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" 
                        (click)="deleteParcel()">Retour Client</button> -->
                    </div>
                    <div *ngIf="parcel.status === 'sent'" class="change-status">
                        <button class="btn btn-primary" (click)="receiveDRC()" >
                            <!-- <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span> -->
                            <span >Reception DRC</span>
                            </button>
                        <!-- <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" (click)="deleteParcel()">Accuser Dommage</button> -->
                    </div>
                    <div *ngIf="parcel.status === 'arrived'" class="change-status">
                        <button class="btn btn-primary" (click)="startDelivery()" >
                            <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span>
                            <span >Livrer</span>
                            </button>
                        <!-- <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" (click)="deleteParcel()">Retour Entrpôt</button> -->
                    </div>
                    <div *ngIf="isDelivering" class="change-status">
                    <!-- <div class="change-status"> -->
                        <h4>Livraison* :</h4>
                        <form  data-mark="*" data-pre-fill="true" [formGroup]="deliveryForm" (ngSubmit)="onSubmitDelivery()"
                                class="o_mark_optional">
                                <!-- <label class="s_website_form_label" style="width: 200px" for="weight">
                                    <span class="s_website_form_label_content">Excédant (KG)*</span>
                                </label> -->
                                <input class="form-control s_website_form_input" type="text" id="receiver_name" 
                                formControlName="receiver_name" placeholder="Réçu par">
                                <div style="display: flex; flex-direction:row; margin-top: 5px;">
                                    <div class="s_website_form_field mb-3 col-4 s_website_form_custom s_website_form_required">
                                        <!-- <label class="s_website_form_label" style="width: 200px" for="phonePrefix1">
                                            <span class="s_website_form_label_content">Pays *</span>
                                        </label> -->
                                        <select id="phonePrefix1" formControlName="receiver_prefix" class="form-select s_website_form_input">
                                            <option *ngFor="let prefix of phonePrefixes" [value]="prefix.value">
                                                {{ prefix.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-3 col-8" style="margin: 0px 0px 0px 5px;">
                                        <input class="form-control s_website_form_input" type="tel" placeholder="Téléphone"
                                        formControlName="receiver_phone" id="receiver_phone" maxlength="15" pattern="\d{1,15}">
                                    </div>
                                </div>
                                <!-- <h3>Coût suplementaire : €{{cost}}</h3> -->
                                <div style="margin-top: 5px;">
                                    <button class="btn btn-primary" type="submit" >
                                        <!-- <span *ngIf="isLoading" class="spinner-container">
                                            <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                                        </span> -->
                                        <span >Confirmer</span>
                                    </button>
                                    <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" 
                                        (click)="cancelDelivery()">Annuler</button>
                                </div>
                                
                        </form>
                        <!-- <button class="btn btn-primary" (click)="overWeight()" >
                            <span *ngIf="isLoading" class="spinner-container">
                                <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                            </span>
                            <span >Surpoids</span>
                            </button>
                        <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" 
                        (click)="invalidParcel()">Non-conformité</button> -->
                    </div>
                    <!-- <div class="change-status">
                        <button class="btn btn-primary" (click)="draftPayment()" >
                            
                            <span >Faire la reception</span>
                            </button>
                        <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" (click)="deleteParcel()">Ordonner un retour</button>
                    </div>
                    <div class="change-status">
                        <button class="btn btn-primary" (click)="draftPayment()" >
                            
                            <span >Faire la reception</span>
                            </button>
                        <button class="btn btn-secondary" style="margin: 0px 0px 0px 10px;" (click)="deleteParcel()">Ordonner un retour</button>
                    </div> -->
                </div>
                <div *ngIf="!parcel" class="col-lg-6 o_colored_level">
                    <h2>Code invalide. Vérifiez votre tracking code aupres de notre service client</h2>
                </div>
                <div class="col-lg-6 pt16 pb16 o_colored_level">
                    <section class="s_map pt256 pb72" data-map-type="m" data-map-zoom="12" data-snippet="s_map" data-map-address="Rue Ilya PRIGOGINE 35 Tubize Belgique" data-name="Carte">
        <div class="map_container o_not_editable">
            <div class="css_non_editable_mode_hidden">
                <div class="missing_option_warning alert alert-info rounded-0 fade show d-none d-print-none o_default_snippet_text">
                    Une adresse doit être spécifiée pour qu'une carte soit intégrée
                </div>
            </div>
            <iframe *ngIf="!parcel.location" class="s_map_embedded o_not_editable" src="https://maps.google.com/maps?q=250%20Executive%20Park%20Blvd%2C%20Suite%203400%20San%20Francisco%20California%20(US)%20United%20States&amp;t=m&amp;z=12&amp;ie=UTF8&amp;iwloc=&amp;output=embed" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" aria-label="Carte"></iframe>
            <iframe *ngIf="parcel?.location" [src]="mapUrl" width="100%" height="350px" frameborder="0" style="border:0;" allowfullscreen></iframe>
            <div class="s_map_color_filter"></div>
        </div>
    </section>
</div>