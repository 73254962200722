  <header id="top" data-anchor="true" data-name="Header" data-extra-items-toggle-aria-label="Bouton d'éléments supplémentaires" class="o_hoverable_dropdown">
    <nav data-name="Navbar" aria-label="Main" class="navbar navbar-expand-lg navbar-light o_colored_level o_cc o_header_force_no_radius d-none d-lg-block p-0 shadow-sm ">
      <div id="o_main_nav" class="o_main_nav">
        <div class="o_header_hide_on_scroll">
          <div aria-label="Haut" class="o_header_sales_two_top py-1">
            <ul class="navbar-nav container d-grid h-100 px-3 o_grid_header_3_cols">
              <!-- <li class="o_header_sales_two_txt_elts_placeholder"></li>
              <li class="o_header_sales_two_social_links_placeholder"></li> -->
            </ul>
          </div>
          <div aria-label="Moyen" class="container d-flex justify-content-between align-items-center py-1">
            <a data-name="Navbar Logo" routerLink="/" class="navbar-brand logo me-4">
              <span role="img" aria-label="Logo of befret design" title="befret design"><img src="/assets/befret_logo.png" class="img img-fluid-2" width="95" height="40" alt="befret design" loading="lazy"></span>
            </a>
            <ul class="navbar-nav align-items-center gap-1">
              <li class=" o_no_autohide_item" style="margin: 0px 80px;" *ngIf="!authService.isLoggedIn()">
                <a routerLink="/auth" class="btn btn-outline-secondary">{{"Se connecter" | translate}}</a>
              </li>
              <!-- <li class=" o_no_autohide_item" style="margin: 0px 80px;" *ngIf="authService.isLoggedIn()">
                <a routerLink="/auth" class="btn btn-outline-secondary">Déconnexion</a>
              </li> -->
              <li class="dropdown o_no_autohide_item" *ngIf="authService.isLoggedIn() && userProfile?.isEmployee" style="margin: 0px 0px 0px 0px; cursor: pointer;" routerLink="#">
                <div class="position-relative me-2 p-2 rounded-circle border bg-o-color-3 " style="position: relative;">
                  <!-- <span class="unread-count"> {{this.mailCount}} </span> -->
                  <i id="mailIcon" class="fa fa-1x fa-fw fa-envelope fa-stack">
                    <span class="text-danger rounded">{{ mailCount }}</span>
                  </i>
                </div>
                
              </li>
              
              <li class="dropdown o_no_autohide_item" *ngIf="authService.isLoggedIn()" style="margin: 0px 20px 0px 0px;">
                
                <a href="#" role="button" data-bs-toggle="dropdown" class="dropdown-toggle btn d-flex align-items-center border-0 fw-bold text-reset o_navlink_background_hover" aria-expanded="false">
                    <div class="position-relative me-2 p-2 rounded-circle border bg-o-color-3">
                        <i data-oe-model="ir.ui.view" data-oe-id="443" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/li[1]/a[1]/div[1]/i[1]" class="fa fa-1x fa-fw fa-user fa-stack"></i>
                    </div>
                    <span class="">{{userProfile?.fullname || userProfile?.email}}</span>
                </a>
                <div role="menu" class="dropdown-menu js_usermenu dropdown-menu-end" data-bs-popper="none">
                    <!-- <a href="/web" role="menuitem" class="dropdown-item ps-3" id="o_backend_user_dropdown_link" data-oe-model="ir.ui.view" data-oe-id="443" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/li[1]/div[1]/a[1]">
                        <i class="fa fa-fw fa-th me-1 small text-primary"></i> Apps
                    </a> -->
                  <a routerLink="userprofile" role="menuitem" class="dropdown-item ps-3" data-oe-id="462" data-oe-xpath="/data/xpath/a" data-oe-model="ir.ui.view" data-oe-field="arch">
                      <i class="fa fa-fw fa-id-card-o me-1 small text-primary"></i> {{"Mon compte" | translate}}
                  </a>
                  <a routerLink="history" role="menuitem" class="dropdown-item ps-3" data-oe-id="462" data-oe-xpath="/data/xpath/a" data-oe-model="ir.ui.view" data-oe-field="arch">
                      <i class="fa fa-fw fa-list me-1 small text-primary"></i> {{"Mes envois" | translate}}
                  </a>
                  <div id="o_logout_divider" class="dropdown-divider" data-oe-model="ir.ui.view" data-oe-id="443" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/li[1]/div[1]/div[1]"></div>
                  
                  <a *ngIf="userProfile?.isEmployee" routerLink="dashboard" role="menuitem" class="dropdown-item ps-3" data-oe-id="462" data-oe-xpath="/data/xpath/a" data-oe-model="ir.ui.view" data-oe-field="arch">
                    <i class="fa fa-fw fa-bar-chart-o me-1 small text-primary"></i> {{"Tableau de bord" | translate}}
                  </a>
                  <a role="menuitem" id="o_logout" class="dropdown-item ps-3" data-oe-model="ir.ui.view" data-oe-id="443" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/li[1]/div[1]/a[2]" (click)="onLogout()">
                      <i class="fa fa-fw fa-sign-out me-1 small text-primary"></i> {{"Déconnexion" | translate}}
                  </a>
                </div>
              </li>
              <li data-name="Language Selector" class="o_header_language_selector ">
                <div class="js_language_selector d-flex align-items-center h-100 d-print-none">
                    <div role="menu" class=" list-inline">
                      <a 
                      class="js_change_lang  list-inline-item" 
                      role="menuitem" 
                      routerLink="/nl" 
                      data-url_code="nl" 
                      [class.disabled]="currentLanguage === 'nl'"
                      (click)="setLanguage('nl')"
                      title=" Nederlands (BE)">
                          <span class="align-middle ">NL</span>
                      </a>
                      <a 
                      class="js_change_lang  list-inline-item" 
                      role="menuitem" 
                      routerLink="/en" 
                      data-url_code="en" 
                      title="English (UK)"
                      [class.disabled]="currentLanguage === 'en'"
                      (click)="setLanguage('en')">
                          <span class="align-middle ">EN</span>
                      </a>
                      <a 
                      class="js_change_lang active list-inline-item" 
                      role="menuitem" 
                      routerLink="" 
                      data-url_code="fr" 
                      title=" Français"
                      [class.disabled]="currentLanguage === 'fr'"
                      (click)="setLanguage('fr')">
                          <span class="align-middle ">FR</span>
                      </a>
                    </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div aria-label="Bas" class="border-top o_border_contrast" style="background-color: #1f981f4b;">
          <div class="container d-flex justify-content-between" >
            <ul role="menu" id="top_menu" class="nav navbar-nav top_menu align-items-center me-4 py-1">
              <li role="presentation" class="nav-item">
                <a role="menuitem" routerLink="/" class="nav-link">
                  <span>{{"Accueil" | translate}}</span>
                </a>
              </li>
              <li role="presentation" class="nav-item" style="color: #ffff;">
                <a role="menuitem" routerLink="/tracking" class="nav-link ">
                  <span>{{"Suivre mon colis" | translate}}</span>
                </a>
              </li>
              <!-- <li *ngIf="authService.isLoggedIn()" role="presentation" class="nav-item" style="color: #ffff;">
                <a role="menuitem" routerLink="/history" class="nav-link ">
                  <span>Historique</span>
                </a>
              </li> -->
              <li role="presentation" class="nav-item">
                <a role="menuitem" routerLink="/contactus" class="nav-link ">
                  <span>{{"Service Client" | translate}}</span>
                </a>
              </li>
    <!-- <li role="presentation" class="nav-item">
        <a role="menuitem" routerLink="/" class="nav-link">
            <span>Service Pro</span>
        </a>
    </li> -->
    <!-- <li role="presentation" class="nav-item">
        <a role="menuitem" routerLink="/" class="nav-link">
            <span>FQ</span>
        </a>
    </li> -->
            </ul>
            <ul class="navbar-nav">
            </ul>
          </div>
        </div>
      </div>       
    </nav>






    <nav aria-label="Mobile" class="navbar navbar-light o_colored_level o_cc o_header_mobile d-block d-lg-none shadow-sm px-0 o_header_force_no_radius">
        <div class="o_main_nav container flex-wrap justify-content-between" >
          <a routerLink="/" class="navbar-brand logo">
            <span role="img" aria-label="Logo of befret design" title="befret design">
              <img src="/assets/befret_logo.png" class="img img-fluid" alt="BeFret Logo" loading="lazy">
            </span>
          </a>
          <ul class="o_header_mobile_buttons_wrap navbar-nav flex-row align-items-center">

            <li class="dropdown o_no_autohide_item" *ngIf="authService.isLoggedIn()" style="margin: 0px 0px 0px 0px;">
              <a href="#" role="button" data-bs-toggle="dropdown" class="dropdown-toggle btn d-flex align-items-center border-0 fw-bold text-reset o_navlink_background_hover" aria-expanded="false">
                  <div class="position-relative rounded-circle border bg-o-color-3">
                      <i data-oe-model="ir.ui.view" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/li[1]/a[1]/div[1]/i[1]" class="fa fa-1x fa-fw fa-user fa-stack"></i>
                  </div>
                  <span class="limited-text">{{userProfile?.fullname || userProfile?.email || "User"}}</span>
              </a>
              
              <a>
                  <div role="menu" class="dropdown-menu js_usermenu dropdown-menu-end" data-bs-popper="none">
                      <!-- <a href="/web" role="menuitem" class="dropdown-item ps-3" id="o_backend_user_dropdown_link" data-oe-model="ir.ui.view" data-oe-id="443" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/li[1]/div[1]/a[1]">
                          <i class="fa fa-fw fa-th me-1 small text-primary"></i> Apps
                      </a> -->
                      <!-- <a> <span>{{userProfile?.fullname || userProfile?.email || "NewMan"}}</span></a> -->
                    <a routerLink="userprofile" role="menuitem" class="dropdown-item ps-3" data-oe-id="462" data-oe-xpath="/data/xpath/a" data-oe-model="ir.ui.view" data-oe-field="arch">
                        <i class="fa fa-fw fa-id-card-o me-1 small text-primary"></i>{{"Mon compte" | translate}} 
                    </a>
                    <a routerLink="history" role="menuitem" class="dropdown-item ps-3" data-oe-id="462" data-oe-xpath="/data/xpath/a" data-oe-model="ir.ui.view" data-oe-field="arch">
                        <i class="fa fa-fw fa-list me-1 small text-primary"></i>{{"Mes envois" | translate}} 
                    </a>
                    <div id="o_logout_divider" class="dropdown-divider" data-oe-model="ir.ui.view" data-oe-id="443" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/li[1]/div[1]/div[1]"></div>
                    <a *ngIf="userProfile?.isEmployee" routerLink="dashboard" role="menuitem" class="dropdown-item ps-3" data-oe-id="462" data-oe-xpath="/data/xpath/a" data-oe-model="ir.ui.view" data-oe-field="arch">
                      <i class="fa fa-fw fa-bar-chart-o me-1 small text-primary"></i> {{"Tableau de bord" | translate}}
                    </a>
                    <a role="menuitem" id="o_logout" class="dropdown-item ps-3" data-oe-model="ir.ui.view" data-oe-id="443" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/li[1]/div[1]/a[2]" (click)="onLogout()">
                        <i class="fa fa-fw fa-sign-out me-1 small text-primary"></i> {{"Déconnexion" | translate}}
                    </a>
                  </div>
            </a>
              
            </li>
            <!-- <li (click)="onMobileMenu()"> -->
            <li>
              <button class="nav-link btn me-auto p-2 o_not_editable" type="button" data-bs-toggle="offcanvas" 
                data-bs-target="#top_menu_collapse_mobile" aria-controls="top_menu_collapse_mobile" 
                aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
              </button>
            </li>
          </ul>

          <div id="top_menu_collapse_mobile" class="offcanvas offcanvas-end o_navbar_mobile">
            <div class="offcanvas-body d-flex flex-column justify-content-between h-100 w-50" style="background-color: rgba(255, 255, 255, 0.926);">
              <div class="offcanvas-header justify-content-end o_not_editable">
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <ul class="navbar-nav">
                <ul class="nav navbar-nav top_menu text-center">
                  <li class="nav-item">
                    <a routerLink="/" class="nav-link">
                      <span style="text-decoration:underline; color: #22A922;" data-bs-dismiss="offcanvas">{{"Accueil" | translate}}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/tracking" class="nav-link">
                      <span style="color: #22A922;" data-bs-dismiss="offcanvas">{{"Suivre mon Colis" | translate}}</span>
                    </a>
                  </li>
                  <!-- <li *ngIf="authService.isLoggedIn()" class="nav-item">
                    <a routerLink="/history" class="nav-link">
                      <span style="color: #22A922;" data-bs-dismiss="offcanvas">Historique</span>
                    </a>
                  </li> -->
                  <li class="nav-item">
                    <a routerLink="/contactus" class="nav-link">
                      <span style="color: #22A922;" data-bs-dismiss="offcanvas">{{"Service Client" | translate}}</span>
                    </a>
                  </li>
                  <li class="o_no_autohide_item" *ngIf="!authService.isLoggedIn()">
                    <a routerLink="/auth" class="btn btn-secondary w-100" data-bs-dismiss="offcanvas">{{"Se connecter" | translate}}</a>
                  </li>
                </ul>
              </ul>
              <ul class="navbar-nav">
                <!-- <li class="o_no_autohide_item">
                  <a routerLink="/auth" class="btn btn-secondary w-100" data-bs-dismiss="offcanvas">Se connecter</a>
                </li> -->
                <li class="o_header_language_selector">
                  <div class="js_language_selector dropup d-print-none">
                    <div role="menu" class="w-100 list-inline">
                      <a 
                      class="js_change_lang list-inline-item" 
                      routerLink="/nl" 
                      data-url_code="nl" 
                      [class.disabled]="currentLanguage === 'nl'"
                      (click)="setLanguage('nl')"
                      title="Nederlands (BE)">
                        <span class="align-middle me-auto small" data-bs-dismiss="offcanvas">NL</span>
                      </a>
                      <a 
                      class="js_change_lang list-inline-item" 
                      routerLink="/en" 
                      data-url_code="en" 
                      [class.disabled]="currentLanguage === 'en'"
                      (click)="setLanguage('en')"
                      title="English (UK)">
                        <span class="align-middle me-auto small" data-bs-dismiss="offcanvas">EN</span>
                      </a>
                      <a 
                      class="js_change_lang active list-inline-item" 
                      routerLink="" 
                      data-url_code="fr" 
                      [class.disabled]="currentLanguage === 'fr'"
                      (click)="setLanguage('fr')"
                      title="Français">
                        <span class="align-middle me-auto small" data-bs-dismiss="offcanvas">FR</span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
 </header>

 