import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import QRCode from 'qrcode';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  trackingID: string | null = null;
  parcelID: string | null = null;
  phoneNumber: string | null = null;
  sender: string | null = null;
  pType: string | null = null;
  qrImg: string | null = null;
  notified: any;
  @ViewChild('pdfContent') pdfContent!: ElementRef;

  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  async ngOnInit() {
    this.trackingID = this.route.snapshot.queryParamMap.get('trackingID');
    this.parcelID = this.route.snapshot.queryParamMap.get('parcel');
    this.phoneNumber = this.route.snapshot.queryParamMap.get('phoneNumber');
    this.notified = this.route.snapshot.queryParamMap.get('notified');
    this.sender = this.route.snapshot.queryParamMap.get('sender');
    this.pType = this.route.snapshot.queryParamMap.get('pType');
    console.log("tracking: ", this.trackingID, " parcel: ", this.parcelID, " from: ", this.sender);

    this.generateQRCode();

    if (this.notified === 'false') {
      const notificationData = {
        parcelID: this.parcelID,
            trackingCode: this.trackingID,
            sender: this.sender,
            phoneNumber: this.phoneNumber,
            pType: this.pType,
      };
      console.log("Data : ", notificationData);
      await firstValueFrom(
        this.http.post(
          'https://us-central1-befret-development.cloudfunctions.net/sendPaymentNotification',
          notificationData
        )
      );
    } else {
      console.log("Seems like the user is already notified:", this.notified);
    }
  }

  copyToClipBoard(): void {
    if (this.trackingID) {
      navigator.clipboard.writeText(this.trackingID).then(() => {
        alert('Code copié!');
      });
    }
  }

  generateQRCode(): void {
    if (this.parcelID) {
      QRCode.toDataURL(this.parcelID, {
        width: 400,
        color: {
          dark: '#000', // Red color
          light: '#ffffff' // Background color
        }
      }, (err, url) => {
        if (err) {
          console.error(err);
          return;
        }
        this.qrImg = url;
        console.log("The QR URL: ", this.qrImg);
        console.log("The QR URL: ", url);
      });
    } else {
      console.log("There is no parcelID: ", this.parcelID);
    }
  }

  async generatePDF(): Promise<void> {
    if (!this.pdfContent) {
      console.error('PDF content element is not available.');
      return;
    }

    const element = this.pdfContent.nativeElement;

    // Capture the main content
    const canvas = await html2canvas(element, { scale: 5 });
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF('portrait', 'mm', 'a4');

    // Calculate dimensions for placing the content in the top-left quarter
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pdfWidth / 2;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const x = (pdfWidth - imgWidth) / 2;
    const y = 20; // Adjusting top padding for better view

    pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);

    // Add the QR code separately if available
    if (this.qrImg) {
      const qrX = x + imgWidth - 100; // Adjust position as needed
      const qrY = y + imgHeight - 100; // Adjust position as needed
      // pdf.addImage(this.qrImg, 'PNG', qrX, qrY, 35, 35);
    }

    pdf.save('tracking-info.pdf');
  }
}
