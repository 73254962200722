<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Message réçu" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="col-lg-12 mt-4 mt-lg-0 o_colored_level text-center">
                        <span class="d-block fa fa-5x fa-envelope mx-auto rounded-circle border"></span>
                        <h1 class="text-primary "><strong>{{"Merci de nous avoir écrit" | translate}}</strong></h1>
                        <h2>{{"Nous avons bien réçu votre message et nous mettrons tout en oeuvre" | translate}}<br> {{"à vous repondre dans les plus brefs delais" | translate}}</h2>
                    </div>
                </div></div></section></div></main>