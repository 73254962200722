<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Sensibilisation à la Fraude" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="privacy-policy col-lg-6">
                        <!-- Long Privacy Policy Content Here -->
                        <!-- <h2>1. Introduction</h2> -->
                        <p>
                            {{"Chez BEFRET SRL, nous prenons la sécurité de vos transactions et de vos informations personnelles très au sérieux. À mesure que les transactions en ligne augmentent, le risque de fraude peut également croître. Nous avons mis en place des mesures de sécurité rigoureuses pour protéger nos clients contre la fraude, mais il est essentiel que vous soyez aussi conscient des risques et des comportements à adopter pour éviter d'en être victime." | translate}}
                        </p>
                        <h2>{{"1. Types de fraude courants" | translate}}</h2>
                        <p>
                            {{"La fraude peut prendre plusieurs formes. Voici quelques exemples courants que vous pourriez rencontrer :" | translate}}                        </p>
                        <h3>{{"a) Phishing (hameçonnage)" | translate}}</h3>
                        <p>
                            {{"Les fraudeurs envoient des e-mails ou des messages qui semblent provenir de" | translate}} <strong>{{"BEFRET SRL" | translate}} </strong>{{"ou d'autres entreprises de confiance. Ces messages vous demandent de fournir des informations personnelles, de cliquer sur un lien malveillant ou de mettre à jour vos informations de paiement." | translate}}                        </p>
                        
                        <h3>{{"b) Usurpation d'identité" | translate}}</h3>
                        <p>
                            {{"Des fraudeurs peuvent se faire passer pour des employés de" | translate}} <strong>{{"BEFRET SRL" | translate}}</strong>, {{"de transporteurs ou d'autres services pour tenter d'obtenir vos informations confidentielles ou de vous faire payer pour des services frauduleux." | translate}}                        </p>
                        <h3>{{"c) Faux sites web" | translate}}</h3>
                        <p>
                            {{"Certains fraudeurs créent des copies de sites web comme befret.be afin de vous induire en erreur et de voler vos informations personnelles ou bancaires. Ils peuvent vous demander de saisir vos identifiants, coordonnées bancaires, ou encore d’effectuer un paiement." | translate}}                        </p>
                        <h3>{{"d) Colis frauduleux" | translate}}</h3>
                        <p>
                            {{"Certains individus peuvent tenter de vous envoyer des colis contenant des articles illégaux ou vous demander de servir d'intermédiaire pour des envois suspects. BEFRET SRL collabore activement avec les autorités pour prévenir ce type de fraude." | translate}}                        </p>
                        <h2>{{"2. Conseils pour vous protéger contre la fraude" | translate}}</h2>
                        <p>
                            {{"Nous vous recommandons de suivre ces conseils pour garantir votre sécurité et celle de vos transactions :" | translate}}</p>
                            <ul>
                                <li><strong>{{"Vérifiez toujours l'authenticité de l'expéditeur :" | translate}} </strong> {{"Si vous recevez un e-mail, un message ou un appel qui semble provenir de BEFRET SRL, assurez-vous que l’adresse e-mail ou le numéro de téléphone est officiel. Ne communiquez jamais vos informations personnelles sans vérification préalable." | translate}}</li>
                                <li><strong>{{"Ne cliquez pas sur des liens suspects :" | translate}} </strong>{{"Évitez de cliquer sur des liens dans des e-mails ou messages qui vous semblent douteux. Tapez directement l’adresse de notre site dans votre navigateur pour accéder à votre compte." | translate}}</li>
                                <li><strong>{{"Ne partagez jamais vos identifiants : BEFRET SRL" | translate}} </strong>{{"ne vous demandera jamais de partager vos informations de connexion, votre mot de passe ou vos informations bancaires par e-mail ou message." | translate}}</li>
                                <li><strong>{{"Utilisez des mots de passe forts :" | translate}} </strong>{{"Choisissez un mot de passe complexe et unique pour votre compte sur befret.be et ne le partagez pas avec d'autres services." | translate}}</li>
                                <li><strong>{{"Méfiez-vous des offres trop belles pour être vraies :" | translate}} </strong>{{"Si une offre ou un message semble trop avantageux ou urgent, prenez le temps de vérifier sa véracité avant d'agir." | translate}}</li>
                                <li><strong>{{"Signalez toute activité suspecte :" | translate}} </strong>{{"Si vous suspectez qu'une fraude a eu lieu, contactez immédiatement BEFRET SRL ou votre banque pour signaler toute activité suspecte." | translate}}</li>
                                <!-- <li>Toute reproduction doit inclure la mention de droit d'auteur suivante :
                                    Copyright © BEFRET. Tous droits réservés.
                                    </li> -->
                            </ul>
                        
                        <h2>{{"3. Ce que fait BEFRET SRL pour protéger ses clients" | translate}}</h2>
                        <p>
                            <strong>{{"BEFRET SR" | translate}}L</strong> {{"a mis en place plusieurs mesures de sécurité pour garantir la protection de vos informations et de vos transactions :" | translate}}
                        </p>
                        <ul>
                            <li><strong>{{"Sécurité des données :" | translate}} </strong>{{"Nous utilisons des technologies de cryptage pour protéger vos informations personnelles et financières pendant la transmission." | translate}}</li>
                            <li><strong>{{"Vérification des comptes :" | translate}} </strong>{{"Nous procédons régulièrement à des vérifications de sécurité pour détecter toute activité suspecte sur les comptes utilisateurs." | translate}}</li>
                            <li><strong>{{"Collaboration avec les autorités :" | translate}} </strong>{{"En cas de fraude avérée, nous coopérons pleinement avec les autorités locales et internationales pour prendre des mesures contre les fraudeurs." | translate}}</li>
                            
                        </ul>

                    <h2>{{"4. Comment signaler une fraude ?" | translate}}</h2>
                    <p>
                        {{"Si vous pensez être victime d’une tentative de fraude liée à" | translate}} <strong>{{"BEFRET SRL" | translate}}</strong> {{"ou si vous avez repéré un comportement suspect, nous vous encourageons à nous contacter immédiatement. Nos équipes prendront les mesures nécessaires pour protéger vos données et sécuriser vos envois." | translate}}                    </p>
                    <h3>{{"Contactez-nous :" | translate}}</h3>
                     <ul>
                        <li><strong>{{"Email :" | translate}} </strong>info&#64;befret.be</li>
                        <li><strong>{{"Téléphone :" | translate}} </strong>(à compléter)</li>
                        <li><strong>{{"Adresse :" | translate}} </strong>{{"35, Rue Ilya PRIGOGINE, 1480 Saintes (Tubize), Belgique" | translate}}</li>
                    </ul>
                    <h2>{{"5. Sanctions en cas de fraude" | translate}}</h2>
                    <p>
                        {{"Nous prenons toute tentative de fraude ou de malveillance très au sérieux. Toute personne impliquée dans une fraude à l'encontre de" | translate}} <strong>{{"BEFRET SRL" | translate}}</strong> {{"ou de ses clients fera l'objet d'une enquête approfondie et sera signalée aux autorités compétentes. Nous nous réservons le droit de suspendre ou de fermer tout compte utilisateur impliqué dans une activité frauduleuse et de prendre des mesures légales pour obtenir réparation des dommages subis." | translate}}                    </p>
                    
                    </div>
                </div></div></section>
        <!-- <section>
            
              
        </section> -->
    </div></main>