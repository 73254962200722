import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  // private functionsUrl = "https://us-central1-befret-development.cloudfunctions.net/createInvoice"
  private functionsUrl = "https://us-central1-befret-development.cloudfunctions.net/createInvoice"


  constructor(private http: HttpClient) { }

  createInvoice(
    customerEmail: string, 
    parcelID: string, 
    amount: number): Observable<any> {
    return this.http.post(this.functionsUrl, { customerEmail, parcelID, amount });
  }
}
