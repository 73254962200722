import { Component } from '@angular/core';

@Component({
  selector: 'app-success-message-sent',
  templateUrl: './success-message-sent.component.html',
  styleUrl: './success-message-sent.component.css'
})
export class SuccessMessageSentComponent {

}
