<main>
                    
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Foire aux Questions (FAQ) de BEFRET" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="privacy-policy col-lg-6">
                        <!-- Long Privacy Policy Content Here -->
                        <h2><strong>{{"ENVOI" | translate}}</strong></h2>
                        <h2>{{"1. Quels sont les tarifs de BEFRET ?" | translate}}</h2>
                        <p>
                            {{"BEFRET propose des tarifs adaptés au poids de votre colis, au type d'envoi (paquet ou courrier), à la destination (Kinshasa ou Lubumbashi), et à l’ajout d’options supplémentaires (comme la livraison à domicile). Nous travaillons par tranches de kilos pour minimiser l'impact des petites erreurs de pesée." | translate}}
                        </p>
                        <ul>
                            <li><strong>{{"Tarifs pour un paquet :" | translate}}</strong></li>
                                <ul>
                                    <li>{{"De 0 à 1 kg : 14 euros vers Kinshasa, 16 euros vers Lubumbashi." | translate}}</li>
                                </ul>
                            <li><strong>{{"Tarifs pour un courrier :" | translate}}</strong></li>
                                <ul>
                                    <li>{{"De 0 à 1 kg : 15 euros pour toutes les destinations." | translate}}</li>
                                </ul>
                        </ul>
                        {{"Si vous choisissez l'option de livraison à domicile, un supplément de 4 euros s'ajoutera au prix de votre envoi." | translate}}
                        <h2>{{"2. Quelles options offrent BEFRET ?" | translate}}</h2>
                        <p>
                            {{"BEFRET propose plusieurs options, y compris la possibilité d'envoyer un colis en ligne, d'ajouter une option de livraison à domicile, et de suivre vos colis en temps réel." | translate}}
                        </p>
                        
                        <h2>{{"3. Quelles sont les méthodes de livraison ?" | translate}}</h2>
                        <p>
                            {{"Vous avez la possibilité de retirer vos colis dans nos agences de Kinshasa ou Lubumbashi, dans l’un de nos points relais, ou encore de choisir la livraison à domicile moyennant un supplément. Nous proposons également une livraison gratuite dans des points de rencontre situés directement dans les stations Total ou les supermarchés" | translate}}
                        </p>
                        <h2><strong>{{"DÉPÔT" | translate}}</strong></h2>
                        <h2>{{"4. Où déposer mon colis ?" | translate}}</h2>
                        <p>
                            {{"Pour l’envoi de votre colis, vous avez la possibilité de le déposer dans un bureau de poste ou un autre service (Mondial Relay, Post NL, GLS, etc.) près de chez vous. Veuillez l'envoyer à l'adresse suivante :" | translate}} <strong> {{"35, Rue PRIGOGINE, 1480 Saintes (Tubize), BELGIQUE" | translate}}</strong>
                        </p>
                        <h2>{{"5. Comment déposer mon colis ?" | translate}}</h2>
                        <p>
                            {{"Après avoir préparé et encodé votre colis sur BEFRET :" | translate}}
                        </p>
                        <ul>
                            <li><strong>{{"Pour un paquet :" | translate}} </strong>{{"Imprimez l’étiquette et collez-la sur votre colis. Vous pouvez ensuite le déposer à la poste ou dans un point relais proche de chez vous. Si vous ne pouvez pas imprimer l’étiquette, notez le code à 6 chiffres (veuillez-vous assurer que le code est noté correctement) et ajoutez l’adresse BEFRET." | translate}}</li>
                            <li><strong>{{"Pour un courrier :" | translate}} </strong>{{"Imprimez l’étiquette et collez-la sur votre colis. Si vous ne pouvez pas imprimer, notez le code sous le QR code (veuillez-vous assurer qu'il est noté correctement) suivi de l'adresse BEFRET." | translate}}</li>
                            <!-- <li><strong>Site web : </strong><a href="https://www.mediationconsommateur.be" class="link">www.mediationconsommateur.be</a></li>
                            <li><strong>Email : </strong>contact&#64;mediationconsommateur.be</li> -->
                        </ul>
                        <h2>{{"6. Comment BEFRET informe-t-il le destinataire ?" | translate}}</h2>
                        <p>
                            {{"BEFRET informe le destinataire dès l'enlèvement du colis en Belgique et lui communique la date de livraison ou les détails pour récupérer son colis. En cas d'imprévu, notre équipe prendra contact avec le destinataire et fournira un moyen de contact pour toute assistance." | translate}}    
                        </p>
                        
                        <h2>{{"7. Comment suivre mon colis ?" | translate}}</h2>
                        <p>
                            {{"Un suivi détaillé est effectué pour tous vos envois. Vous serez informé dès que votre colis envoyé via la poste arrivera dans notre entrepôt en Belgique. Vous pouvez également suivre l’évolution de vos colis à partir de votre compte BEFRET." | translate}}
                        </p>

                    <h2>{{"8. Que faire si le destinataire est absent lors de la livraison ?" | translate}}</h2>
                    <p>
                        {{"BEFRET sécurise votre colis et se rend flexible pour le livrer à l'endroit de votre choix. Si le destinataire est absent, merci de nous informer afin que nous puissions trouver une solution ensemble. Il est important de noter qu'un colis ne peut pas rester dans nos bureaux trop longtemps, des frais supplémentaires pouvant s'appliquer après un certain délai. Nous ne sommes pas responsables des dommages subis par un colis dans ce cas (voir nos conditions d'utilisation)." | translate}}
                    </p>
                    <h2><strong>{{"SOLUTIONS" | translate}}</strong></h2>
                    <h2>{{"9. Demande de facturation" | translate}}</h2>
                    <p>
                        {{"Vous êtes un professionnel ou une association et avez besoin d’une facture ? BEFRET est à votre disposition pour vous établir des factures pour tous vos envois." | translate}}
                    </p>
                    
                    <h2>{{"10. Gestion de retour" | translate}}</h2>
                    <p>
                        {{"Nous vous avons livré un colis erroné ? Vous souhaitez renvoyer un colis vers son pays d'origine ? N’hésitez pas à appeler notre service client pour une assistance de qualité." | translate}}
                    </p>
                    <h2>{{"11. Paiement par le destinataire" | translate}}</h2>
                    <p>
                        {{"L’option de paiement à destination est destinée aux clients réguliers (ceux qui envoient entre 10 et 20 colis par semaine). Cette option est liée à la nature des colis envoyés. Si le client envoie principalement des courriers, il pourra payer ses courriers à destination, et il en est de même pour les paquets." | translate}}
                    </p>
                    <h2>{{"12. Récupération de la TVA" | translate}}</h2>
                    <p>
                        {{"Si vous avez acheté un colis et souhaitez récupérer la TVA au niveau de la douane, nous sommes à votre service. Un frais de dossier de 25 euros s'applique. Veuillez nous contacter à l'adresse suivante :" | translate}} <strong>info&#64;befret.be</strong>
                    </p>
                    
                    </div>
                </div></div></section>
        <!-- <section>
            
              
        </section> -->
    </div></main>